import { OverLayFields } from '../models/OverlayFields';
import { PositioningType } from '../models/PositioningType';

export const TotalFlowTankPositioning: PositioningType[] = [
  {
    overlayField: OverLayFields.LastGoodScan,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ControllerType,
    position: { x: 36.5, y: 62 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.LastSample,
    position: { x: 612, y: 226 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.Temperature,
    position: { x: 612, y: 264 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.Volume,
    position: { x: 612, y: 303 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.TankLevel,
    position: { x: 612, y: 340.13 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.InterfaceLevel,
    position: { x: 612, y: 381 },
    justification: 'left',
  },
];
