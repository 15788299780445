import React, { useEffect, useState } from 'react';
import { ReactComponent as LeftLongTriangle } from '../../../../images/left-long-triangle.svg';
import { ReactComponent as RightLongTriangle } from '../../../../images/right-long-triangle.svg';
import { ReactComponent as RightTriangle } from '../../../../images/right-triangle.svg';
import { ReactComponent as LeftTriangle } from '../../../../images/left-triangle.svg';
import { ReactComponent as Packer } from '../../../../images/packer.svg';
import './PressureProfileWellbore.scss';
import { Wellbore } from '../../models/GLWellbore';
import { ValveData } from '../../GLAnalysisSlice';
import WellboreFill from './WellboreFill';

interface PressureProfileWellboreProps {
  wellbore: Wellbore | undefined;
  valves: ValveData[];
  height: number;
}

const PressureProfileWellbore: React.FC<PressureProfileWellboreProps> = ({ wellbore, valves, height }) => {
  const [normalizedTubingDepth, setNormalizedTubingDepth] = useState(-1);
  const [normalizedPackerDepth, setNormalizedPackerDepth] = useState(-1);
  const [topFluidColumn, setTopFluidColumn] = useState(-1);
  const [bottomFluidColumn, setBottomFluidColumn] = useState(-1);
  const [casingFluidColumn, setCasingFluidColumn] = useState(-1);

  const bottomholeFixedHeight = wellbore?.wellDepth ? wellbore.wellDepth * 0.0714 : 0;

  const calculateNormalizedTubingDepth = () => {
    if (
      wellbore === undefined ||
      wellbore.tubingDepth === undefined ||
      wellbore.wellDepth === undefined ||
      wellbore.bottomFluidColumn === undefined ||
      wellbore.casingFluidColumn === undefined ||
      wellbore.topFluidColumn === undefined
    ) {
      return;
    }

    const floatingColumnsActualHeight =
      wellbore.bottomFluidColumn + wellbore.casingFluidColumn + wellbore.topFluidColumn;

    const referenceHeight = 300;
    const scalingFactor = height / referenceHeight;

    const bottomDepth = wellbore.wellDepth * (referenceHeight / floatingColumnsActualHeight);

    const totalWellboreToScreenRatio = floatingColumnsActualHeight / wellbore.wellDepth;
    const tubingOffset = (bottomDepth - wellbore.tubingDepth) * totalWellboreToScreenRatio;

    const normalizedTubingDepth = (referenceHeight - tubingOffset) * scalingFactor;
    if (!isNaN(normalizedTubingDepth)) {
      setNormalizedTubingDepth(normalizedTubingDepth);
    }
  };

  const calculateNormalizedPackerDepth = () => {
    if (wellbore === undefined || wellbore.packerDepth === undefined || wellbore.wellDepth === undefined) {
      return;
    }

    const normalizedPackerDepth = ((wellbore.packerDepth - bottomholeFixedHeight) / wellbore.wellDepth) * height;

    if (!isNaN(normalizedPackerDepth)) {
      setNormalizedPackerDepth(normalizedPackerDepth);
    }
  };

  const calculateTopFluidColumn = () => {
    if (wellbore === undefined || wellbore.topFluidColumn === undefined || wellbore.wellDepth === undefined) {
      return;
    }

    const referenceHeight = 300;
    const scalingFactor = height / referenceHeight;

    const scaledTopFluidColumn = wellbore.topFluidColumn * scalingFactor;

    setTopFluidColumn(scaledTopFluidColumn);
  };

  const calculateBottomFluidColumn = () => {
    if (wellbore === undefined || wellbore.bottomFluidColumn === undefined || wellbore.wellDepth === undefined) {
      return;
    }

    const referenceHeight = 300;
    const scalingFactor = height / referenceHeight;

    const scaledBottomFluidColumn = wellbore.bottomFluidColumn * scalingFactor;

    setBottomFluidColumn(scaledBottomFluidColumn);
  };

  const calculateCasingFluidColumn = () => {
    if (wellbore === undefined || wellbore.casingFluidColumn === undefined || wellbore.wellDepth === undefined) {
      return;
    }

    const referenceHeight = 300;
    const scalingFactor = height / referenceHeight;

    const scaledCasingFluidColumn = wellbore.casingFluidColumn * scalingFactor;

    setCasingFluidColumn(scaledCasingFluidColumn);
  };

  useEffect(() => {
    calculateNormalizedTubingDepth();
    calculateNormalizedPackerDepth();
    calculateTopFluidColumn();
    calculateBottomFluidColumn();
    calculateCasingFluidColumn();
  }, [wellbore, height]);

  return (
    <div className='pressure-profile-wellbore-container'>
      {wellbore == null || wellbore.errorMessage == null || wellbore.errorMessage.length > 0 ? (
        <div className='pressure-profile-wellbore-error-message'>{wellbore?.errorMessage ?? ''}</div>
      ) : (
        <div className='pressure-profile-wellbore-inside-container' style={{ height: height }}>
          <div className='pressure-profile-wellbore-side-shapes-left'>
            <div className='pressure-profile-wellbore-triangles'>
              <LeftLongTriangle />
              <LeftLongTriangle />
              <LeftLongTriangle />
            </div>
            <LeftTriangle />
          </div>
          <div className='pressure-profile-wellbore-bore'>
            <div className='pressure-profile-wellbore-left'>
              <WellboreFill
                topFluidColumn={topFluidColumn}
                bottomFluidColumn={bottomFluidColumn}
                casingFluidColumn={casingFluidColumn}
              />
              {wellbore?.hasPacker && (
                <div
                  className='pressure-profile-wellbore-packer-container'
                  style={{ height: `${normalizedPackerDepth}px` }}
                >
                  <Packer />
                </div>
              )}
            </div>

            <div className='pressure-profile-wellbore-center'>
              <div className='pressure-profile-wellbore-tubing' style={{ height: `${normalizedTubingDepth}px` }} />
            </div>

            <div className='pressure-profile-wellbore-right'>
              <WellboreFill
                topFluidColumn={topFluidColumn}
                bottomFluidColumn={bottomFluidColumn}
                casingFluidColumn={casingFluidColumn}
              />
              {wellbore?.hasPacker && (
                <div
                  className='pressure-profile-wellbore-packer-container'
                  style={{ height: `${normalizedPackerDepth}px` }}
                >
                  <Packer />
                </div>
              )}
              {valves?.map((valve, index) => {
                const color =
                  valve.isOpen && !valve.isInjecting
                    ? '#8B0000'
                    : valve.isOpen && valve.isInjecting
                    ? '#32CD32'
                    : !valve.isOpen
                    ? '#D0D8DD'
                    : '#D0D8DD';

                if (valve.depth == null || isNaN(parseInt(valve.depth)) || wellbore?.wellDepth == null) {
                  return (
                    <div
                      key={index}
                      className='pressure-profile-wellbore-valve'
                      style={{ top: `${0}%`, backgroundColor: color }}
                    />
                  );
                }

                const normalizedValveDepth =
                  ((parseInt(valve.depth) - bottomholeFixedHeight) / wellbore.wellDepth) * height;

                return (
                  <div
                    key={index}
                    className='pressure-profile-wellbore-valve'
                    style={{ top: `${normalizedValveDepth}px`, backgroundColor: color }}
                  />
                );
              })}
            </div>
          </div>
          <div className='pressure-profile-wellbore-side-shapes-right'>
            <div className='pressure-profile-wellbore-triangles'>
              <RightLongTriangle />
              <RightLongTriangle />
              <RightLongTriangle />
            </div>
            <RightTriangle />
          </div>
        </div>
      )}
    </div>
  );
};

export default PressureProfileWellbore;
