import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchGLAnalysisAsync } from './GLAnalysisService';
import { Wellbore } from './models/GLWellbore';

interface State {
  loading: boolean;
  glAnalysis: GLAnalysisResults;
  error: string | undefined;
}

interface GLAnalysisResults {
  [id: string]: Data;
}

interface Data {
  inputs: AnalysisData[];
  outputs: AnalysisData[];
  valves: ValveData[];
  wellboreViewData: Wellbore;
}

interface AnalysisData {
  id: string;
  name: string;
  displayName: string;
  value: string;
  displayValue: string;
  dateTypeId: number;
  measurementAbbreviation: string;
  sourceId: number;
}

export interface ValveData {
  position: number;
  description: string;
  depth: string;
  closingPressureAtDepth: string;
  openingPressureAtDepth: string;
  injectionPressureAtDepth: string;
  tubingCriticalVelocityAtDepth: string;
  status: string;
  isOpen: boolean;
  isInjecting: boolean;
}

interface FetchGLAnalysisParameters {
  assetId: string;
  testDate: string;
  analysisTypeId: string;
  analysisResultId: string;
}

export const fetchAnalyticsGLAnalysisAsync = createAsyncThunk(
  'Analytics/GLAnalysis',
  async (data: FetchGLAnalysisParameters) => {
    const response = await fetchGLAnalysisAsync(
      data.assetId,
      data.testDate,
      data.analysisTypeId,
      data.analysisResultId,
    );
    return response;
  },
);

const initialState: State = {
  loading: false,
  glAnalysis: {},
  error: undefined,
};

export const glAnalysisSlice = createSlice({
  name: 'glAnalysisData',
  initialState,
  reducers: {
    setInitialState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnalyticsGLAnalysisAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAnalyticsGLAnalysisAsync.fulfilled, (state, action) => {
        const assetId = action.meta.arg.assetId;
        const testDate = action.meta.arg.testDate;
        const analysisTypeId = action.meta.arg.analysisTypeId;
        const analysisResultId = action.meta.arg.analysisResultId;
        const values = action.payload?.values;
        if (values && state.glAnalysis[`${assetId}-${testDate}-${analysisTypeId}-${analysisResultId}`] == null) {
          state.glAnalysis[`${assetId}-${testDate}-${analysisTypeId}-${analysisResultId}`] = values;
        }
        state.loading = false;
      })
      .addCase(fetchAnalyticsGLAnalysisAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setInitialState } = glAnalysisSlice.actions;
export default glAnalysisSlice.reducer;
