import React from 'react';
import { useTracking } from 'react-tracking';

interface TrackingProps {
  tracking: {
    capture: (eventData: object) => void;
  };
}

const TrackingDecorator = (WrappedComponent: React.ComponentType<TrackingProps>) => {
  const TrackingComponent: React.FC = (props) => {
    const tracking = useTracking();
    const handleCapture = (eventData: object) => {
      console.log(`${eventData}`);
    };
    const trackedProps = {
      ...props,
      tracking: {
        ...tracking,
        capture: handleCapture,
      },
    };
    return <WrappedComponent {...trackedProps} />;
  };
  return TrackingComponent;
};

export default TrackingDecorator;
