import React from 'react';

const TimelineLink = ({ color = '#B8C5CC' }) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_6365_21263)'>
      <path
        d='M9.23762 10.2265C9.59642 10.7042 10.0538 11.0992 10.5786 11.3847C11.1035 11.6701 11.6837 11.8394 12.2797 11.8809C12.8757 11.9224 13.4737 11.8353 14.0331 11.6254C14.5925 11.4155 15.1002 11.0877 15.5218 10.6644L18.017 8.15956C18.7745 7.37226 19.1924 6.31895 19.1808 5.22648C19.1692 4.13401 18.729 3.08981 17.955 2.31876C17.181 1.54772 16.1351 1.11153 15.0426 1.10414C13.9501 1.09675 12.8984 1.51875 12.114 2.27925L10.6834 3.70701M12.5677 8.55339C12.2089 8.07564 11.7516 7.68064 11.2267 7.39519C10.7018 7.10973 10.1217 6.9405 9.52568 6.89896C8.92964 6.85743 8.33165 6.94457 7.77225 7.15447C7.21286 7.36437 6.70515 7.69211 6.28355 8.11548L3.78837 10.6203C3.03089 11.4076 2.61294 12.4609 2.62454 13.5534C2.63614 14.6458 3.07635 15.6901 3.85037 16.4611C4.62439 17.2321 5.67028 17.6683 6.76279 17.6757C7.85529 17.6831 8.90699 17.2611 9.69137 16.5006L11.1136 15.0729'
        stroke={color}
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_6365_21263'>
        <rect width='20' height='20' fill='white' transform='translate(0.88501 0.242676) rotate(-0.110297)' />
      </clipPath>
    </defs>
  </svg>
);

export default TimelineLink;
