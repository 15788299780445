import { userInstance } from '../api';

interface UserPreference {
  Property: string;
  Group: string;
  Value: string;
}

const getLandingPage = async () => {
  const params = {
    groupName: 'Landing',
    property: 'DefaultPage',
  };

  return await userInstance
    .get('UserDefault/UserPreference', { params })
    .then((response: any) => {
      if (response) {
        return response.data;
      }
    })
    .catch(function (error) {
      console.error('Error fetching initial state:', error);
      return '';
    });
};

const saveLandingPage = async (params: UserPreference): Promise<string> => {
  try {
    await userInstance.post('UserDefault/UserPreference', params).then(function (response: any) {
      if (response) {
        return response.data;
      }

      return '';
    });
  } catch (error) {
    console.error('Error fetching initial state:', error);
    return '';
  }

  return '';
};

const UserService = {
  getLandingPage,
  saveLandingPage,
};

export default UserService;
export { UserPreference };
