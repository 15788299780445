import { OverLayFields } from '../models/OverlayFields';
import { PositioningType } from '../models/PositioningType';

export const GasLiftPositioning: PositioningType[] = [
  {
    overlayField: OverLayFields.LastGoodScan,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.CurrentCommunicationStatus,
    position: { x: 619, y: 416 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.RTUAddress,
    position: { x: 618, y: 444 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.FirmwareVersion,
    position: { x: 618, y: 485 },
    justification: 'left',
  },
];
