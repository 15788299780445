import React, { useEffect, useState } from 'react';
import './CardDateList.css';
import CardDateItem from '../CardDateItem/CardDateItem';
import { useAppSelector } from '../../../hooks/storeHooks';
import { FixedSizeList as List } from 'react-window';
import Loader from '../../common/Loader/Loader';

interface RGBColor {
  r: number;
  g: number;
  b: number;
}

interface CardDateDetails {
  date: string;
  cardTypeName: string;
  isSelected: boolean;
  color: RGBColor;
}

interface CardDateListProps {
  cardDateItems: CardDateDetails[];
  onClick: (_: any, date: string) => void;
  cardTypeNames: string[];
}

const CardDateList = (props: CardDateListProps) => {
  const [filteredCardDate, setFilteredCardDate] = useState<CardDateDetails[]>([]);
  const isLoading = useAppSelector((state) => state.rodLiftAnalysis.isLoading);

  useEffect(() => {
    const data = props.cardDateItems.filter((item) => {
      if (props.cardTypeNames.length === 0) {
        return true;
      }
      return props.cardTypeNames.includes(item.cardTypeName);
    });
    setFilteredCardDate(data);
  }, [props]);

  const Row = ({ index, style }: { index: number; style: React.CSSProperties }) => (
    <CardDateItem
      id={index}
      key={index}
      date={filteredCardDate[index].date}
      name={filteredCardDate[index].cardTypeName}
      isSelected={filteredCardDate[index].isSelected}
      onClickAction={props.onClick as any}
      showIcon={false}
      color={filteredCardDate[index].color}
      analysisTypeId={0}
      analysisResultId={0}
      style={style}
    />
  );

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <List height={465} itemCount={filteredCardDate.length} itemSize={55} width='100%'>
          {Row}
        </List>
      )}
    </div>
  );
};

export default CardDateList;
