import React from 'react';
import './WidgetData.scss';

export interface WidgetDataProps {
  id: string;
  name: string;
  value: number;
  color: string;
}

function WidgetData(props: WidgetDataProps) {
  const { name, value, color } = { ...props };

  return (
    <div className='widget-data-item-data'>
      <span className='widget-data-item-bullet' style={{ backgroundColor: `${color}` }} />
      <p className='widget-data-item-title'>{name} </p>
      <p className='widget-data-item-value'>{value} </p>
    </div>
  );
}

export default WidgetData;
