import React, { useEffect } from 'react';
import './NotificationList.scss';
import { useAppSelector } from '../../../../hooks/storeHooks';
import { Notification } from '../../../../models/Notification';

interface NotificationListProps {
  onEventTypeIdsChange: (eventTypeIds: number[]) => void;
}

const NotificationList = ({ onEventTypeIdsChange }: NotificationListProps) => {
  const notifications: Array<Notification> = useAppSelector((state) => state.notificationsList?.notifications);
  const filterId = useAppSelector((state) => state.notificationFilter.currentFilterId);

  const notificationsFiltered = () => {
    return (
      notifications?.filter((notification: Notification) => {
        const matchesFilter = filterId === null || notification.eventTypeId == filterId;
        return matchesFilter;
      }) || []
    );
  };

  useEffect(() => {
    const eventTypeIds = notifications.map((notification: Notification) => notification.eventTypeId);
    onEventTypeIdsChange(eventTypeIds);
  }, [notifications]);

  return (
    <>
      <div data-testid='notification-list' className='notification-wrapper'>
        <div>
          {notificationsFiltered().map((notification: Notification, index: number) => {
            return (
              <div key={index} className='comment-box notification-with-buttons'>
                <div>
                  <div className='notification-header'>
                    <span className='node-text'>{notification.nodeId}</span>
                    <span className='date-text'>{notification.date}</span>
                  </div>
                  {notification.eventTypeId == 1 ? (
                    <div className='comment-container'>
                      <div data-testid={`${index}-type`} className='notify-buttons blue'>
                        <span className='comment-text blue'>{notification.userId} </span>
                        <span>commented on a </span>
                        <span className='comment-text blue'>well</span>
                      </div>
                      <div className='comment-body-container'>
                        <span className='comment-body'>{notification.note}</span>
                      </div>
                    </div>
                  ) : (
                    <div data-testid={`${index}-type`} className='notify-buttons red'>
                      <a>{notification.eventTypeName}</a>
                    </div>
                  )}
                </div>
                {notification.eventTypeId != 1 && (
                  <span data-testid={`${index}-note`} className='notification-footer'>
                    {notification.note}
                  </span>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default NotificationList;
