import { OverLayFields } from '../models/OverlayFields';
import { PositioningType } from '../models/PositioningType';

export const JetPumpPositioning: PositioningType[] = [
  {
    overlayField: OverLayFields.LastGoodScan,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.CurrentCommunicationStatus,
    position: { x: 69, y: 166 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ControllerType,
    position: { x: 69, y: 192 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.TimeInState,
    position: { x: 69, y: 271 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.MotorType,
    position: { x: 69, y: 474 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.RodStrings,
    position: { x: 264, y: 474 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.PumpDepth,
    position: { x: 264, y: 605 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.PumpType,
    position: { x: 264, y: 643 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.StrokeLength,
    position: { x: 0, y: 194 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.StrokesPerMinute,
    position: { x: 0, y: 232 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.CasingPressure,
    position: { x: 0, y: 270 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.TubingPressure,
    position: { x: 0, y: 308 },
    justification: 'right',
  },
];
