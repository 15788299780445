import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchDataHistoryAlarmLimits, fetchDataHistoryTrendViews } from './TrendGroupLabelService';
import { RootState } from '../../../store';

interface State {
  AlarmLimits: AlarmLimits;
  startDate: string;
  endDate: string;
  customViews: CustomViews;
  activeChartKey: number;
  selectedView: ViewType;
  loading: boolean;
}

export interface AlarmLimits {
  values: AlarmLimitValues[];
}

export interface AlarmLimitValues {
  address: number;
  loLimit: number;
  loLoLimit: number;
  hiLimit: number;
  hiHiLimit: number;
}

export interface CustomViews {
  values: {
    viewId: number;
    viewName: string;
    startDate: string;
    endDate: string;
    isSelected: boolean;
    isGlobal?: boolean;
  }[];
}

interface ViewType {
  viewId: number;
  viewName: string;
  startDate: string;
  endDate: string;
  isSelected: boolean;
}
const initialState: State = {
  AlarmLimits: {
    values: [],
  },
  startDate: '',
  endDate: '',
  customViews: {
    values: [],
  },
  activeChartKey: 1,
  selectedView: {} as ViewType,
  loading: false,
};

interface FetchParameters {
  selectedAssetId: string | undefined;
  address: string;
}

// Async thunk to fetch the initial state data
export const fetchInitialTrendCustomViewsAsync = createAsyncThunk('groupStatusSlice/fetchInitialState', async () => {
  const data = await fetchDataHistoryTrendViews();
  return data;
});

const TrendGroupLabelSlice = createSlice({
  name: 'TrendGroupLabel',
  initialState,
  reducers: {
    setInitialState: () => {
      return initialState;
    },
    setDateRange: (state, action: { payload: { startDate: string; endDate: string } }) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    setActiveChartKey: (state, action: { payload: number }) => {
      state.activeChartKey = action.payload;
    },
    updateSelectedView: (state, action: { payload: ViewType }) => {
      state.selectedView = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInitialStateAsync.fulfilled, (state, action) => {
        const currentAlarmLimitState = { ...state.AlarmLimits }; // Create a shallow copy of AlarmLimits
        const alarmLimits = action.payload as AlarmLimits;
        const values: AlarmLimitValues[] = alarmLimits.values;

        if (!values || !values.length) return;
        values.forEach((value) => {
          const existingIndex = currentAlarmLimitState.values.findIndex((limit) => limit.address === value.address);

          if (existingIndex !== -1) {
            // If the value with the same address exists, remove it
            currentAlarmLimitState.values = [
              ...currentAlarmLimitState.values.slice(0, existingIndex),
              ...currentAlarmLimitState.values.slice(existingIndex + 1),
            ];
          } else {
            // Otherwise, add the new value
            currentAlarmLimitState.values = [...currentAlarmLimitState.values, value];
          }
        });

        return { ...state, AlarmLimits: currentAlarmLimitState };
      })
      .addCase(fetchInitialTrendCustomViewsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInitialTrendCustomViewsAsync.fulfilled, (state, action) => {
        state.customViews = action.payload as CustomViews;
        state.loading = false;
      })
      .addCase(fetchInitialTrendCustomViewsAsync.rejected, (state) => {
        state.loading = false;
      });
  },
});

// Async thunk to fetch the initial state data
export const fetchInitialStateAsync = createAsyncThunk(
  'trendGroupLabel/fetchInitialState',
  async ({ selectedAssetId, address }: FetchParameters) => {
    const data = await fetchDataHistoryAlarmLimits(selectedAssetId, address);
    return data;
  },
);

export const alarmLimits = (state: RootState) => state.trendGroupLabel.AlarmLimits;

export const date = (state: RootState) => state.trendGroupLabel.startDate;
export const activeChartKey = (state: RootState) => state.trendGroupLabel.activeChartKey; // Selector for activeChartKey
export const { setActiveChartKey, updateSelectedView, setInitialState } = TrendGroupLabelSlice.actions;
export const { setDateRange } = TrendGroupLabelSlice.actions;
export default TrendGroupLabelSlice.reducer;
