// Models
interface RGBColor {
  r: number;
  g: number;
  b: number;
}

// Constants
const colorMap: { [key: number]: RGBColor } = {
  /* order:
    1: Dark blue
    2: Yellow
    3: Light orange
    4: Cyan
    5: Success
    6: Error
    7: Teal
  */

  // 500
  1: { r: 96, g: 191, b: 218 },
  2: { r: 255, g: 191, b: 63 },
  3: { r: 239, g: 104, b: 32 },
  4: { r: 93, g: 171, b: 187 },
  5: { r: 18, g: 183, b: 106 },
  6: { r: 240, g: 68, b: 56 },
  7: { r: 85, g: 174, b: 168 },

  // 400
  8: { r: 66, g: 148, b: 255 },
  9: { r: 255, g: 208, b: 114 },
  10: { r: 246, g: 141, b: 46 },
  11: { r: 127, g: 189, b: 201 },
  12: { r: 50, g: 213, b: 131 },
  13: { r: 249, g: 112, b: 102 },
  14: { r: 122, g: 191, b: 186 },

  // 300
  15: { r: 137, g: 188, b: 255 },
  16: { r: 255, g: 217, b: 140 },
  17: { r: 248, g: 170, b: 98 },
  18: { r: 156, g: 204, b: 214 },
  19: { r: 108, g: 233, b: 166 },
  20: { r: 253, g: 162, b: 155 },
  21: { r: 152, g: 206, b: 202 },

  // 200
  22: { r: 184, g: 215, b: 255 },
  23: { r: 255, g: 229, b: 178 },
  24: { r: 250, g: 189, b: 133 },
  25: { r: 182, g: 217, b: 224 },
  26: { r: 166, g: 244, b: 197 },
  27: { r: 254, g: 205, b: 202 },
  28: { r: 179, g: 219, b: 216 },

  // 100
  29: { r: 208, g: 228, b: 255 },
  30: { r: 255, g: 238, b: 204 },
  31: { r: 251, g: 208, b: 168 },
  32: { r: 205, g: 229, b: 234 },
  33: { r: 209, g: 250, b: 223 },
  34: { r: 254, g: 228, b: 226 },
  35: { r: 203, g: 230, b: 228 },

  // 50
  36: { r: 231, g: 242, b: 255 },
  37: { r: 255, g: 246, b: 229 },
  38: { r: 253, g: 227, b: 203 },
  39: { r: 226, g: 240, b: 243 },
  40: { r: 236, g: 253, b: 243 },
  41: { r: 254, g: 243, b: 242 },
  42: { r: 255, g: 241, b: 240 },

  // 25
  43: { r: 241, g: 247, b: 255 },
  44: { r: 255, g: 251, b: 242 },
  45: { r: 254, g: 245, b: 238 },
  46: { r: 246, g: 250, b: 251 },
  47: { r: 246, g: 254, b: 249 },
  48: { r: 255, g: 251, b: 250 },
  49: { r: 245, g: 250, b: 250 },

  50: { r: 255, g: 255, b: 255 },
};

const glrColorMap: { [key: number]: RGBColor } = {
  /* order:
    1: Purple
    2: Pink

    // Cycle these
    3: Warning
    4: Dark blue
    5: Secondary
    7: Success
  */

  0: { r: 136, g: 77, b: 213 },
  1: { r: 218, g: 138, b: 201 },

  // 500
  2: { r: 247, g: 144, b: 9 },
  3: { r: 0, g: 109, b: 250 },
  4: { r: 250, g: 96, b: 32 },
  5: { r: 18, g: 183, b: 106 },

  // 400
  6: { r: 253, g: 176, b: 34 },
  7: { r: 63, g: 148, b: 255 },
  8: { r: 251, g: 116, b: 60 },
  9: { r: 50, g: 213, b: 131 },

  // 300
  10: { r: 254, g: 200, b: 75 },
  11: { r: 137, g: 188, b: 255 },
  12: { r: 255, g: 143, b: 97 },
  13: { r: 108, g: 233, b: 166 },

  // 200
  14: { r: 254, g: 223, b: 137 },
  15: { r: 184, g: 215, b: 255 },
  16: { r: 252, g: 166, b: 130 },
  17: { r: 166, g: 244, b: 197 },
};

// Exported Methods
export function getGLRCurveColor(colorIndex: number, isIPRCurve = false): RGBColor {
  if (isIPRCurve) {
    return {
      r: 240,
      g: 68,
      b: 56,
    } as RGBColor;
  }

  // Get the first 10 colors from the glr color map, then start using the regular color map
  if (Object.prototype.hasOwnProperty.call(glrColorMap, colorIndex)) {
    return glrColorMap[colorIndex];
  }

  const offset = Object.keys(glrColorMap).length;
  let adjustedIndex = colorIndex - offset;

  if (adjustedIndex < 0) {
    console.error('Invalid color index for GLR curve color');
    return generateRandomCardColor();
  }

  // ignore hard to read colors (index 3 and 5)
  if (adjustedIndex == 3) {
    adjustedIndex = 4;
  } else if (adjustedIndex == 4 || adjustedIndex == 5) {
    // since index 4 was used for 3, we skip ahead.
    adjustedIndex += 2;
  }

  return getCardColor(adjustedIndex);
}

export function getCardColor(colorIndex: number): RGBColor {
  if (Object.prototype.hasOwnProperty.call(colorMap, colorIndex)) {
    return colorMap[colorIndex];
  }

  return generateRandomCardColor();
}

// Helper Methods
function generateRandomCardColor(): RGBColor {
  let colorFound = false;

  let rValue = 0;
  let gValue = 0;
  let bValue = 0;

  while (!colorFound) {
    rValue = getRandomColorValue(10, 243);
    gValue = getRandomColorValue(10, 243);
    bValue = getRandomColorValue(20, 243);

    const yellow = isYellow(rValue, gValue, bValue);
    const lightGreen = isLightGreen(rValue, gValue, bValue);

    if (!yellow && !lightGreen) {
      colorFound = true;
    }
  }

  return {
    r: rValue,
    g: gValue,
    b: bValue,
  };
}

function getRandomColorValue(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function isYellow(r: number, g: number, b: number): boolean {
  return r > 210 && g > 210 && b < 45;
}

function isLightGreen(r: number, g: number, b: number): boolean {
  return r > 120 && g > 210 && b > 120;
}
