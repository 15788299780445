import './NavBar.scss';
import React, { useEffect, useState } from 'react';
import { ReactComponent as Icon } from '../../../images/filter-lines.svg';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { setNotificationButtons, switchActiveButton } from '../UIButtonToolbarSlice';
import UIButtonToolbar from './UIButtonToolbar';
import NotificationList from './notifications/NotificationList';
import NotificationFilterDropdown from './notifications/notification-filter-dropdown/NotificationFilterDropdown';
import { setCurrentFilterId } from '../NotificationFilterSlice';

interface NotificationButton {
  name: string;
  active: boolean;
  id: number;
}

const notificationButtons: NotificationButton[] = [
  {
    name: 'All',
    active: true,
    id: 0,
  },
  {
    name: 'Host',
    active: false,
    id: 5,
  },
  {
    name: 'RTU',
    active: false,
    id: 4,
  },
  {
    name: 'Comments',
    active: false,
    id: 1,
  },
];

const NotificationDrawerItem = () => {
  const notificationButtonsFromRedux = useAppSelector((state) => state.notificationButtons.notificationButtons);
  const [notificationTypeData, setNotificationTypeData] = useState([]);
  const notifications = useAppSelector((state) => state.notificationsList?.notifications);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [eventTypeIds, setEventTypeIds] = useState<number[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const uniqueNotitificatonEvents: any = notifications.filter((primaryNotification, index, self) => {
      return (
        self.findIndex(
          (secondaryNotification) => secondaryNotification.eventTypeName === primaryNotification.eventTypeName,
        ) === index
      );
    });
    setNotificationTypeData(uniqueNotitificatonEvents);
    if (notificationButtonsFromRedux.length === 0) {
      dispatch(setNotificationButtons(notificationButtons));
    }
  }, []);

  function buttonClicked(id: number) {
    dispatch(switchActiveButton({ id: id, buttonType: 'notification' }));
    dispatch(setCurrentFilterId(id === 0 ? null : id));
  }

  function filterClicked() {
    setIsModalOpen((prev) => !prev);
  }

  const handleEventTypeIdsChange = (updatedEventTypeIds: number[]) => {
    setEventTypeIds(updatedEventTypeIds);
  };

  return (
    <div data-testid='notification-drawer' className='navigation-bar-items'>
      <div className='navigation-bar-items-content'>
        <div className='navigation-content-headline'>Notifications</div>
        <div className='notification-content-top-container'>
          <div className='notification-content-button-filter'>
            <UIButtonToolbar uiButtons={notificationButtonsFromRedux} buttonClicked={buttonClicked} />
          </div>
          <div className='notification-content-filter'>
            <div className='filter-icon-container'>
              <button
                data-testid='notification-filter-icon-button'
                className='navigation-content-search-btn'
                onClick={filterClicked}
              >
                <Icon />
              </button>
            </div>
            {isModalOpen && (
              <NotificationFilterDropdown
                options={notificationTypeData
                  .map(
                    (notification: any) => ({
                      id: notification.eventTypeId,
                      name: notification.eventTypeName,
                    }),
                    [],
                  )
                  .filter((option) => eventTypeIds.includes(option.id))}
                onOptionChange={(selectedId) => buttonClicked(selectedId === null ? 0 : selectedId)}
              />
            )}
          </div>
        </div>
        <NotificationList onEventTypeIdsChange={handleEventTypeIdsChange} />
      </div>
    </div>
  );
};

export default NotificationDrawerItem;
