import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchGLAnalysisSurveyCurveCoordinatesInitialState } from './GLAnalysisService';
import { CardCoordinate } from '../../models/Card';

interface Cards {
  [id: string]: Card;
}

interface Card {
  id: string;
  cardTrends: CardTrend[];
}

interface CardTrend {
  id: number;
  name: string;
  curveTypeId: number;
  displayName: string;
  coordinatesOutput: CardCoordinate[];
}

interface State {
  loading: boolean;
  cards: Cards;
  glAnalysisSurveyCurveCoordinates: GLAnalysisSurveyCurveCoordinates | null | undefined;
  error: string | undefined;
}

interface GLAnalysisSurveyCurveCoordinates {
  id: string;
  dateCreated: string;
  values: SurveyCurveCoordinates[];
}

interface SurveyCurveCoordinates {
  id: string;
  name: string;
  curveTypeId: number;
  displayName: string;
  coordinatesOutput: CoordinatesOutputs[];
}

interface CoordinatesOutputs {
  x: string;
  y: string;
}

interface GlAnalysisSurveyParam {
  assetId: string;
  surveyDate: string;
}

export const fetchSurveyCurveCoordinatesInitialStateAsync = createAsyncThunk(
  'glAnalysisSurveyCurveCoordinatesSlice/fetchSurveyCurveCoordinatesInitialState',
  async (param: GlAnalysisSurveyParam) => {
    const data = await fetchGLAnalysisSurveyCurveCoordinatesInitialState(param.assetId, param.surveyDate);
    return data;
  },
);

const initialState: State = {
  loading: false,
  cards: {},
  glAnalysisSurveyCurveCoordinates: null,
  error: undefined,
};

export const glAnalysisSurveyCurveCoordinatesSlice = createSlice({
  name: 'glAnalysisSurveyCurveCoordinatesData',
  initialState,
  reducers: {
    setInitialState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSurveyCurveCoordinatesInitialStateAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSurveyCurveCoordinatesInitialStateAsync.fulfilled, (state, action) => {
        const assetId = action.meta.arg.assetId;

        const cardDate = action.meta.arg.surveyDate;
        const card: Card = {
          id: `${assetId}-${cardDate}`,
          cardTrends:
            action.payload.values &&
            action.payload.values
              .filter((x: CardTrend) => x.coordinatesOutput != null && x.name != null && x.displayName != null)
              .map((x: CardTrend) => {
                return {
                  id: x.id,
                  name: x.name,
                  coordinates: x.coordinatesOutput,
                  curveTypeId: x.curveTypeId,
                  displayName: x.displayName,
                };
              }),
        };

        if (state.cards[`${assetId}-${cardDate}`] == null) {
          state.cards[`${assetId}-${cardDate}`] = card;
        }

        state.loading = false;
      })
      .addCase(fetchSurveyCurveCoordinatesInitialStateAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setInitialState } = glAnalysisSurveyCurveCoordinatesSlice.actions;
export default glAnalysisSurveyCurveCoordinatesSlice.reducer;
