import * as am5core from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';

export const CreateTemperatureXAxis = (props: any) => {
  const temperatureXAxis = props[0].chart.xAxes.push(
    am5xy.ValueAxis.new(props[0].root, {
      renderer: am5xy.AxisRendererX.new(props[0].root, {
        opposite: true,
        width: props[0].temperatureAxisWidth,
        minGridDistance: 100,
      }),
      // handle case where min and max are never set. Chart won't display axis properly with bad values.
      min: props[0].temperatureMinX > props[0].temperatureMaxX ? 0 : props[0].temperatureMinX,
      max: props[0].temperatureMaxX < props[0].temperatureMinX ? 100 : props[0].temperatureMaxX,
      strictMinMax: true,
      width: props[0].temperatureAxisWidth,
      maxWidth: props[0].temperatureAxisWidth,
      x: props[0].startPosition,
      zoomX: false,
      panX: false,
    }),
  );
  temperatureXAxis.children.unshift(
    am5core.Label.new(props[0].root, {
      text: 'Temperature (°F)',
      x: am5core.p50,
      centerX: am5core.p50,
      marginTop: -25,
      fontSize: 12,
      fontWeight: '400',
      fill: props[0].color,
    }),
  );

  const temperatureRenderer = temperatureXAxis.get('renderer');
  temperatureRenderer.grid.template.setAll({
    visible: false,
  });

  return temperatureXAxis;
};
