import React, { useEffect } from 'react';
import './NodalAnalysisChart.scss';
import { Card, CardCoordinate, RGBColor, SelectedCardTrend } from '../../../../models/Card';
import * as am5core from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { CurveType } from '../../enums/CurveType';
import { getGLRCurveColor } from '../../../../utilities/CardColorGenerator';
import { findIntersects } from '../../../../utilities/CurveIntersectUtility';
import { useAppSelector } from '../../../../hooks/storeHooks';

interface NodalAnalysisChartProps {
  selectedCardTrends: SelectedCardTrend[];
  cards: Card[];
}

const NodalAnalysisChart: React.FC<NodalAnalysisChartProps> = (props) => {
  const themeMode = useAppSelector((state) => state.theme.mode);
  const isDarkMode = themeMode === 'dark';
  const defaultMin = 0;
  const defaultMax = 1;

  useEffect(() => {
    const root = am5core.Root.new('nodal-analysis-chart');

    // create chart.
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelY: 'zoomXY',
        maxTooltipDistance: -1,
        width: am5core.percent(100),
      }),
    );

    // create y axis.
    const yAxis = createYAxis();
    // create x axis.
    const xAxis = createXAxis();

    // calculate min and max for all axes.
    calculateMinMaxForAxes();
    // plot all series.
    props.selectedCardTrends?.forEach((selectedCardTrend) => {
      props.cards?.forEach((card) => {
        card.cardTrends?.forEach((cardTrend) => {
          if (cardTrend.curveTypeId == selectedCardTrend.id) {
            if (cardTrend.curveTypeId == CurveType.IPRCurve) {
              createIPRSeries(cardTrend.displayName, card.color, cardTrend.coordinates);
            } else {
              if (cardTrend.displayName.startsWith('GLR')) {
                createSeries(
                  cardTrend.displayName,
                  card.color,
                  cardTrend.coordinates,
                  selectedCardTrend.glrCurveColorId,
                );
              }
            }
          }
        });
      });
    });

    root.setThemes([am5themes_Animated.new(root)]);

    // create cursor.
    const customCursor = am5xy.XYCursor.new(root, {});
    chart.set('cursor', customCursor);

    return () => {
      root.dispose();
    };

    // function definitions
    function createYAxis() {
      const axisRendererY = am5xy.AxisRendererY.new(root, { minGridDistance: 37 });
      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          extraTooltipPrecision: 1,
          renderer: axisRendererY,
          min: defaultMin,
          max: defaultMax,
          strictMinMax: true,
        }),
      );
      axisRendererY.labels.template.set('fill', isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'));

      yAxis.children.unshift(
        am5core.Label.new(root, {
          text: 'Flowing bottomhole pressure (psi)',
          centerX: am5core.p50,
          y: am5core.p50,
          centerY: am5core.p50,
          marginLeft: -30,
          rotation: 270,
          fontSize: 12,
          fontWeight: '400',
          fill: isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'),
        }),
      );

      const yAxisRenderer = yAxis.get('renderer');
      yAxisRenderer.grid.template.setAll({
        strokeOpacity: 1,
        stroke: am5core.color('#D0D8DD'),
        strokeWidth: 1,
      });

      return yAxis;
    }

    function createXAxis() {
      const xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererX.new(root, { minGridDistance: 100 }),
          min: defaultMin,
          max: defaultMax,
          strictMinMax: true,
        }),
      );

      xAxis.children.push(
        am5core.Label.new(root, {
          text: 'Flow rate (bbl)',
          x: am5core.p50,
          centerX: am5core.p50,
          marginTop: -5,
          marginBottom: 5,
          fontSize: 12,
          fontWeight: '400',
          fill: isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'),
        }),
      );

      const xAxisRenderer = xAxis.get('renderer');
      xAxisRenderer.grid.template.setAll({
        strokeDasharray: [2.5],
        strokeOpacity: 1,
        strokeWidth: 1,
        stroke: am5core.color('#D0D8DD'),
      });
      xAxisRenderer.labels.template.set('fill', isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'));

      return xAxis;
    }

    function calculateMinMaxForAxes() {
      const allYValues: number[] = [];
      const allXValues: number[] = [];

      props.selectedCardTrends?.forEach((selectedCardTrend) => {
        props.cards?.forEach((card) => {
          card.cardTrends?.forEach((cardTrend) => {
            if (cardTrend.coordinates.length == 0 || cardTrend.curveTypeId != selectedCardTrend.id) {
              return;
            }

            cardTrend.coordinates;
            // accumulate all Y values for Y axis
            allYValues.push(...cardTrend.coordinates.map((coord) => coord.y));

            // accumulate all X values for X axis
            allXValues.push(...cardTrend.coordinates.map((coord) => coord.x));
          });
        });
      });

      // update axis bounds
      yAxis.set('max', allYValues.length > 0 ? Math.max(...allYValues) * 1.15 : defaultMax);
      updateXAxisMax(allXValues);
    }

    function updateXAxisMax(allXValues: number[]) {
      const allGLRIPRIntersects: CardCoordinate[] = [];

      props.cards.forEach((card) => {
        if (!card.cardTrends) {
          return;
        }

        const iprCurve = card.cardTrends.find((x) => x.curveTypeId === CurveType.IPRCurve);

        if (!iprCurve) {
          return;
        }

        const glrCurves = card.cardTrends.filter((x) => x.displayName?.startsWith('GLR'));

        glrCurves.forEach((curve) => {
          allGLRIPRIntersects.push(...findIntersects(curve.coordinates, iprCurve.coordinates));
        });
      });

      const glrIPRIntersectXValue =
        allGLRIPRIntersects.length > 0
          ? [...allGLRIPRIntersects].sort((coord1, coord2) => coord2.x - coord1.x)[0].x
          : 0;

      if (glrIPRIntersectXValue === 0) {
        xAxis.set('max', allXValues.length > 0 ? Math.max(...allXValues) * 1.15 : defaultMax);
      } else {
        xAxis.set('max', glrIPRIntersectXValue * 1.25);
      }
    }

    function createSeries(
      seriesName: string,
      color: RGBColor | undefined,
      coordinates: CardCoordinate[],
      glrCurveIndex?: number,
    ) {
      const tooltip = am5core.Tooltip.new(root, {
        labelText: '{name}\nX: {valueX}\nY: {valueY}',
        getFillFromSprite: false,
        getLabelFillFromSprite: false,
        autoTextColor: false,
      });

      let curveColor = am5core.color('#000000');

      if (seriesName.startsWith('GLR') && glrCurveIndex != null) {
        const glrCurveColorRGB = getGLRCurveColor(glrCurveIndex);
        curveColor = am5core.color(`rgb(${glrCurveColorRGB.r}, ${glrCurveColorRGB.g}, ${glrCurveColorRGB.b})`);
      }

      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: seriesName,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'y',
          valueXField: 'x',
          tooltip: tooltip,
          connect: false,
          stroke: color ? am5core.color(`rgb(${color.r}, ${color.g}, ${color.b})`) : curveColor,
        }),
      );

      series.strokes.template.setAll({
        strokeWidth: 2,
      });

      const seriesColor: am5core.Color = series.get('stroke') as am5core.Color;
      tooltip.get('background')?.set('fill', series.get('stroke'));
      tooltip.label.setAll({
        fill:
          seriesColor.r * 0.299 + seriesColor.g * 0.587 + seriesColor.b * 0.114 > 150
            ? am5core.color('#000')
            : am5core.color('#fff'),
        fontSize: 12,
        fontWeight: '400',
      });

      series.data.setAll(coordinates);
    }

    function createIPRSeries(seriesName: string, color: RGBColor | undefined, coordinates: CardCoordinate[]) {
      const tooltip = am5core.Tooltip.new(root, {
        labelText: '{name}\nX: {valueX}\nY: {valueY}',
        getFillFromSprite: false,
        getLabelFillFromSprite: false,
        autoTextColor: false,
      });

      const IPRCurveColor = getGLRCurveColor(0, true);

      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: seriesName,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'y',
          valueXField: 'x',
          tooltip: tooltip,
          connect: false,
          stroke: color
            ? am5core.color(`rgb(${color.r}, ${color.g}, ${color.b})`)
            : am5core.color(`rgb(${IPRCurveColor.r}, ${IPRCurveColor.g}, ${IPRCurveColor.b})`),
        }),
      );

      series.strokes.template.setAll({
        strokeWidth: 2,
      });

      series.strokes.template.set('strokeDasharray', [3, 3]);

      const seriesColor: am5core.Color = series.get('stroke') as am5core.Color;
      tooltip.get('background')?.set('fill', series.get('stroke'));
      tooltip.label.setAll({
        fill:
          seriesColor.r * 0.299 + seriesColor.g * 0.587 + seriesColor.b * 0.114 > 150
            ? am5core.color('#000')
            : am5core.color('#fff'),
        fontSize: 12,
        fontWeight: '400',
      });

      series.data.setAll(coordinates);
    }
  }, [props.cards?.length, props.selectedCardTrends?.length, isDarkMode]);

  return <div id='nodal-analysis-chart' className='nodal-analysis-chart'></div>;
};

export default NodalAnalysisChart;
