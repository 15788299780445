import React, { useRef, useState } from 'react';
import './CardHeader.scss';
import { ReactComponent as VerticalDots } from '../../../images/dots-vertical.svg';
import { useClickOutside } from '../Dropdown/DropdownClick';
import { ReactComponent as FullScreenIcon } from '../../../images/fullScreen.svg';

interface CardHeaderInterface {
  value: string;
  onClick?: () => void;
  isFullScreen?: boolean;
  icon?: string;
  showDropdown?: boolean;
}

const CardHeader: React.FC<CardHeaderInterface> = (props: CardHeaderInterface) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleButtonClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const dropdownRef = useRef<HTMLDivElement>(null);
  useClickOutside(dropdownRef, () => {
    setIsDropdownOpen(false);
  });

  return (
    <div className='card-header'>
      <div className='card-header-content'>
        <div className='card-header-frame'>
          <div className='card-header-text-container'>
            <div className={props.icon === 'verticalDots' ? 'widget-header-text' : 'card-header-text'}>
              {props.value}
            </div>
            <div>
              {props.icon === 'fullScreen' ? (
                <div className='card-header-icon' onClick={props.onClick}>
                  <FullScreenIcon />
                </div>
              ) : props.icon === 'verticalDots' ? (
                <div className='card-header-icon' onClick={handleButtonClick} ref={dropdownRef}>
                  <VerticalDots />
                  {props.showDropdown && isDropdownOpen && (
                    <div className='group-status-dropdown'>
                      <div className='group-status-download-container' onClick={props.onClick}>
                        Download CSV
                      </div>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardHeader;
