import { PositioningType } from '../models/PositioningType';
import { OverLayFields } from '../models/OverlayFields';

export const RegisterViewPositioning: PositioningType[] = [
  {
    overlayField: OverLayFields.FacilityComms,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.FacilityEnabled,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.FacilityTagCount,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.FacilityHostAlarm,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.FacilityAlarmCount,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.FacilityComment,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
];
