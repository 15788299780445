import api from '../../../../services/api';
import { wellControlInstance } from '../../../../services/api';
import { FetchEnabledStatusResponse, FetchWellControlActionsResponse } from '../models';

export const fetchWellControlActionsInitialState = async (assetId: string) => {
  const params = {
    assetId,
  };
  try {
    return await wellControlInstance
      .get<FetchWellControlActionsResponse>('wellcontrol/getwellcontrolactions', {
        params,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(function (response: any) {
        if (response) {
          return response.data;
        }
      });
  } catch (error) {
    console.error('Error fetching well control action initial state:', error);
    return [];
  }
};

export const fetchWellControlActionsAsync = async (assetId: string) => {
  return await wellControlInstance
    .get('wellcontrol/getwellcontrolactions?assetId=' + assetId, {
      headers: {
        'content-type': 'application/json',
      },
    })
    .then(function (response: any) {
      if (response) {
        return response.data;
      }
    });
};

const getToastMessage = (label: string, assetName: string | undefined) => {
  if (label && assetName) {
    return label + ' transaction sent for ' + assetName ?? 'well';
  } else {
    return 'Transaction sent succesffuly';
  }
};

export const executeWellControlActionAsync = async (
  assetId: string,
  wellControlActionId: number,
  socketId: string,
  controlActionName: string,
  wellName: string | undefined,
) => {
  const params = {
    assetId: assetId, // asset Id
    controlType: wellControlActionId, // enum of control types
    socketId: socketId, // web socket id
  };

  return await wellControlInstance.post('WellControl', params).then(function (response: any) {
    if (response && response.data) {
      return getToastMessage(controlActionName, wellName);
    } else {
      return null;
    }
  });
};

export const fetchEnabledStatusInitialState = async (assetId: string) => {
  const params = {
    assetId,
  };
  try {
    return await api
      .get<FetchEnabledStatusResponse>(`AssetData/GetEnabledStatus?assetId=${assetId}`, {
        params,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(function (response: any) {
        if (response) {
          return response.data;
        }
      });
  } catch (error) {
    console.error('Error fetching well control action initial state:', error);
    return [];
  }
};

export const fetchEnabledStatusAsync = async (assetId: string) => {
  const params = {
    assetId: assetId,
  };

  return await api
    .get<FetchEnabledStatusResponse>('AssetData/GetEnabledStatus', {
      params,
      headers: {
        'content-type': 'application/json',
      },
    })
    .then(function (response: any) {
      if (response) {
        return response.data;
      }
    });
};

export const enableWellAsync = async (assetId: string, socketId: string) => {
  const params = {
    assetId: assetId,
    dataCollection: '1',
    // TODO: There is logic in old xspoc client to provide a disable code if a system parameter is set.
    disableCode: '',
    enabled: '1',
    socketId: socketId,
  };

  return await wellControlInstance.put('WellControl/WellEnableDisable', params).then(function (response: any) {
    if (response) {
      return response.data;
    }
  });
};

export const disableWellAsync = async (assetId: string, socketId: string) => {
  const params = {
    assetId: assetId,
    dataCollection: '0',
    // TODO: There is logic in old xspoc client to provide a disable code if a system parameter is set.
    disableCode: '',
    enabled: '0',
    socketId: socketId,
  };

  return await wellControlInstance.put('WellControl/WellEnableDisable', params).then(function (response: any) {
    if (response) {
      return response.data;
    }
  });
};
