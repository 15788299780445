import Token from '../../models/Token';
import { LoginModel } from '../../models/Login';
import Helpers from '../../utilities/Helpers';
import api from '../api';
import TokenService from './token.service';
import { TokenRefreshModel } from '../../models/TokenRefresh';
import GrantTypeConstant from '../../constants/grant-type.constant';
import { v4 as uuidv4 } from 'uuid';

const logout = () => {
  TokenService.removeUser();
};

const isUserLoggedIn = () => {
  return TokenService.getUser() != null;
};

const login = async (login: LoginModel) => {
  const uuid = uuidv4();

  return await api
    .post(
      '/FormLogin',
      {
        grantType: GrantTypeConstant.form,
        userName: login.userName,
        passwordHash: login.passwordHash,
      },
      {
        headers: {
          'content-type': 'application/json',
          'xspoc-ui-tracking-id': uuid,
        },
      },
    )
    .then(function (response: any) {
      if (response) {
        const data: Token = response.data;
        TokenService.addOrUpdateLocalRefreshToken(data?.refreshToken);
        TokenService.addOrUpdateLocalAccessToken(data?.accessToken);
        TokenService.addOrUpdateLocalExpiresIn(data?.expiresIn);
        TokenService.addOrUpdateLocalCorrelationId(uuid);
        TokenService.setUser(data?.userName);

        if (data?.isFirstTimeLogin === true) {
          return 'firstTimeLogin';
        }

        return 'success';
      }

      return 'failed';
    })
    .catch(function (error) {
      Helpers.ConsoleLog(error, 'error');
      return 'failed';
    });
};

const refreshTokenFromServer = async () => {
  try {
    const refreshModel: TokenRefreshModel = {
      grantType: GrantTypeConstant.refreshToken,
      refreshToken: TokenService.getLocalRefreshToken(),
      userName: TokenService.getUser(),
    };
    const uuid = TokenService.getCorrelationId();

    await api
      .post('RefreshToken', refreshModel, {
        headers: {
          'content-type': 'application/json',
          'xspoc-ui-tracking-id': uuid,
        },
      })
      .then((response) => {
        if (response.status === 401) {
          return false;
        } else {
          const data: Token = response.data;
          TokenService.addOrUpdateLocalRefreshToken(data?.refreshToken);
          TokenService.addOrUpdateLocalAccessToken(data?.accessToken);
          TokenService.addOrUpdateLocalExpiresIn(data?.expiresIn);
          TokenService.setUser(data?.userName);
          return true;
        }
      })
      .catch(function (error) {
        Helpers.ConsoleLog(error, 'error');
        return false;
      });
    return false;
  } catch (error) {
    Helpers.ConsoleLog(error, 'error');
    return false;
  }
};

const getWellControlAction = async () => {
  const param: any = {
    assetId: '0ec78817-a654-47dc-8fc4-a8a139e573b0',
    testDate: '12/14/2022',
    analysisTypeId: 1,
    analysisResultId: 1358,
  };

  await api.get('Analytics/GLAnalysis' + (param as string)).then((response: any) => {
    if (response) {
      return response;
    } else {
      return null;
    }
  });
};

const AuthService = {
  logout,
  isUserLoggedIn,
  refreshTokenFromServer,
  login,
  getWellControlAction,
};

export default AuthService;
