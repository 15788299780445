import { OverLayFields } from '../models/OverlayFields';
import { PositioningType } from '../models/PositioningType';

export const MultiWellPlungerLiftPositioning: PositioningType[] = [
  {
    overlayField: OverLayFields.LastGoodScan,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.RTUAddress,
    position: { x: 577, y: 57 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.FirmwareVersion,
    position: { x: 577, y: 95 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.CurrentLoadFactor,
    position: { x: 577, y: 318 },
    justification: 'left',
  },
];
