import React from 'react';
import AuthService from '../../services/authentication/auth.service';

import { Navigate } from 'react-router-dom';

interface AuthComponentProps {
  component: React.FC;
}

const AuthComponent: React.FC<AuthComponentProps> = ({ component: Component }) => {
  return AuthService.isUserLoggedIn() ? <Component /> : <Navigate to='/login' replace />;
};

export default AuthComponent;
