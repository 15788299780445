import React, { useState, useEffect, useMemo } from 'react';
import './GLAnalysis.scss';
import UIIconButtonToolbar from '../../common/UIIconButtonToolbar/UIIconButtonToolbar';
import ViewToggle1 from '../../../images/view toggle-1.svg';
import ViewToggle2 from '../../../images/view toggle-2.svg';
import BarChart from '../../../images/bar-chart-07-DT.svg';
import PipeValve from '../../../images/valve-DT.svg';
import UIEyeButton from '../../common/UIButtons/UIEyeButton';
import UIFilterButton from '../../common/UIButtons/UIFilterButton';
import { HorizontalTabs } from '../../common/HorizontalTabs/HorizontalTabs';
import CardHeader from '../../common/CardHeader/CardHeader';
import { HorizontalTabsV2 } from '../../common/HorizontalTabs/HorizontalTabsV2';
import AnalysisInputOutput from '../../common/AnalysisInputOutput/AnalysisInputOutput';
import { useAppSelector, useAppDispatch } from '../../../hooks/storeHooks';
import XDAnalysis from '../../common/XDAnalysis/XDAnalysis';
import GlAnalysisWellTest from '../GLAnalyisisWellTest';
import Tooltip from '../../common/tooltip/ToolTip';
import PressureProfileChart from '../charts/pressure-profile/PressureProfileChart';
import PressureProfileWellbore from '../charts/pressure-profile/PressureProfileWellbore';
import PressureProfileLegend from '../charts/pressure-profile/PressureProfileLegend';
import CardTrendOptions from '../../common/CardTrendOptions/CardTrendOptions';
import NodalAnalysisChart from '../charts/nodal-analysis/NodalAnalysisChart';
import NodalAnalysisLegend from '../charts/nodal-analysis/NodalAnalysisLegend';
import { ChartType } from '../enums/ChartType';
import { CurveType } from '../enums/CurveType';
import { Card, CardCoordinate, CardTrend } from '../../../models/Card';
import CardTypeFilter from '../../common/UIFilterOptions/CardTypeFilter';
import PerformanceCurveChart from '../charts/performance-curve/PerformanceCurveChart';
import PerformanceCurveLegend from '../charts/performance-curve/PerformanceCurveLegend';
import GLValveAnalysis from '../valve-analysis/GLValveAnalysis';
import { setInitialState } from '../GLAnalysisSlice';
import { SurveyDataData, fetchSurveyDateInitialStateAsync } from '../GLAnalysisSurveyDateSlice';
import { useParams } from 'react-router-dom';
import { WellTestData } from '../GLAnalysisWellTestSlice';
import Timeline, { DateRange } from '../../common/timeline/Timeline';
import { AnalysisTimelineProps } from '../../asset-analysis/AssetAnalysis';
import TimelineDatepicker from '../../common/timeline/TimelineDatepicker';
import TimelineIcon from '../../common/timeline/TimelineIcon';
import { getGLRCurveColor } from '../../../utilities/CardColorGenerator';
import Loader from '../../common/Loader/Loader';
import Dropdown from '../../common/Dropdown/Dropdown';
import useFullScreenToggle from '../../../hooks/useFullScreenToggle';
import GLAnalysisResultHeader from './GLAnalysisResultHeader';

const glFilterCardType = [
  { id: 1, label: 'Well test' },
  { id: 2, label: 'Sensitivity analysis' },
];

const glCurveViewOptions = [
  {
    id: CurveType.ProductionPerformance,
    curveTypeId: CurveType.ProductionPerformance,
    label: 'Production performance curve',
    active: true,
  },
  {
    id: CurveType.PressurePerformance,
    curveTypeId: CurveType.PressurePerformance,
    label: 'Pressure performance curve',
    active: true,
  },
  {
    id: CurveType.FlowingBottomholePressure,
    curveTypeId: CurveType.FlowingBottomholePressure,
    label: 'Flowing bottomhole pressure performance curve',
    active: true,
  },
  {
    id: CurveType.ColemanCritFlowrateSurface,
    curveTypeId: CurveType.ColemanCritFlowrateSurface,
    label: 'Coleman crit flowrate at surface',
    active: true,
  },
  {
    id: CurveType.ColemanCritFlowrateDepth,
    curveTypeId: CurveType.ColemanCritFlowrateDepth,
    label: 'Coleman crit flowrate at inj. depth',
    active: true,
  },
  {
    id: CurveType.ThornhillCraverValve,
    curveTypeId: CurveType.ThornhillCraverValve,
    label: 'Mod* thornhill-craver valve CV',
    active: true,
  },
  {
    id: CurveType.ProductionOperatingPoint,
    curveTypeId: CurveType.ProductionOperatingPoint,
    label: 'Production operating point',
    active: true,
  },
  {
    id: CurveType.PressureOperatingPoint,
    curveTypeId: CurveType.PressureOperatingPoint,
    label: 'Pressure operating point',
    active: true,
  },
];

const glAnalysisPressureProfileViewOptions = [
  {
    id: CurveType.ProductionFluidPressure,
    curveTypeId: CurveType.ProductionFluidPressure,
    label: 'Production fluid curve',
    active: true,
  },
  {
    id: CurveType.ReservoirFluidHydrostatic,
    curveTypeId: CurveType.ReservoirFluidHydrostatic,
    label: 'Reservoir fluid hydrostatic curve',
    active: true,
  },
  {
    id: CurveType.KillFluidHydrostatic,
    curveTypeId: CurveType.KillFluidHydrostatic,
    label: 'Kill fluid hydrostatic curve',
    active: true,
  },
  {
    id: CurveType.GasInjectionPressure,
    curveTypeId: CurveType.GasInjectionPressure,
    label: 'Gas injection curve',
    active: true,
  },
  { id: CurveType.Temperature, curveTypeId: CurveType.Temperature, label: 'Temperature curve', active: true },
  {
    id: CurveType.InjectionRateForCriticalVelocity,
    curveTypeId: CurveType.InjectionRateForCriticalVelocity,
    label: 'GL injection rate for critical velocity',
    active: true,
  },
  {
    id: CurveType.TemperatureSurveyCurve,
    curveTypeId: CurveType.TemperatureSurveyCurve,
    label: 'Temperature survey curve',
    active: true,
  },
  {
    id: CurveType.PressureSurveyCurve,
    curveTypeId: CurveType.PressureSurveyCurve,
    label: 'Pressure survey curve',
    active: true,
  },
];

const chartOptions = [
  { id: ChartType.PerformanceCurve, label: 'Performance curve' },
  { id: ChartType.PressureProfile, label: 'Pressure profile' },
  { id: ChartType.NodalAnalysis, label: 'Nodal analysis' },
];

const defaultPressureProfileCurveColors = [
  {
    id: 8,
    name: 'Production fluid pressure curve',
    color: '#003747',
  },
  {
    id: 5,
    name: 'Reservoir fluid hydrostatic curve',
    color: '#DC4405',
  },
  {
    id: 6,
    name: 'Kill fluid hydrostatic curve',
    color: '#0094BD',
  },
  {
    id: 4,
    name: 'Gas injection pressure curve',
    color: '#6BBBAE',
  },
  {
    id: 7,
    name: 'Temperature curve',
    color: '#F79009',
  },
  {
    id: 29,
    name: 'Injection rate for critical velocity curve',
    color: '#006DFA',
  },
];

interface GLAnalysisProps {
  timelineProps: AnalysisTimelineProps;
}

export const GLAnalysis = ({ timelineProps }: GLAnalysisProps) => {
  const dispatch = useAppDispatch();
  const fetchSurveyDateStateAsync = fetchSurveyDateInitialStateAsync;
  const glAnalysisData = useAppSelector((state) => state.glAnalysisData?.glAnalysis);
  const [selectedTabId, setSelectedTabId] = useState(0);
  const [selectedChartId, setSelectedChartId] = useState(0);
  const [selectedCardTrends, setSelectedCardTrends] = useState(
    Array.of<{ id: number; label: string; active: boolean }>(),
  );
  const [selectedCards, setSelectedCards] = useState<Card[]>([]);
  const glAnalysisWellTest = useAppSelector((state) => state.glAnalysisWellTest);
  const glAnalysisSurveyWellTests = useAppSelector((state) => state.gLAnalysisSurveyDate);
  const glAnalysisCurveCoordinates = useAppSelector((state) => state.glAnalysisCurveCoordinates);
  const glAnalysisSurveyCoordinates = useAppSelector((state) => state.glAnalysisSurveyCurveCoordinates);
  const [selectedWellTestType, setSelectedWellTestType] = useState<string[]>([]);
  const [chartHeader, setChartHeader] = useState('Performance curve');
  const [nodalAnalysisStates, setNodalAnalysisStates] = useState(
    Array.of<{ id: number; label: string; active: boolean }>(),
  );
  const glAnalysisLoader = useAppSelector((state) => state.glAnalysisData?.loading);
  const isGlAnalysisWellTestLoading = useAppSelector((state) => state.glAnalysisWellTest.loading);
  const isGlAnalysisSurveyWellTestLoading = useAppSelector((state) => state.gLAnalysisSurveyDate.loading);
  const isGlAnalysisCurveCoordinatesLoading = useAppSelector((state) => state.glAnalysisCurveCoordinates.loading);
  const isGlAnalysisSurveyCurveCoordinatesLoading = useAppSelector(
    (state) => state.glAnalysisSurveyCurveCoordinates.loading,
  );

  const [isAnyLoading, setIsAnyLoading] = useState(false);

  const pressureProfileRef = React.useRef<HTMLDivElement>(null);
  const [pressureProfileHeight, setPressureProfileHeight] = useState(0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (!pressureProfileRef.current) {
        return;
      }

      setPressureProfileHeight(Math.max(pressureProfileRef.current.clientHeight - 140, 300));
    });

    if (pressureProfileRef.current) {
      resizeObserver.observe(pressureProfileRef.current);
    }

    return () => {
      if (pressureProfileRef.current) {
        resizeObserver.unobserve(pressureProfileRef.current);
      }
    };
  }, [pressureProfileRef.current]);

  useEffect(() => {
    setIsAnyLoading(
      isGlAnalysisWellTestLoading ||
        isGlAnalysisSurveyWellTestLoading ||
        isGlAnalysisCurveCoordinatesLoading ||
        isGlAnalysisSurveyCurveCoordinatesLoading,
    );
  }, [
    isGlAnalysisWellTestLoading,
    isGlAnalysisSurveyWellTestLoading,
    isGlAnalysisCurveCoordinatesLoading,
    isGlAnalysisSurveyCurveCoordinatesLoading,
  ]);

  const svgTogglePrimary = (
    <svg width='18' height='11' viewBox='0 0 18 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.5 1H5.5C4.09987 1 3.3998 1 2.86502 1.27248C2.39462 1.51217 2.01217 1.89462 1.77248 2.36502C1.5 2.8998 1.5 3.59987 1.5 5V6.28169C1.5 7.68182 1.5 8.38189 1.77248 8.91667C2.01217 9.38707 2.39462 9.76952 2.86502 10.0092C3.3998 10.2817 4.09987 10.2817 5.5 10.2817H12.5C13.9001 10.2817 14.6002 10.2817 15.135 10.0092C15.6054 9.76952 15.9878 9.38707 16.2275 8.91667C16.5 8.38189 16.5 7.68182 16.5 6.28169V5C16.5 3.59987 16.5 2.8998 16.2275 2.36502C15.9878 1.89462 15.6054 1.51217 15.135 1.27248C14.6002 1 13.9001 1 12.5 1Z'
        stroke='#0094BD'
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
  const svgToggleSecondary = (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.5 3.33337H6.5C5.09987 3.33337 4.3998 3.33337 3.86502 3.60248C3.39462 3.83919 3.01217 4.21691 2.77248 4.68148C2.5 5.20963 2.5 5.90102 2.5 7.2838V8.54961C2.5 9.93239 2.5 10.6238 2.77248 11.1519C3.01217 11.6165 3.39462 11.9942 3.86502 12.2309C4.3998 12.5 5.09987 12.5 6.5 12.5H13.5C14.9001 12.5 15.6002 12.5 16.135 12.2309C16.6054 11.9942 16.9878 11.6165 17.2275 11.1519C17.5 10.6238 17.5 9.93239 17.5 8.54961V7.2838C17.5 5.90102 17.5 5.20963 17.2275 4.68148C16.9878 4.21691 16.6054 3.83919 16.135 3.60248C15.6002 3.33337 14.9001 3.33337 13.5 3.33337Z'
        stroke='#F7F9F9'
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <ellipse cx='3.33333' cy='16.6667' rx='0.833333' ry='0.833333' fill='#F7F9F9' />
      <ellipse cx='6.66732' cy='16.6667' rx='0.833333' ry='0.833333' fill='#F7F9F9' />
      <ellipse cx='9.99935' cy='16.6667' rx='0.833333' ry='0.833333' fill='#F7F9F9' />
      <circle cx='13.3333' cy='16.6667' r='0.833333' fill='#F7F9F9' />
      <circle cx='16.6673' cy='16.6667' r='0.833333' fill='#F7F9F9' />
    </svg>
  );

  const uiIconButtons = [
    {
      key: 0,
      name: 'view-toggle-1',
      active: true,
      image: ViewToggle1,
      tooltip: 'Curve view',
      svgNode: svgTogglePrimary,
    },
    {
      key: 1,
      name: 'view-toggle-2',
      active: false,
      image: ViewToggle2,
      tooltip: 'Timeline view',
      svgNode: svgToggleSecondary,
    },
  ];

  const [uiIconButtonState, setUiIconButtonState] = useState(uiIconButtons);
  const [dateRangeFilteredCardDates, setDateRangeFilteredCardDates] = useState<
    WellTestData[] | SurveyDataData[] | null
  >(null);

  const { assetId } = useParams();
  const {
    startDate,
    endDate,
    minDate,
    maxDate,
    isDateLinked,
    showDateLinkIcon,
    setStartDate,
    setEndDate,
    setMinDate,
    setMaxDate,
    onClickLinkIcon,
    setShowDateLinkIcon,
  } = timelineProps;

  const [dateRange, setDateRange] = useState<DateRange | undefined>(undefined);

  useEffect(() => {
    if (!isAnyLoading && glAnalysisWellTest.glAnalysisWellTests && glAnalysisWellTest.glAnalysisWellTests.length > 0) {
      const dates = glAnalysisWellTest.glAnalysisWellTests.map((x) => new Date(x.date).getTime());
      const surveyDates = glAnalysisSurveyWellTests.glAnalysisSurveyDates?.map((x) => new Date(x.date).getTime()) ?? [];
      dates.push(...surveyDates);

      const minDate = new Date(Math.min(...dates));
      const maxDate = new Date(Math.max(...dates));

      if (minDate == maxDate) {
        minDate.setDate(minDate.getDate() - 1);
        maxDate.setDate(maxDate.getDate() + 1);
      }

      setDateRange({ startDate: minDate, endDate: maxDate });

      setMinDate(minDate);
      setMaxDate(maxDate);
    }
  }, [
    glAnalysisWellTest.glAnalysisWellTests?.length,
    glAnalysisSurveyWellTests.glAnalysisSurveyDates?.length,
    isAnyLoading,
  ]);

  useEffect(() => {
    const newUiIconButtonState = uiIconButtonState.map((button) => {
      if (button.name === 'view-toggle-1') {
        return { ...button, active: true };
      } else {
        return { ...button, active: false };
      }
    });

    setUiIconButtonState(newUiIconButtonState);
  }, [assetId]);

  useEffect(() => {
    if (isDateLinked) {
      const newUiIconButtonState = uiIconButtonState.map((button) => {
        if (button.name === 'view-toggle-2') {
          return { ...button, active: true };
        } else {
          return { ...button, active: false };
        }
      });

      setUiIconButtonState(newUiIconButtonState);
    }
  }, [isDateLinked]);

  useEffect(() => {
    if (uiIconButtonState[0].active) {
      setStartDate(null);
      setEndDate(null);
      setDateRangeFilteredCardDates(null);
      setShowDateLinkIcon(false);
    } else if (uiIconButtonState[1].active) {
      setShowDateLinkIcon(true);
    }
  }, [uiIconButtonState]);

  useEffect(() => {
    if (!isAnyLoading && startDate && endDate) {
      if (selectedTabId == 0) {
        const filteredCardDates =
          glAnalysisWellTest.glAnalysisWellTests?.filter((x) => {
            const cardDate = new Date(x.date);
            return cardDate >= startDate && cardDate <= endDate;
          }) ?? null;

        setDateRangeFilteredCardDates(filteredCardDates);
      } else if (selectedTabId == 1) {
        const filteredCardDates =
          glAnalysisSurveyWellTests.glAnalysisSurveyDates?.filter((x) => {
            const cardDate = new Date(x.date);
            return cardDate >= startDate && cardDate <= endDate;
          }) ?? null;

        setDateRangeFilteredCardDates(filteredCardDates);
      }
    }
  }, [
    glAnalysisWellTest.glAnalysisWellTests,
    startDate,
    endDate,
    selectedTabId,
    glAnalysisSurveyWellTests.glAnalysisSurveyDates,
    isAnyLoading,
  ]);

  const nodalAnalysisUniqueCurves = useMemo(() => {
    if (glAnalysisCurveCoordinates.loading) {
      return [];
    }

    const selectedCurveNames = new Set<string>();

    const getCardTrends = (cardDate: string, analysisTypeId: number, analysisResultId: number) => {
      const cardId = `${assetId}-${cardDate}-${analysisTypeId}-${analysisResultId}`;
      const curves = getGLRCurves(glAnalysisCurveCoordinates.cards[cardId]?.cardTrends);
      const iprCurve = getIPRCurve(glAnalysisCurveCoordinates.cards[cardId]?.cardTrends);
      if (iprCurve) {
        curves.push(iprCurve);
      }
      return curves;
    };

    const nodalAnalysisCards =
      glAnalysisWellTest.glAnalysisWellTests
        ?.filter((card) => card.isSelected)
        .map((card) => ({
          cardTrends: getCardTrends(card.date, card.analysisTypeId, card.analysisResultId),
        })) ?? [];

    const allCurves = nodalAnalysisCards.flatMap((card) => card.cardTrends);
    allCurves.forEach((curve) => selectedCurveNames.add(curve.displayName));

    const uniqueCurves = Array.from(selectedCurveNames)
      .map((name, index) => {
        const curve = allCurves.find((curve) => curve.displayName === name);
        if (curve !== undefined) {
          return {
            id: curve.curveTypeId,
            label: curve.displayName,
            active: nodalAnalysisStates[index] ? nodalAnalysisStates[index].active : true,
          };
        } else {
          return undefined;
        }
      })
      .filter((curve) => curve !== undefined)
      .sort((a, b) =>
        a?.label.startsWith('IPR')
          ? 1
          : parseInt(a?.label.split(' ').pop() ?? '0') > parseInt(b?.label.split(' ').pop() ?? '0')
          ? 1
          : -1,
      ) as Array<{
      id: number;
      label: string;
      active: boolean;
    }>;

    return uniqueCurves;
  }, [selectedChartId, glAnalysisCurveCoordinates, glAnalysisWellTest, glAnalysisSurveyWellTests]);

  useEffect(() => {
    if (selectedChartId === ChartType.PerformanceCurve) {
      setChartHeader('Performance curve');
      setSelectedCardTrends(glCurveViewOptions);
    } else if (selectedChartId === ChartType.PressureProfile) {
      setChartHeader('Pressure profile');
      setSelectedCardTrends(glAnalysisPressureProfileViewOptions);
    } else if (selectedChartId === ChartType.NodalAnalysis) {
      setChartHeader('Nodal analysis');
      if (nodalAnalysisUniqueCurves.length > 0) {
        setSelectedCardTrends(nodalAnalysisUniqueCurves);
      }
    }
  }, [selectedChartId, nodalAnalysisUniqueCurves, glCurveViewOptions, glAnalysisPressureProfileViewOptions]);

  useEffect(() => {
    dispatch(fetchSurveyDateStateAsync({ assetId: assetId !== undefined ? assetId : '' }));
  }, [dispatch]);

  useEffect(() => {
    if (isAnyLoading) {
      return;
    }

    setSelectedCards(getSelectedCards());
  }, [
    glAnalysisWellTest.glAnalysisWellTests,
    glAnalysisCurveCoordinates,
    glAnalysisSurveyWellTests,
    glAnalysisSurveyCoordinates,
    startDate,
    endDate,
    isAnyLoading,
  ]);

  // Set initial state when no cards are selected
  useEffect(() => {
    if (glAnalysisWellTest.selectedCardDates.length == 0 && glAnalysisData != null) {
      dispatch(setInitialState());
    }
  }, [glAnalysisWellTest.selectedCardDates]);

  const tabs = [
    {
      key: 0,
      value: 'Tests',
      active: true,
    },
    {
      key: 1,
      value: 'Surveys',
      active: false,
    },
  ];

  const tabsv2 = [
    {
      key: 0,
      value: 'Production analysis',
      active: true,
      imgSrc: BarChart,
    },
    {
      key: 1,
      value: 'Valve analysis',
      active: false,
      imgSrc: PipeValve,
    },
  ];

  const lastSelectedCardId = glAnalysisWellTest.selectedCardIds[glAnalysisWellTest.selectedCardIds.length - 1];
  const cardList = Object.values(glAnalysisWellTest.glAnalysisWellTests ?? []);
  let lastSelectedCard: WellTestData | null = null;
  if (cardList.length > 0) {
    lastSelectedCard =
      Object.values(glAnalysisWellTest.glAnalysisWellTests ?? []).find((x) => x.id == lastSelectedCardId) ?? null;
  }
  let lastSelectedCardIdString = '';
  if (lastSelectedCard != null) {
    lastSelectedCardIdString = `${assetId}-${lastSelectedCard.date}-${lastSelectedCard.analysisTypeId}-${lastSelectedCard.analysisResultId}`;
  }

  const inputData = glAnalysisData[lastSelectedCardIdString]?.inputs.map((item) => {
    return {
      id: item.id,
      title: item.displayName,
      value: item.displayValue,
    };
  });

  const outputData = glAnalysisData[lastSelectedCardIdString]?.outputs
    .filter((item) => item.id !== 'XDiagAnalysis')
    .map((item) => {
      return {
        id: item.id,
        title: item.displayName,
        value: item.displayValue,
      };
    });

  const valvesData = glAnalysisData[lastSelectedCardIdString]?.valves.map((v) => v.depth);

  const xDiagAnalysisData: string =
    glAnalysisData[lastSelectedCardIdString]?.outputs.find((item) => item.id === 'XDiagAnalysis')?.displayValue ?? '';

  const [tabState, setActiveTab] = useState(tabs);
  const [tabV2State, setV2ActiveTab] = useState(tabsv2);

  function buttonClicked(id: number) {
    const newArray = [...uiIconButtonState];
    newArray.forEach((button) => {
      if (id == button.key) {
        button.active = true;
      } else {
        button.active = false;
      }
    });
    setUiIconButtonState(newArray);

    return;
  }

  function tabClicked(id: number) {
    const newArray = tabState.map((button) => ({
      ...button,
      active: id === button.key,
    }));

    setActiveTab(newArray);
    setSelectedTabId(id);
  }
  function tabV2Clicked(id: number) {
    const newArray = tabV2State.map((button) => ({
      ...button,
      active: id === button.key,
    }));

    setV2ActiveTab(newArray);
  }

  const loadDepthCoordinates = (depthValves: CardCoordinate[]): CardCoordinate[] => {
    const coordinatesDepth: CardCoordinate[] = [];

    if (valvesData === undefined || valvesData === null || depthValves === undefined || depthValves === null) {
      return coordinatesDepth;
    }

    for (const valve of depthValves) {
      const matchingValveData = valvesData.find((val) => Math.floor(parseFloat(val)) === Math.floor(valve.y));
      if (matchingValveData !== undefined) {
        coordinatesDepth.push({ x: valve.x, y: parseFloat(matchingValveData) });
      }
    }

    return coordinatesDepth;
  };

  const getSelectedCards = () => {
    let selectedCards: Card[] = [];
    // get selected cards for well tests
    selectedCards =
      glAnalysisWellTest.glAnalysisWellTests
        ?.filter((x) => x.isSelected)
        .filter((cardDate) =>
          startDate && endDate ? new Date(cardDate.date) >= startDate && new Date(cardDate.date) <= endDate : true,
        )
        .map(
          (x) =>
            ({
              cardDate: x.date,
              cardTrends:
                glAnalysisCurveCoordinates.cards[`${assetId}-${x.date}-${x.analysisTypeId}-${x.analysisResultId}`]
                  ?.cardTrends,
              color: x.color,
            }) as Card,
        )
        .filter((card) => card.cardTrends !== undefined && card.cardTrends.length > 0) ?? [];

    // get selected cards for survey dates
    const glAnalysisSurveyDatesData =
      glAnalysisSurveyWellTests.glAnalysisSurveyDates
        ?.filter((x) => x.isSelected)
        .filter((cardDate) =>
          startDate && endDate ? new Date(cardDate.date) >= startDate && new Date(cardDate.date) <= endDate : true,
        )
        .map(
          (x) =>
            ({
              cardDate: x.date,
              cardTrends: glAnalysisSurveyCoordinates.cards[`${assetId}-${x.date}`]?.cardTrends,
              color: x.color,
            }) as unknown as Card,
        )
        .filter((card) => card.cardTrends !== undefined && card.cardTrends.length > 0) ?? [];

    selectedCards.push(...glAnalysisSurveyDatesData);

    return selectedCards;
  };

  const handleWellTestTypeChange = (value: any, label: any) => {
    value
      ? !selectedWellTestType.includes(label) && setSelectedWellTestType([...selectedWellTestType, label])
      : setSelectedWellTestType(selectedWellTestType.filter((item) => item !== label));
  };

  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  const handleClick = () => {
    setIsFullScreen(!isFullScreen);
  };

  useFullScreenToggle(setIsFullScreen);

  useEffect(() => {
    const htmlElement = document.documentElement;
    if (isFullScreen) {
      htmlElement.classList.add('fullscreen');
    } else {
      htmlElement.classList.remove('fullscreen');
    }
  }, [isFullScreen]);

  return (
    <div className={isFullScreen ? 'full-screen' : ''}>
      <div className={`scrollable-container ${isFullScreen ? 'card-viewer-full-screen-override' : ''}`}>
        <div
          className={`performance-curve-frame gl-performance-curve-frame h-[1016px] ${
            isFullScreen ? '' : 'rounded-[8px]'
          }`}
        >
          <CardHeader value={chartHeader} icon='fullScreen' isFullScreen={isFullScreen} onClick={handleClick} />
          <div className='gl-header'>
            <div className='content-header-frame'>
              <div className='flex flex-row items-center gap-[12px]'>
                <UIIconButtonToolbar uiIconButtons={uiIconButtonState} buttonClicked={buttonClicked} />
                <div className='select-wrap'>
                  <Dropdown
                    height='44px'
                    width='218px'
                    defaultOption={chartOptions.find((option) => option.id === selectedChartId)?.label}
                    onChange={(value) => {
                      setSelectedChartId(parseInt(value.id));
                    }}
                    assets={chartOptions.map((option) => {
                      return {
                        id: option.id.toString(),
                        name: option.label,
                        isSelected: option.id === selectedChartId,
                      };
                    })}
                  />
                </div>
              </div>
              <div className='toolbar-dropdown-container'>
                {uiIconButtonState[1].active && (
                  <div className='card-viewer-date-picker-container'>
                    {showDateLinkIcon && (
                      <div onClick={() => onClickLinkIcon(0)}>
                        <TimelineIcon isDateLinked={isDateLinked} />
                      </div>
                    )}
                    <div className='card-calendar-width card-calendar'>
                      <TimelineDatepicker {...timelineProps} />
                    </div>
                  </div>
                )}
                <Tooltip content='Curve view options' direction='top'>
                  <CardTrendOptions
                    label='View options'
                    options={selectedCardTrends}
                    buttonContent={
                      <div className='card-viewer-button'>
                        <UIEyeButton />
                      </div>
                    }
                    onOptionChange={(id, value) => {
                      setSelectedCardTrends((selectedCardTrends) => {
                        const newSelectedCardTrends = [...selectedCardTrends];
                        const index = newSelectedCardTrends.findIndex((x) => x.id == id);
                        newSelectedCardTrends[index].active = value;
                        if (selectedChartId == ChartType.NodalAnalysis) {
                          setNodalAnalysisStates(newSelectedCardTrends);
                        }
                        return newSelectedCardTrends;
                      });
                    }}
                  />
                </Tooltip>
                <Tooltip content='Well test filter' direction='top'>
                  <CardTypeFilter
                    label='Filter card type'
                    options={glFilterCardType}
                    buttonContent={
                      <div className='card-viewer-button'>
                        <UIFilterButton />
                      </div>
                    }
                    onOptionChange={(value, label) => {
                      handleWellTestTypeChange(value, label);
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className='performance-curve-content'>
            <div className='performance-curve-graph'>
              <div className='card-viewer-card gl-card-viewer-card h-full'>
                <div className='flex flex-row flex-grow flex-shrink h-full'>
                  <div className='py-4' style={{ width: '100%', display: 'flex', position: 'relative' }}>
                    {(isAnyLoading || glAnalysisLoader) && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          zIndex: 100,
                        }}
                      >
                        <Loader />
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        width: '100%',
                        opacity: isAnyLoading || glAnalysisLoader ? 0.5 : 1,
                      }}
                    >
                      {selectedChartId == ChartType.PerformanceCurve ? (
                        <div className='gl-analysis-chart-container'>
                          <div className='gl-analysis-performance-curve-chart'>
                            <PerformanceCurveChart
                              selectedCardTrends={glCurveViewOptions.filter((x) => x.active)}
                              cards={selectedCards}
                            />
                          </div>
                          <div>
                            <PerformanceCurveLegend
                              activeLegendItems={glCurveViewOptions.filter((x) => x.active)}
                              cardColor={selectedCards[selectedCards.length - 1]?.color}
                            />
                          </div>
                        </div>
                      ) : selectedChartId == ChartType.PressureProfile ? (
                        <div className='gl-analysis-chart-container'>
                          <div className='gl-analysis-pressure-profile-well-and-chart' ref={pressureProfileRef}>
                            <div
                              className='gl-analysis-pressure-profile-wellbore'
                              style={{
                                height: pressureProfileHeight,
                              }}
                            >
                              <PressureProfileWellbore
                                wellbore={glAnalysisData[lastSelectedCardIdString]?.wellboreViewData}
                                valves={glAnalysisData[lastSelectedCardIdString]?.valves}
                                height={pressureProfileHeight}
                              />
                            </div>
                            <div className='gl-analysis-pressure-profile-chart'>
                              <PressureProfileChart
                                selectedCardTrends={glAnalysisPressureProfileViewOptions?.filter((x) => x.active)}
                                cards={selectedCards}
                                loadDepthCoordinates={loadDepthCoordinates}
                              />
                            </div>
                          </div>
                          <div>
                            <PressureProfileLegend
                              legendItems={glAnalysisPressureProfileViewOptions
                                .filter(
                                  (x) =>
                                    x.active &&
                                    (glAnalysisWellTest.glAnalysisWellTests?.filter((x) => x.isSelected).length ??
                                      0 > 0),
                                )
                                .map((x) => {
                                  const selectedWellTestDates =
                                    glAnalysisWellTest.glAnalysisWellTests?.filter((x) => x.isSelected) ?? [];
                                  const color =
                                    selectedWellTestDates.length > 0
                                      ? selectedWellTestDates[selectedWellTestDates.length - 1].color ?? undefined
                                      : undefined;
                                  let stringColor: string | null = null;
                                  if (color) {
                                    stringColor = `rgb(${color.r}, ${color.g}, ${color.b})`;
                                  }
                                  return {
                                    id: x.id,
                                    name: x.label,
                                    color:
                                      stringColor ??
                                      defaultPressureProfileCurveColors.find((cc) => cc.id == x.id)?.color,
                                  };
                                })}
                            />
                          </div>
                        </div>
                      ) : selectedChartId == ChartType.NodalAnalysis ? (
                        <div className='gl-analysis-chart-container'>
                          <NodalAnalysisChart
                            selectedCardTrends={selectedCardTrends
                              .map((x, index) => {
                                return { id: x.id, label: x.label, active: x.active, glrCurveColorId: index };
                              })
                              ?.filter((x) => x.active)}
                            cards={selectedCards}
                          />
                          <NodalAnalysisLegend
                            legendItems={selectedCardTrends
                              ?.filter((x) => x.active)
                              .map((x, index) => {
                                const isIPRCurve = x.id === 19;
                                const color = getGLRCurveColor(index, isIPRCurve);

                                const selectedWellTestDates =
                                  glAnalysisWellTest.glAnalysisWellTests?.filter((x) => x.isSelected) ?? [];
                                const cardColor =
                                  selectedWellTestDates.length > 0
                                    ? selectedWellTestDates[selectedWellTestDates.length - 1].color ?? undefined
                                    : undefined;
                                let stringColor: string | null = null;

                                if (cardColor) {
                                  stringColor = `rgb(${cardColor.r}, ${cardColor.g}, ${cardColor.b})`;
                                }

                                return {
                                  id: x.id,
                                  name: x.label,
                                  color: stringColor ?? `rgb(${color.r}, ${color.g}, ${color.b})`,
                                };
                              })}
                          />
                        </div>
                      ) : null}
                      {uiIconButtonState[1].active && (
                        <div className='flex p-4'>
                          <Timeline
                            cards={glAnalysisWellTest.glAnalysisWellTests}
                            minDate={minDate}
                            maxDate={maxDate}
                            selectedStartDate={startDate}
                            selectedEndDate={endDate}
                            onChange={(startDate, endDate) => {
                              setMinDate(startDate);
                              setStartDate(startDate);
                              setMaxDate(endDate);
                              setEndDate(endDate);
                            }}
                            dateRange={dateRange}
                            surveyCards={glAnalysisSurveyWellTests.glAnalysisSurveyDates ?? undefined}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='card-viewer-date-list gl-card-viewer-date-list'>
                    <div className='tabs'>
                      <HorizontalTabs data={tabState} tabClicked={tabClicked} />
                    </div>
                    <div className='tab-content'>
                      {
                        <GlAnalysisWellTest
                          dateRangeFilteredCardDates={dateRangeFilteredCardDates}
                          tabId={selectedTabId}
                          selectedWellTestType={selectedWellTestType}
                          height={778}
                        />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`performance-data-frame gl-performance-data-frame ${
            isFullScreen ? 'gl-performance-data-frame-fullscreen' : ''
          }`}
        >
          <div className='gl-analysis-performance-data-header'>
            <GLAnalysisResultHeader title='Performance data' description={''} />
          </div>
          <>
            <HorizontalTabsV2 data={tabV2State} tabClicked={tabV2Clicked} />
            <div className='gl-liftanalysis-body'>
              <div className='gl-liftanalysis-scroll-area'>
                {isAnyLoading || glAnalysisLoader ? (
                  <Loader />
                ) : (
                  <>
                    {tabV2State.find((a) => a.active)?.key == 0 ? (
                      <>
                        <AnalysisInputOutput headerText='Input' values={inputData} loading={glAnalysisLoader} />
                        <AnalysisInputOutput
                          headerText='Output'
                          values={outputData?.filter((item) => !item.value.includes('last ran')) || []}
                          loading={glAnalysisLoader}
                        />
                      </>
                    ) : (
                      <GLValveAnalysis cardId={lastSelectedCardIdString} />
                    )}
                    <XDAnalysis headerText='X-Diag analysis' value={xDiagAnalysisData} loading={glAnalysisLoader} />
                  </>
                )}
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

// Function definitions
function getGLRCurves(cards: CardTrend[] | undefined): CardTrend[] {
  if (!cards) {
    return Array<CardTrend>();
  }

  return cards.filter((card) => card.displayName.startsWith('GLR'));
}

function getIPRCurve(cards: CardTrend[] | undefined): CardTrend | undefined {
  if (!cards) {
    return undefined;
  }

  return cards.find((card) => card.curveTypeId == CurveType.IPRCurve);
}
