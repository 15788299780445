import React from 'react';
import './ADLoginError.scss';
import PrimaryX from '../../images/primary_X.svg';
import adloginerrorImg from '../../images/adloginerror.svg';

const ADLoginError = () => {
  return (
    <div className='adloginerror-background'>
      <div className='adloginerror-message'>
        <img alt='PrimaryX' src={PrimaryX} className='adloginerror-inlineblock mr-5' />
        <div className='adloginerror-inlineblock font-bold'>ChampionX</div>
        <br />
        <br />
        <div className='text-xs'>
          We're sorry, but we were unable to log you in at this time, Please review the fallowing information and try
          again:
        </div>
        <br />
        <ul>
          <li className='adloginerror-dot'>
            <div className='text-xs '>
              Double-check that your internet connection is stable and functioning properly.
            </div>
          </li>
        </ul>
        <br />
        <div className='text-xs'>
          If the problem persists, Please contact your administrator for further assistance.
        </div>
        <div className='text-xs adloginerror-xspocmargin'>@ XSPOC 2023</div>
      </div>
      <div className='adloginerror-logo'>
        <img alt='adloginerrorImg' src={adloginerrorImg} className='w-100 adloginerror-imgborder' />
      </div>
    </div>
  );
};

export default ADLoginError;
