import Loader from '../Loader/Loader';

import './XDAnalysis.css';

export interface XDAnalysisProps {
  headerText: string;
  value: string;
  loading: boolean | false;
}

const XDAnalysis = (props: XDAnalysisProps) => {
  return (
    <div className='flex-grow flex-shrink analysis-result-item-container xd-analysis-container'>
      <div className='analysis-result-item-header-container'>
        <label className='analysis-result-item-header-text'>{props.headerText}</label>
      </div>
      {props.loading && <Loader />}
      <div className='xd-analysis-text-container'>
        <textarea className='xd-analysis-textarea' readOnly value={props.value} />
      </div>
    </div>
  );
};

export default XDAnalysis;
