import * as am5core from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';

export const CreateValvePointSeries = (props: any) => {
  const tooltip = am5core.Tooltip.new(props[0].root, {
    labelText: '{name}\nX: {valueX}\nY: {valueY}',
    getFillFromSprite: false,
    getLabelFillFromSprite: false,
    autoTextColor: false,
  });

  const series = props[0].chart.series.push(
    am5xy.LineSeries.new(props[0].root, {
      name: props[0].seriesName,
      xAxis: props[0].xAxis,
      yAxis: props[0].yAxis,
      valueYField: 'y',
      valueXField: 'x',
      tooltip: tooltip,
      connect: false,
    }),
  );

  series.bullets.push(function (root: any) {
    return am5core.Bullet.new(root, {
      sprite: am5core.Triangle.new(root, {
        width: 15,
        height: 23,
        fill: am5core.color('#E7F2FF'),
        centerX: am5core.p50,
        centerY: am5core.p50,
        rotation: 180,
      }),
    });
  });

  const seriesColor: am5core.Color = series.get('stroke') as am5core.Color;
  tooltip.get('background')?.set('fill', series.get('stroke'));
  tooltip.label.setAll({
    fill:
      seriesColor.r * 0.299 + seriesColor.g * 0.587 + seriesColor.b * 0.114 > 150
        ? am5core.color('#000')
        : am5core.color('#fff'),
    fontSize: 12,
    fontWeight: '400',
  });

  series.data.setAll(props[0].coordinates);
};
