import * as am5core from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';

export const CreateYAxis = (props: any) => {
  const yAxis = props[0].chart.yAxes.push(
    am5xy.ValueAxis.new(props[0].root, {
      extraTooltipPrecision: 1,
      renderer: am5xy.AxisRendererY.new(props[0].root, { minGridDistance: 37, inversed: true }),
      min: props[0].minY,
      max: props[0].maxY,
    }),
  );

  yAxis.children.unshift(
    am5core.Label.new(props[0].root, {
      text: 'Depth (ft)',
      centerX: am5core.p50,
      y: am5core.p50,
      centerY: am5core.p50,
      marginLeft: -30,
      rotation: 270,
      fontSize: 12,
      fontWeight: '400',
      fill: props[0].color,
    }),
  );

  const yAxisRenderer = yAxis.get('renderer');
  yAxisRenderer.grid.template.setAll({
    strokeOpacity: 1,
    stroke: am5core.color('#4A5463'),
    strokeWidth: 2,
  });

  return yAxis;
};
