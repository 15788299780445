import { OverLayFields } from '../models/OverlayFields';
import { PositioningType } from '../models/PositioningType';

export const ReverseMarkPositioning: PositioningType[] = [
  {
    overlayField: OverLayFields.LastGoodScan,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ApiDesignation,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.CurrentCommunicationStatus,
    position: { x: 120, y: 223 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ControllerType,
    position: { x: 119, y: 264 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.RunStatus,
    position: { x: 120, y: 302 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.TimeInState,
    position: { x: 120, y: 340 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.MotorType,
    position: { x: 173, y: 454 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.MotorLoading,
    position: { x: 173, y: 492 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.StructuralLoading,
    position: { x: 0, y: 196 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.StrokesPerMinute,
    position: { x: 0, y: 234 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.StrokeLength,
    position: { x: 0, y: 272 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.CyclesToday,
    position: { x: 0, y: 310 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.CyclesYesterday,
    position: { x: 0, y: 348 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.TubingPressure,
    position: { x: 204, y: 454 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.CasingPressure,
    position: { x: 204, y: 492 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.RodLoading,
    position: { x: 0, y: 624 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.PumpFillage,
    position: { x: 0, y: 662 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.RodStrings,
    position: { x: 446, y: 550 },
    justification: 'left',
  },
];
