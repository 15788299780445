import { OverLayFields } from '../models/OverlayFields';
import { PositioningType } from '../models/PositioningType';

export const PlungerLiftPositioning: PositioningType[] = [
  {
    overlayField: OverLayFields.LastGoodScan,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.CurrentCommunicationStatus,
    position: { x: 577, y: 349 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.TubingPressure,
    position: { x: 359, y: 111 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.CasingPressure,
    position: { x: 305, y: 492 },
    justification: 'left',
  },
];
