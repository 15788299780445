import { OverLayFields } from '../models/OverlayFields';
import { PositioningType } from '../models/PositioningType';

export const Mark2Positioning: PositioningType[] = [
  {
    overlayField: OverLayFields.LastGoodScan,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ApiDesignation,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.CurrentCommunicationStatus,
    position: { x: 119, y: 232 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ControllerType,
    position: { x: 119, y: 270 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.RunStatus,
    position: { x: 119, y: 308 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.TimeInState,
    position: { x: 119, y: 346 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.MotorType,
    position: { x: 170, y: 465 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.MotorLoading,
    position: { x: 170, y: 503 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.TubingPressure,
    position: { x: 210, y: 465 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.CasingPressure,
    position: { x: 210, y: 502 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.RodStrings,
    position: { x: 250, y: 563 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.StructuralLoading,
    position: { x: 0, y: 208 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.StrokesPerMinute,
    position: { x: 0, y: 246 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.StrokeLength,
    position: { x: 0, y: 284 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.CyclesToday,
    position: { x: 0, y: 322 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.CyclesYesterday,
    position: { x: 0, y: 360 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.RodLoading,
    position: { x: 0, y: 620 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.PumpFillage,
    position: { x: 0, y: 658 },
    justification: 'right',
  },
];
