import { useEffect, useCallback } from 'react';

function useFullScreenToggle(setIsFullScreen: (isFullScreen: boolean) => void) {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsFullScreen(false);
      }
    },
    [setIsFullScreen],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
}

export default useFullScreenToggle;
