import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchGetDefaultTrendsData } from '../DataHistoryService';
import { mapObject } from './TrendLibraryUtility';

interface State {
  overlayLoading: boolean;
  groupLoading: boolean;
  error: string | undefined;
  overlayDefaultCustomViewTrends: { chart1: []; chart2: []; chart3: []; chart4: [] };
  groupDefaultCustomViewTrends: { chart1: []; chart2: []; chart3: []; chart4: [] };
}

interface FetchParameters {
  assetId: string;
  viewId: string | undefined;
  startDate: string | undefined;
  endDate: string | undefined;
  isOverlay?: number;
}

// Async thunk to fetch data history trends
export const fetchGetDefaultTrendsDataAsync = createAsyncThunk(
  'trendItemSlice/fetchGetDefaultTrendsData',
  async (view: FetchParameters) => {
    const now = new Date();
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
    const yesterday = new Date(now.getTime() - oneDayInMilliseconds);

    const startDate = yesterday.toLocaleString('en');
    const endDate = new Date().toLocaleString('en');

    const data = await fetchGetDefaultTrendsData(
      view.assetId,
      view.viewId ?? '',
      view.startDate ?? startDate,
      view.endDate ?? endDate,
      view.isOverlay,
    );
    if (data) {
      return data;
    }
  },
);

const initialState: State = {
  overlayLoading: false,
  groupLoading: false,
  error: undefined,
  overlayDefaultCustomViewTrends: { chart1: [], chart2: [], chart3: [], chart4: [] },
  groupDefaultCustomViewTrends: { chart1: [], chart2: [], chart3: [], chart4: [] },
};

export const CustomViewDataSlice = createSlice({
  name: 'trendItem',
  initialState,
  reducers: {
    setInitialState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetDefaultTrendsDataAsync.pending, (state, action: any) => {
        const isOverlay = action.meta.arg.isOverlay;
        if (isOverlay) {
          state.overlayLoading = true;
        } else {
          state.groupLoading = true;
        }
      })
      .addCase(fetchGetDefaultTrendsDataAsync.fulfilled, (state, action: any) => {
        const isOverlay = action.meta.arg.isOverlay;
        if (isOverlay) {
          state.overlayLoading = false;
        } else {
          state.groupLoading = false;
        }

        if (isOverlay) {
          if (action.payload.values) {
            const chartsData = mapObject(action.payload.values, action.payload.dateCreated);
            for (const chart in chartsData) {
              chartsData[chart] = removeDuplicates(chartsData[chart], 'name');
            }
            state.overlayDefaultCustomViewTrends = chartsData;
          }
        } else {
          if (action.payload.values) {
            const chartsData = mapObject(action.payload.values, action.payload.dateCreated);
            for (const chart in chartsData) {
              chartsData[chart] = removeDuplicates(chartsData[chart], 'name');
            }
            state.groupDefaultCustomViewTrends = chartsData;
          }
        }
      })
      .addCase(fetchGetDefaultTrendsDataAsync.rejected, (state, action) => {
        const isOverlay = action.meta.arg.isOverlay;

        if (isOverlay) {
          state.overlayLoading = false;
        } else {
          state.groupLoading = false;
        }

        state.error = action.error.message;
      });
  },
});

export const { setInitialState } = CustomViewDataSlice.actions;
export default CustomViewDataSlice.reducer;

function removeDuplicates(array: any[], prop: any) {
  return array.reduce((acc, obj) => {
    if (!acc.some((item: any) => item[prop] === obj[prop])) {
      acc.push(obj);
    }
    return acc;
  }, []);
}
