import React from 'react';
import { ReactComponent as Filterfunnel } from '../../../images/filter-funnel-01.svg';
import './UIEyeAndFilterButtons.scss';

function UIFilterButton() {
  return (
    <div className='card-viewer-button'>
      <Filterfunnel />
    </div>
  );
}

export default UIFilterButton;
