import { DisplayStatusState } from './enums/DisplayStatusState';

export const DisplayStatusStateCssClassMap = new Map<number, string>([
  [DisplayStatusState.Normal, 'normal'],
  [DisplayStatusState.OK, 'good'],
  [DisplayStatusState.Warning, 'warning'],
  [DisplayStatusState.Error, 'error'],
  [DisplayStatusState.Fatal, 'fatal'],
  [DisplayStatusState.Emphasis, 'emphasis'],
]);
