import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  AnalysisInputOutputResponseValues,
  ESPPressureProfile,
  fetchESPAnalysisServiceAsync,
} from './ESPAnalysisService';

interface FetchAnalysisParameters {
  assetId: string;
  dateTime?: string;
}

const fetchESPAnalysisAsync = createAsyncThunk(
  'ESPAnalysis/fetchESPAnalysis',
  async (data: FetchAnalysisParameters) => {
    const response = await fetchESPAnalysisServiceAsync(data.assetId, data.dateTime);

    return response;
  },
);

interface ESPAnalysis {
  input: AnalysisInputOutputResponseValues[];
  output: AnalysisInputOutputResponseValues[];
  gasHandlingInputs: AnalysisInputOutputResponseValues[];
  gasHandlingOutputs: AnalysisInputOutputResponseValues[];
  isGasHandlingEnabled: boolean | undefined;
  xdiagAnalysis: AnalysisInputOutputResponseValues;
  pressureProfile: ESPPressureProfile | undefined;
}

interface ESPAnalysisResults {
  [date: string]: ESPAnalysis;
}

interface ESPAnalysisState {
  espAnalysisCards: ESPAnalysisResults;
  loading: boolean;
}

const initialState: ESPAnalysisState = {
  espAnalysisCards: {} as ESPAnalysisResults,
  loading: false,
};

const espAnalysisSlice = createSlice({
  name: 'ESPAnalysis/Analysis',
  initialState,
  reducers: {
    setInitialState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchESPAnalysisAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchESPAnalysisAsync.fulfilled, (state: any, action) => {
        const date = action.meta.arg.dateTime ?? '';
        const espAnalysis = {} as ESPAnalysis;

        if (action?.payload?.values?.input !== undefined) {
          espAnalysis.input = action.payload.values.input;
        }

        if (action?.payload?.values?.output !== undefined) {
          espAnalysis.output = action.payload.values.output.filter((x) => x.id.toLowerCase() !== 'xdiaganalysis');
        }

        if (action?.payload?.values?.gasHandlingInputs !== undefined) {
          espAnalysis.gasHandlingInputs = action.payload.values.gasHandlingInputs;
        }

        if (action?.payload?.values?.gasHandlingOutputs !== undefined) {
          espAnalysis.gasHandlingOutputs = action.payload.values.gasHandlingOutputs;
        }

        if (action?.payload?.values?.isGasHandlingEnabled !== undefined) {
          espAnalysis.isGasHandlingEnabled = action.payload.values.isGasHandlingEnabled;
        }

        if (action?.payload?.values?.output !== undefined) {
          const analysis = action.payload.values.output.find((x) => x.id.toLowerCase() === 'xdiaganalysis');

          if (analysis) {
            espAnalysis.xdiagAnalysis = analysis;
          }
        }

        espAnalysis.pressureProfile = action.payload?.pressureProfile;

        if (!state[date]) {
          state[date] = espAnalysis;
        }
        state.loading = false;
      })
      .addCase(fetchESPAnalysisAsync.rejected, (state) => {
        state.loading = false;
      });
  },
});

export { fetchESPAnalysisAsync };
export const { setInitialState } = espAnalysisSlice.actions;
export default espAnalysisSlice.reducer;
