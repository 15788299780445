import React from 'react';
import '../AssetStatus.scss';
import { PIDData } from '../../../models/PIDData';

interface PIDTableProps {
  pidData: PIDData;
}

const PIDTable = ({ pidData }: PIDTableProps) => {
  return (
    <div className='flex flex-col w-full h-full'>
      <div className='w-full facility-table-container'>
        <table className='w-full flex flex-column asset-status-table-border'>
          <thead className='w-full facility-table-header facility-table-bottom-border flex h-[44px] items-center'>
            <tr className='w-full flex'>
              <th className='asset-status-basis-thirds'>
                <span>Description</span>
              </th>
              <th className='asset-status-basis-thirds'>
                <span>Primary</span>
              </th>
              <th className='asset-status-basis-thirds'>
                <span>Override</span>
              </th>
            </tr>
          </thead>
          <tbody className='w-full flex flex-column'>
            <tr className='w-full flex h-[48px] items-center facility-table-bottom-border'>
              <td className='asset-status-basis-thirds'>Process</td>
              <td className='asset-status-basis-thirds'>{pidData?.primaryProcessVariable ?? 'Missing'}</td>
              <td className='asset-status-basis-thirds'>{pidData?.overrideProcessVariable ?? 'Missing'}</td>
            </tr>
            <tr className='w-full flex h-[48px] items-center facility-table-bottom-border'>
              <td className='asset-status-basis-thirds'>Set point</td>
              <td className='asset-status-basis-thirds'>{pidData?.primarySetpoint ?? 'Missing'}</td>
              <td className='asset-status-basis-thirds'>{pidData?.overrideSetpoint ?? 'Missing'}</td>
            </tr>
            <tr className='w-full flex h-[48px] items-center facility-table-bottom-border'>
              <td className='asset-status-basis-thirds'>Deadband</td>
              <td className='asset-status-basis-thirds'>{pidData?.primaryDeadband ?? 'Missing'}</td>
              <td className='asset-status-basis-thirds'>{pidData?.overrideDeadband ?? 'Missing'}</td>
            </tr>
            <tr className='w-full flex h-[48px] items-center facility-table-bottom-border'>
              <td className='asset-status-basis-thirds'>Proportional</td>
              <td className='asset-status-basis-thirds'>{pidData?.primaryProportionalGain ?? 'Missing'}</td>
              <td className='asset-status-basis-thirds'>{pidData?.overrideProportionalGain ?? 'Missing'}</td>
            </tr>
            <tr className='w-full flex h-[48px] items-center facility-table-bottom-border'>
              <td className='asset-status-basis-thirds'>Integral</td>
              <td className='asset-status-basis-thirds'>{pidData?.primaryIntegral ?? 'Missing'}</td>
              <td className='asset-status-basis-thirds'>{pidData?.overrideIntegral ?? 'Missing'}</td>
            </tr>
            <tr className='w-full flex h-[48px] items-center facility-table-bottom-border'>
              <td className='asset-status-basis-thirds'>Derivative</td>
              <td className='asset-status-basis-thirds'>{pidData?.primaryDerivative ?? 'Missing'}</td>
              <td className='asset-status-basis-thirds'>{pidData?.overrideDerivative ?? 'Missing'}</td>
            </tr>
            <tr className='w-full flex h-[48px] items-center facility-table-bottom-border'>
              <td className='asset-status-basis-thirds'>Scale Factor</td>
              <td className='asset-status-basis-thirds'>{pidData?.primaryScaleFactor ?? 'Missing'}</td>
              <td className='asset-status-basis-thirds'>{pidData?.overrideScaleFactor ?? 'Missing'}</td>
            </tr>
            <tr className='w-full flex h-[48px] items-center'>
              <td className='asset-status-basis-thirds'>Output</td>
              <td className='asset-status-basis-thirds'>{pidData?.primaryOutput ?? 'Missing'}</td>
              <td className='asset-status-basis-thirds'>{pidData?.overrideOutput ?? 'Missing'}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PIDTable;
