import React, { useEffect, useRef, useState, useMemo } from 'react';
import './TrendLibrary.scss';
import { Menu } from '@headlessui/react';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import {
  updateItemsDisplayState,
  fetchDataHistoryTrendLibraryAsync,
  updateSearchTrends,
  updateSelectedItem,
  updateCheckedItems,
  updateCheckBoxFlag,
  Trend,
} from './TrendLibrarySlice';
import { fetchTrendCoordinatesItemAsync, overlayCoordinates } from './TrendCoordinateSlice';
import { ReactComponent as SearchIcon } from '../../../images/search-md.svg';
import { ReactComponent as ChevronDownIcon } from '../../../images/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from '../../../images/chevron-up.svg';
import { useParams } from 'react-router-dom';
import { Checkbox } from '../../common/CustomCheckbox/CheckBox';
import { fetchInitialStateAsync } from '../../asset-analysis/TrendGroupLabel/TrendGroupLabelSlice';
import { updateOverlayCoordinates } from './TrendCoordinateSlice';
import { TrendLibraryProps, UrlParams, TrendItemType, ChartType } from './ChartTypes';
import { useClickOutside } from '../../common/Dropdown/DropdownClick';

const RESTRICTING_CHART_VALUE = 6;

const TrendLibrary: React.FC<TrendLibraryProps> = ({ buttonContent, startDate, endDate }) => {
  const dispatch = useAppDispatch();
  const { assetId } = useParams<keyof UrlParams>() as UrlParams;
  const trendLibrary = useAppSelector((state) => state.trendLibrary);
  const trendItems = useAppSelector((state) => state.trendLibrary?.searchTrends);
  const isFiltered = useAppSelector((state) => state.trendLibrary.isFiltered);
  const groupName = useAppSelector((state) => state.assetGroups?.selectedGroup?.assetGroupName);
  const selectedAssetId = useAppSelector((state) => state.assetGroups?.selectedAssetGuid);

  const checkedItems = useAppSelector((state) => state.trendLibrary.checkedItems);

  const overlayChartCoordinates = useAppSelector(overlayCoordinates);
  const customViewDataSlice = useAppSelector((state) => state.customViewDataSlice);

  const handleTrendItemClick = (id: number) => {
    dispatch(
      updateItemsDisplayState({
        trendId: id,
        itemId: undefined,
        searchString: undefined,
        itemIdGuid: undefined,
      }),
    );
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (trendItems && trendItems?.length > 0) {
        const itemId = trendItems[0].items[0].name;
        const itemIdGuid = trendItems[0].items[0].id;

        dispatch(
          updateSelectedItem({
            trendId: trendItems[0].trendId,
            itemId: itemId,
            searchString: undefined,
            itemIdGuid: itemIdGuid,
          }),
        );

        dispatch(fetchInitialStateAsync({ selectedAssetId, address: itemIdGuid }));
      }
    }
  };

  useEffect(() => {
    if (!trendItems || !trendItems.length) {
      dispatch(fetchDataHistoryTrendLibraryAsync({ assetId: assetId, groupName: groupName ?? '' }));
    }
  }, [assetId, groupName]);

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateSearchTrends({
        trendId: undefined,
        itemId: undefined,
        searchString: e.target.value,
        itemIdGuid: undefined,
      }),
    );
  };
  const checkedItemsNames = useMemo(() => {
    return checkedItems.map((item) => item.name);
  }, [checkedItems]);

  useEffect(() => {
    updateCheckBoxFlag(true);
    if (!startDate || !endDate) return;
    const formattedStartDate = new Date(startDate).toLocaleString('en');
    const formattedEndDate = new Date(endDate).toLocaleString('en');
    if (!customViewDataSlice.overlayLoading && !customViewDataSlice.groupLoading) {
      dispatch(
        fetchTrendCoordinatesItemAsync({
          assetId: assetId,
          chart1Type: checkedItems.map((item) => item?.name).join(','),
          chart1ItemId: checkedItems.map((item) => item?.typeId?.toString()).join(','),
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          overlay: 1,
        }),
      );
    }
  }, [startDate, endDate, assetId]);

  const handleCheckboxChange = (trendItem: TrendItemType) => {
    dispatch(updateCheckBoxFlag(true));
    if (checkedItems.findIndex((item) => item.name === trendItem.name) > -1) {
      dispatch(updateCheckedItems(checkedItems.filter((item) => item.name !== trendItem.name)));
      if (overlayChartCoordinates) {
        dispatch(
          updateOverlayCoordinates({
            value: {
              ...overlayChartCoordinates,
              chart1: overlayChartCoordinates.chart1.filter((item: any) => item.name !== trendItem.name),
            },
            type: ChartType.Overlay,
          }),
        );
      }
    } else {
      if (checkedItems && checkedItems.length < RESTRICTING_CHART_VALUE) {
        dispatch(updateCheckedItems([...checkedItems, trendItem]));
        if (!startDate || !endDate) return;
        const formattedStartDate = new Date(startDate).toLocaleString('en');
        const formattedEndDate = new Date(endDate).toLocaleString('en');
        dispatch(
          fetchTrendCoordinatesItemAsync({
            assetId: assetId,
            chart1ItemId: trendItem.typeId.toString(),
            chart1Type: trendItem.name,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            overlay: 1,
          }),
        );
      } else {
        alert('You can add only 6 trends as of now');
      }
    }
  };

  const handleItemIdClick = (
    itemName: string | undefined,
    trendId: number,
    itemId: string | undefined,
    isChecked: boolean,
  ) => {
    dispatch(updateSelectedItem({ trendId: trendId, itemId: itemName, searchString: undefined, itemIdGuid: itemId }));
    if (itemId != null && Number.isInteger(parseInt(itemId, 10)) && isChecked) {
      dispatch(fetchInitialStateAsync({ selectedAssetId, address: itemId }));
    }
  };

  const handleItemTrendNameClick = (trendId: number) => {
    dispatch(
      updateItemsDisplayState({
        trendId: trendId,
        itemId: undefined,
        searchString: undefined,
        itemIdGuid: undefined,
      }),
    );
  };

  const renderTrend = (trend: Trend, index: number, indentLevel = 0) => {
    return (
      <div key={trend.itemTrendName + index.toString()}>
        <div className={trend.class} onClick={() => handleTrendItemClick(trend.trendId)}>
          <div className='trend-library-frame-item-content'>
            <div
              data-testid={`trend-${trend.itemTrendName}`}
              className='trend-library-content-frame-item-text'
              onClick={() => handleItemTrendNameClick(trend.trendId)}
            >
              {trend.itemTrendName}
            </div>
          </div>
          <div className='trend-library-content-frame-item-carrot'>
            {trendLibrary?.trendHashMap[trend.trendId]?.showItems ? (
              <ChevronUpIcon width='20' height='20' stroke='#647980' />
            ) : (
              <ChevronDownIcon width='20' height='20' stroke='#647980' />
            )}
          </div>
        </div>

        {trendLibrary?.trendHashMap[trend.trendId]?.showItems && (
          <div>
            <div style={{ paddingLeft: `${(indentLevel + 1) * 20}px` }}>
              {!isFiltered
                ? trend.childTrends?.map((childTrend, childIndex) =>
                    renderTrend(childTrend, index + childIndex + 1, indentLevel + 1),
                  )
                : null}
              {trend.items?.map((item, itemIndex) => (
                <div
                  data-testid={`trend-${item.name}`}
                  key={item.name + itemIndex.toString()}
                  className='trend-library-content-frame-item'
                  onClick={() =>
                    handleItemIdClick(item.name, trend.trendId, item.id, !checkedItemsNames.includes(item.name))
                  }
                >
                  <Checkbox
                    isChecked={checkedItemsNames.includes(item.name)}
                    Label={item.name}
                    onItemSelect={() => handleCheckboxChange(item)}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const itemTrendDetails = trendItems?.map((value: Trend, index: number) => renderTrend(value, index));

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleButtonClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const dropdownRef = useRef<HTMLDivElement>(null);
  useClickOutside(dropdownRef, () => {
    setIsDropdownOpen(false);
  });

  return (
    <Menu as='div' className='toggle-dropdown-container' ref={dropdownRef}>
      {({ open }) => (
        <>
          <div>
            <Menu.Button onClick={handleButtonClick}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {buttonContent}
                <span className={open ? 'fas fa-caret-up' : 'fas fa-caret-down'} />
              </div>
            </Menu.Button>
          </div>
          {isDropdownOpen && (
            <div className='trend-dropdown-container'>
              <div className='trend-library-dropdown-label-container'>
                <div className='trend-library-option-label'>Trend library</div>
              </div>
              <div className='trend-library-dropdown-label-container'>
                <div className='trend-library-sub-header'>Selected trends</div>
              </div>
              {checkedItemsNames.length > 0 && (
                <div className='trend-library-selected-item-text'>
                  {checkedItems.map((item, index) => (
                    <div key={index} className='trend-library-selected-trend-item'>
                      <Checkbox
                        isChecked={(checkedItems[item.name as any] as any) || true}
                        Label={item.name}
                        onItemSelect={() => handleCheckboxChange(item)}
                      />
                    </div>
                  ))}
                </div>
              )}
              <div className='trend-library-content-search'>
                <SearchIcon className='trend-library-search-icon' width='20' height='20' stroke='#647980' />
                <input
                  className='input-with-icon'
                  placeholder='Search'
                  onChange={handleSearchInputChange}
                  onKeyDown={handleKeyPress}
                />
              </div>
              <div className='trend-library-item-details flex-grow flex-shrink'>{itemTrendDetails}</div>
            </div>
          )}
        </>
      )}
    </Menu>
  );
};

export default TrendLibrary;
