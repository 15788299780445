import { OverLayFields } from '../models/OverlayFields';
import { PositioningType } from '../models/PositioningType';

export const EFMPositioning: PositioningType[] = [
  {
    overlayField: OverLayFields.LastGoodScan,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.CurrentCommunicationStatus,
    position: { x: 143, y: 215 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ControllerType,
    position: { x: 143, y: 239 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.RTUAddress,
    position: { x: 143, y: 277 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.HostName,
    position: { x: 143, y: 315 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.DiffPress,
    position: { x: 577, y: 102 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.StaticPressure,
    position: { x: 577, y: 139.937 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.FlowingTemperature,
    position: { x: 577, y: 178 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.FlowRate,
    position: { x: 577, y: 216.127 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ProductionTodayVolume,
    position: { x: 577, y: 254 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ProductionYesterdayVolume,
    position: { x: 577, y: 292 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.BatteryVoltage,
    position: { x: 577, y: 331 },
    justification: 'left',
  },
];
