import React from 'react';
import './TrendGroup.scss';
import '../data-history/TrendLibrary/TrendLibrary.scss';
import { ReactComponent as TrendSettings } from '../../images/settings-04.svg';
import TrendLabelItem, { TrendLabelItemProps } from './TrendLabelItem/TrendLabelItem';
import TrendLibraryGrouped from '../data-history/TrendLibrary/TrendLibraryGrouped';
import { useAppSelector } from '../../hooks/storeHooks';
import { trendCoordinateSlice } from '../data-history/TrendLibrary/TrendCoordinateSlice';
import Loader from '../common/Loader/Loader';

export interface TrendGroupProps {
  headerText: string;
  values: TrendLabelItemProps[] | undefined | null;
  trendId: number;
  trendName: string;
  startDate: Date | null;
  endDate: Date | null;
  chartIndex: number;
  trendIndex?: number | null;
}

const TrendGroup = (props: TrendGroupProps) => {
  const { headerText, values, trendIndex } = props;
  const isLoadingGroupDefaultTrends = useAppSelector((state) => state.customViewDataSlice.groupLoading);
  const isLoadingOverlayDefaultTrends = useAppSelector((state) => state.customViewDataSlice.overlayLoading);
  const trendLoader = useAppSelector(trendCoordinateSlice).loading;

  return (
    <div className='flex-grow flex-shrink trend-group-container'>
      <div className='trend-group-header-container'>
        <label className='trend-group-header-text'>{headerText}</label>
        <TrendLibraryGrouped
          endDate={props.endDate}
          startDate={props.startDate}
          buttonContent={
            <div className='trend-group-settings'>
              <TrendSettings />
            </div>
          }
          trendId={props.trendId}
          chartIndex={props.chartIndex}
          trendIndex={trendIndex}
        />
      </div>
      <div className='trend-group-label-body-container'>
        <>
          {isLoadingGroupDefaultTrends || isLoadingOverlayDefaultTrends || trendLoader ? (
            <div className='trend-loader'>
              <Loader />
            </div>
          ) : (
            <>
              {values && values.length > 0 ? (
                values.map((x, index) => (
                  <TrendLabelItem key={index} id={x.id} title={x.title} value={x.value} color={x.color} />
                ))
              ) : (
                <p>No values to display</p>
              )}
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default TrendGroup;
