import { OverLayFields } from '../models/OverlayFields';
import { PositioningType } from '../models/PositioningType';

export const TundraSSIPositioning: PositioningType[] = [
  { overlayField: OverLayFields.LastGoodScan, position: { x: 0, y: 0 }, justification: 'left' },
  {
    overlayField: OverLayFields.ApiDesignation,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.RunStatus,
    position: { x: 39, y: 332 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ControllerType,
    position: { x: 39, y: 358 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.StrokeLength,
    position: { x: 39, y: 395.13 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.StrokesPerMinute,
    position: { x: 40, y: 432.27 },
    justification: 'left',
  },
  { overlayField: OverLayFields.TubingPressure, position: { x: 543, y: 413 }, justification: 'left' },
  { overlayField: OverLayFields.FlowlinePressure, position: { x: 543, y: 450 }, justification: 'left' },
  { overlayField: OverLayFields.CasingPressure, position: { x: 543, y: 487.27 }, justification: 'left' },
  { overlayField: OverLayFields.PumpDepth, position: { x: 543, y: 612 }, justification: 'left' },
  { overlayField: OverLayFields.PumpType, position: { x: 543, y: 649.13 }, justification: 'left' },
  { overlayField: OverLayFields.RodLoading, position: { x: 543, y: 686.27 }, justification: 'left' },
];
