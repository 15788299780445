import api from '../../services/api';

export interface FetchWellTestResponseValues {
  date: string;
  analysisTypeName: string;
}

export interface FetchWellTestResponse {
  values: FetchWellTestResponseValues[];
}

const fetchWellTestAsync = async (assetId: string, type: string) => {
  const params = {
    assetId,
    type,
  };
  try {
    return await api
      .get('WellTests', {
        params,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(function (response: any) {
        if (response) {
          return response.data as FetchWellTestResponse;
        } else {
          return null;
        }
      });
  } catch (error) {
    console.error('Error fetching esp analysis well test dates', error);
  }

  return null;
};

export interface AnalysisInputOutputResponseValues {
  id: string;
  name: string;
  displayName: string;
  value: string;
  displayValue: string;
  dataTypeId: number;
  measurementAbbreviation: string;
  sourceId: number;
}

export interface FetchAnalysisResponseValues {
  input: AnalysisInputOutputResponseValues[];
  output: AnalysisInputOutputResponseValues[];
  gasHandlingInputs: AnalysisInputOutputResponseValues[];
  gasHandlingOutputs: AnalysisInputOutputResponseValues[];
  isGasHandlingEnabled: boolean;
}

export interface ESPPressureProfile {
  isValid: boolean;
  errorMessage: string;
  casingPressure: ValueWithUnit | undefined;
  tubingPressure: ValueWithUnit | undefined;
  pumpIntakePressure: ValueWithUnit | undefined;
  usedDischargeGaugeInAnalysis: boolean;
  pumpDischargePressure: ValueWithUnit | undefined;
  pumpStaticPressure: ValueWithUnit | undefined;
  fluidLevel: ValueWithUnit | undefined;
  perforationDepth: ValueWithUnit | undefined;
  pumpFrictionDelta: ValueWithUnit | undefined;
  pumpPressureDelta: ValueWithUnit | undefined;
  flowingBHP: ValueWithUnit | undefined;
  flowingBHPGradient: string;
  staticGradient: string;
  pressureGradientUnits: string;
  dischargeGaugePressure: ValueWithUnit | undefined;
  dischargeGaugeDepth: ValueWithUnit | undefined;
  verticalPumpDepth: ValueWithUnit | undefined;
}

export interface ValueWithUnit {
  value: number;
  unit: string;
}

export interface FetchAnalysisResponse {
  values: FetchAnalysisResponseValues;
  pressureProfile: ESPPressureProfile;
}

const fetchESPAnalysisServiceAsync = async (assetId: string, dateTime?: string) => {
  const params = {
    assetId,
    testDate: dateTime,
  };
  try {
    return await api
      .get('Analytics/ESPAnalysis', {
        params,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(function (response: any) {
        if (response) {
          return response.data as FetchAnalysisResponse;
        } else {
          return null;
        }
      });
  } catch (error) {
    console.error('Error fetching esp analysis', error);
  }

  return null;
};

export interface FetchCardCoordinatesCoordinate {
  x: number;
  y: number;
}

export interface FetchCardCoordinatesObject {
  id: number;
  name: string;
  coordinates: FetchCardCoordinatesCoordinate[];
}
export interface FetchCardCoordinatesResponse {
  values: FetchCardCoordinatesObject[];
}

const fetchCardCoordinatesAsync = async (assetId: string, testDate?: string) => {
  const queryParams = {
    assetId,
    testDate,
  };
  return await api
    .get('Analytics/ESPAnalysis/ESPCoordinates', {
      params: queryParams,
      headers: {
        'content-type': 'application/json',
      },
    })
    .then(function (response: any) {
      if (response) {
        return response.data as FetchCardCoordinatesResponse;
      } else {
        return null;
      }
    });
};

export { fetchWellTestAsync, fetchESPAnalysisServiceAsync, fetchCardCoordinatesAsync };
