import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { fetchGLAnalysisWellTestInitialState } from './GLAnalysisService';
import { RGBColor } from '../../models/Card';
import { getCardColor } from '../../utilities/CardColorGenerator';

interface State {
  loading: boolean;
  glAnalysisWellTests: WellTestData[] | null;
  selectedCardDates: string[];
  selectedCardIds: number[];
  error: string | undefined;
}

export interface WellTestData {
  id: number;
  date: string;
  analysisTypeId: number;
  analysisResultId: number;
  analysisTypeName: string;
  color: RGBColor | null;
  isSelected: boolean;
  cardTypeName: string;
}

interface glAnalysisParameter {
  assetId: string;
  type: string;
}

// Async thunk to fetch the initial state data
export const fetchWellTestInitialStateAsync = createAsyncThunk(
  'glAnalysisWellTestSlice/fetchInitialState',
  async (param: glAnalysisParameter) => {
    const data = await fetchGLAnalysisWellTestInitialState(param.assetId, param.type);
    return data;
  },
);

const initialState: State = {
  loading: false,
  glAnalysisWellTests: null,
  selectedCardDates: [],
  selectedCardIds: [],
  error: undefined,
};

export const glAnalysisWellTestSlice = createSlice({
  name: 'glAnalysisWellTestData',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },
    toggleSelected(state, action: PayloadAction<number>) {
      const id = action.payload;
      state.selectedCardDates = [];

      if (!state.selectedCardIds.includes(id) && state.selectedCardIds.length >= 50) {
        alert('You can add only 50 cards to the chart.');
        return;
      }

      state.glAnalysisWellTests?.forEach((x, _, array) => {
        if (x.id == id) {
          x.isSelected = !x.isSelected;
        }
        if (x.isSelected) {
          state.selectedCardDates.push(x.date);
          x.color = getCardColor(array.findIndex((d) => d.id == x.id) + 1);
        }
      });
      if (state.selectedCardDates.length === 1) {
        state.glAnalysisWellTests?.forEach((x) => {
          x.color = null;
        });
      }

      state.glAnalysisWellTests?.forEach((x) => {
        if (x.id === id) {
          if (state.selectedCardIds.includes(id) && !x.isSelected) {
            state.selectedCardIds = state.selectedCardIds.filter((cardId) => cardId !== id);
          } else {
            if (!state.selectedCardIds.includes(id)) {
              state.selectedCardIds.push(id);
            }
          }
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWellTestInitialStateAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchWellTestInitialStateAsync.fulfilled, (state, action) => {
        state.loading = false;
        let id = 1;
        const glAnalysisWellTests: WellTestData[] = action.payload.values;
        if (glAnalysisWellTests.length > 0) {
          glAnalysisWellTests.forEach((x: WellTestData) => {
            x.isSelected = false;

            // assign an id to each card
            x.id = id++;
          });
        }

        state.glAnalysisWellTests = action.payload.values;
      })
      .addCase(fetchWellTestInitialStateAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { toggleSelected, setInitialState } = glAnalysisWellTestSlice.actions;
export default glAnalysisWellTestSlice.reducer;
