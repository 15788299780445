import React from 'react';

interface GLAnalysisResultHeaderProps {
  title: string;
  description: string;
  loading?: boolean;
}

const GLAnalysisResultHeader: React.FC<GLAnalysisResultHeaderProps> = (props: GLAnalysisResultHeaderProps) => {
  return (
    <div className={`analysis-result-header-container`}>
      <label className='gl-analysis-result-title-text'>{props.title}</label>
      {props.loading && (
        <>
          <label className='analysis-result-description'>{props.description}</label>
        </>
      )}
    </div>
  );
};

export default GLAnalysisResultHeader;
