import { OverLayFields } from '../models/OverlayFields';
import { PositioningType } from '../models/PositioningType';

export const ConventionalPositioning: PositioningType[] = [
  {
    overlayField: OverLayFields.LastGoodScan,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ApiDesignation,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.StructuralLoading,
    position: { x: 0, y: 209 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.CurrentCommunicationStatus,
    position: { x: 120, y: 243 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ControllerType,
    position: { x: 120, y: 290 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.RunStatus,
    position: { x: 120, y: 330 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.TimeInState,
    position: { x: 120, y: 370 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.MotorType,
    position: { x: 170, y: 475 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.MotorLoading,
    position: { x: 170, y: 513 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.StrokesPerMinute,
    position: { x: 0, y: 247 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.StrokeLength,
    position: { x: 0, y: 285 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.CyclesToday,
    position: { x: 0, y: 323 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.CyclesYesterday,
    position: { x: 0, y: 361 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.RodStrings,
    position: { x: 255, y: 575 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.TubingPressure,
    position: { x: 205, y: 475 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.CasingPressure,
    position: { x: 205, y: 513 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.RodLoading,
    position: { x: 0, y: 631 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.PumpFillage,
    position: { x: 0, y: 669 },
    justification: 'right',
  },
];
