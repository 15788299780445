import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import React, { useEffect, useState } from 'react';
import { fetchInitialStateAsync, setInitialState } from './AssetStatusSlice';
import { IRodLiftStatusWellModel } from '../../models/RodPumpWellStatus';
import { IDataValueModel, IOverlayDataValueModel } from '../../models/DataValueModel';
import { IImageOverlayModel } from '../../models/ImageOverlayModel';
import { useParams } from 'react-router-dom';
import { OverLayFields } from './models/OverlayFields';
import { PositioningType } from './models/PositioningType';
import { OverlayImagesDark, OverlayImagesLight } from './OverlayImages';
import { DiagramPositioning } from './DiagramPositionings';
import { DiagramTypes } from './models/DiagramTypes';
import ImageDiagram from './image-diagram/ImageDiagram';
import './AssetStatus.scss';
import TableDiagram from './table-diagram/TableDiagram';
import Loader from '../common/Loader/Loader';

const DiagramTypesForTable = [
  DiagramTypes.PID,
  DiagramTypes.Facility,
  DiagramTypes.ValveControl,
  DiagramTypes.RegisterView,
];

const AssetStatus = () => {
  const dispatch = useAppDispatch();
  const { assetId } = useParams<{ assetId: string }>();
  const isDarkMode = useAppSelector((state) => state.theme.mode) === 'dark';

  const [backgroundImage, setBackgroundImage] = useState<string>('');
  const [imageOverlays, setImageOverlays] = useState<IImageOverlayModel[]>([]);

  const getAssetStatusData = () => {
    if (assetId) {
      dispatch(fetchInitialStateAsync({ assetId: assetId }));
    }
  };

  useEffect(() => {
    getAssetStatusData();
  }, [assetId]);

  useEffect(() => {
    dispatch(setInitialState());
    setImageOverlays([]);
    setBackgroundImage('');
  }, [assetId]);

  const wellStatusData: IRodLiftStatusWellModel | undefined = useAppSelector((state) => state.rodliftwellstatus.data);
  const isLoading = useAppSelector((state) => state.rodliftwellstatus.loading);

  const exceptionProperties = toPropertyList(wellStatusData?.exceptions);
  const alarmProperties = toPropertyList(wellStatusData?.alarms);
  const wellTestProperties = toPropertyList(wellStatusData?.lastWellTest);
  const statusRegisterProperties = toPropertyList(wellStatusData?.statusRegisters);

  const usePercentsForWellStatusOverview = true;
  const wellStatusOverview = toPropertyList(wellStatusData?.wellStatusOverview, usePercentsForWellStatusOverview);

  useEffect(() => {
    if (!wellStatusData) {
      setBackgroundImage('');
      setImageOverlays([]);
      return;
    }

    if (DiagramTypesForTable.find((x) => x == wellStatusData.diagramType)) {
      setBackgroundImage('');
      setImageOverlays(getImageOverlays(wellStatusData));
      return;
    }

    const backgroundImage = isDarkMode
      ? OverlayImagesDark[wellStatusData.diagramType as keyof typeof OverlayImagesDark]
      : OverlayImagesLight[wellStatusData.diagramType as keyof typeof OverlayImagesLight] ?? '';

    if (!backgroundImage) {
      setBackgroundImage('');
      setImageOverlays([]);
      return;
    }

    setBackgroundImage(
      wellStatusData
        ? isDarkMode
          ? OverlayImagesDark[wellStatusData.diagramType as keyof typeof OverlayImagesDark]
          : OverlayImagesLight[wellStatusData.diagramType as keyof typeof OverlayImagesLight]
        : '',
    );
    setImageOverlays(getImageOverlays(wellStatusData));
  }, [wellStatusData, isDarkMode, assetId]);

  function toPropertyList(dataValues: IDataValueModel[] | null | undefined, usePercents = false) {
    if (!dataValues) return [];

    return dataValues
      .filter((dvm) => dvm.isVisible)
      .map((dvm, index) => {
        let newValue = dvm.value;

        if (usePercents && dvm.value) {
          newValue = `${newValue ?? ''}%`;
        }

        return {
          id: index,
          description: dvm.label,
          value: newValue,
          displayState: dvm.displayState,
        };
      });
  }

  function extractOverlayData(data: IRodLiftStatusWellModel, positioningData: PositioningType[]): IImageOverlayModel[] {
    const array = positioningData?.map((position) => {
      const justification =
        data.imageOverlayItems?.find((x) => x.overlayField == position.overlayField)?.justification ??
        position.justification;

      if (position.overlayField === OverLayFields.RodStrings && data.rodStrings) {
        return {
          overlayField: position.overlayField,
          position: position.position,
          dataValues: data.rodStrings,
          title: 'Rods',
          justification: justification,
          maxHeight: data.diagramType === DiagramTypes.JetPump ? '108px' : '225px',
        };
      }

      if (position.overlayField === OverLayFields.ChemicalInjectionInformation && data.chemicalInjectionInformation) {
        return {
          overlayField: position.overlayField,
          position: position.position,
          dataValues: data.chemicalInjectionInformation,
          title: '',
          justification: justification,
          maxHeight: '286px',
        };
      }

      if (position.overlayField === OverLayFields.ESPEquipment) {
        const orderedFields: number[] = [
          OverLayFields.Pump,
          OverLayFields.Seal,
          OverLayFields.Motor,
          OverLayFields.Cable,
        ];

        const filteredItems = data.imageOverlayItems
          ?.filter(
            (x) =>
              x.overlayField === OverLayFields.Pump ||
              x.overlayField === OverLayFields.Seal ||
              x.overlayField === OverLayFields.Motor ||
              x.overlayField === OverLayFields.Cable,
          )
          .sort(
            (a, b) => orderedFields.indexOf(a.overlayField as number) - orderedFields.indexOf(b.overlayField as number),
          )
          .reduce((acc: IOverlayDataValueModel[], item) => {
            const existingItem = acc.find((x) => x.overlayField === item.overlayField);

            if (existingItem) {
              acc.push({ ...item, label: '' });
            } else {
              acc.push({ ...item });
            }

            return acc;
          }, []);

        return {
          overlayField: position.overlayField,
          position: position.position,
          dataValues: filteredItems,
          title: 'Equipment',
          justification: justification,
          maxHeight: '286px',
          maxWidth: '300px',
        };
      }

      return {
        overlayField: position.overlayField,
        position: position.position,
        dataValues: data.imageOverlayItems?.filter((x) => x.overlayField == position.overlayField),
        justification: justification,
      };
    });

    return array;
  }

  function getImageOverlays(data: IRodLiftStatusWellModel | null | undefined): IImageOverlayModel[] {
    const model: IImageOverlayModel[] = [];

    if (!data) return model;

    const positioningData = DiagramPositioning[data.diagramType as keyof typeof DiagramPositioning];
    return extractOverlayData(data, positioningData);
  }

  return (
    <div className='flex flex-grow h-full flex-column asset-status-outer-container-padding'>
      {isLoading ? (
        <div className='flex flex-row h-full card asset-status-card'>
          <Loader />
        </div>
      ) : wellStatusData == null || wellStatusData.diagramType == null ? null : DiagramTypesForTable.find(
          (x) => x == wellStatusData.diagramType,
        ) ? (
        <TableDiagram
          imageOverlays={imageOverlays}
          facilityTagData={wellStatusData.facilityTagData}
          pidData={wellStatusData.pidData}
          onRefresh={getAssetStatusData}
          diagramType={wellStatusData.diagramType}
          valveControlData={wellStatusData.valveControlData}
        />
      ) : (
        <ImageDiagram
          backgroundImage={backgroundImage}
          imageOverlays={imageOverlays}
          exceptionProperties={exceptionProperties}
          alarmProperties={alarmProperties}
          wellTestProperties={wellTestProperties}
          statusRegisterProperties={statusRegisterProperties}
          wellStatusOverview={wellStatusOverview}
          diagramType={wellStatusData.diagramType}
          onRefresh={getAssetStatusData}
        />
      )}
    </div>
  );
};

export default AssetStatus;
