import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchInitialState } from './GroupStatusService';

interface State {
  groupStatusLoading: boolean;
  groupStatus: GroupStatusList | null | undefined;
  error: string | undefined;
  selectedView: any;
}

interface GroupStatusList {
  Id: string;
  DateCreated: string;
  values: GroupStatus;
}

interface GroupStatus {
  columns: Array<GroupStatusColumns>;
  rows: GroupStatusRow[];
  rowValues: any;
}

interface GroupStatusColumns {
  id: string;
  header: string;
  accessor: string;
}

// Define the data row interface
interface GroupStatusRow {
  [key: string]: string;
}

interface FetchInitialGroupStatusStateParameters {
  viewId: any;
  group: any;
}

// Async thunk to fetch the initial state data
export const fetchInitialGroupStatusStateAsync = createAsyncThunk(
  'groupStatusSlice/fetchInitialState',
  async (groupStatusData: FetchInitialGroupStatusStateParameters) => {
    const data = await fetchInitialState(groupStatusData);
    return data;
  },
);

const initialState: State = {
  groupStatusLoading: false,
  groupStatus: null,
  error: undefined,
  selectedView: {},
};

export const GroupStatus = createSlice({
  name: 'groupStatus',
  initialState,
  reducers: {
    setSelectedView: (state, action) => {
      state.selectedView = action.payload.selectedView;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInitialGroupStatusStateAsync.pending, (state) => {
        state.groupStatusLoading = true;
      })
      .addCase(fetchInitialGroupStatusStateAsync.fulfilled, (state, action) => {
        state.groupStatusLoading = false;
        state.groupStatus = action.payload;
      })
      .addCase(fetchInitialGroupStatusStateAsync.rejected, (state, action) => {
        state.groupStatusLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { setSelectedView } = GroupStatus.actions;

export default GroupStatus.reducer;
