import React, { useState } from 'react';
import { Menu, Transition, Switch } from '@headlessui/react';
import '../../common/ViewOptions/ViewOptions.css';
import '../../../styles/SwitchOptions.scss';

interface CardTypeFilterProps {
  label: string;
  options: { id: number; label: string; isForm?: boolean }[];
  buttonContent: React.ReactNode;
  onOptionChange: (id: number, isSelected: boolean, label: string) => void; // Callback function
}

const CardTypeFilter: React.FC<CardTypeFilterProps> = ({ label, options, buttonContent, onOptionChange }) => {
  const [enabledStates, setEnabledStates] = useState<boolean[]>(options.map(() => false));

  return (
    <Menu as='div' className='toggle-dropdown-container' data-testid='card-type-filter-component'>
      {({ open }) => (
        <>
          <div>
            <Menu.Button>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {buttonContent}
                <span className={open ? 'fas fa-caret-up' : 'fas fa-caret-down'} />
              </div>
            </Menu.Button>
          </div>

          <Transition
            as={React.Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items className='absolute right-0 z-10 mt-2 whitespace-nowrap origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus-outline-none'>
              <div className='dropdown-label-container'>
                <p className='option-label'>{label}</p>
              </div>
              <div className='dropdown-switches'>
                {options.map((option, index) => (
                  <div key={index} className='flex items-center'>
                    <Switch.Group as='div' className='flex items-center'>
                      <Switch
                        checked={enabledStates[index] || false}
                        onChange={(value) => {
                          const newStates = [...enabledStates];
                          newStates[index] = value;
                          setEnabledStates(newStates);
                          onOptionChange(option.id, value, option.label);
                        }}
                        className={`custom-switch ${enabledStates[index] ? 'checked' : 'unchecked'}`}
                      >
                        <span
                          aria-hidden='true'
                          className={`custom-switch-handle ${enabledStates[index] ? 'checked' : ''}`}
                        />
                      </Switch>
                    </Switch.Group>
                    <div className='dropdown-list'>
                      <span data-testid='view-option-label' className='font-medium text-gray-900'>
                        {option.label}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default CardTypeFilter;
