import React from 'react';
import './ServerError.scss';
import PrimaryX from '../../images/CX-Logo.svg';
import ServerErrorImg from '../../images/servererror.svg';

const ServerError = () => {
  return (
    <div className='servererror-background'>
      <div className='servererror-content'>
        <div className='servererror-message'>
          <div className='branding'>
            <img alt='PrimaryX' src={PrimaryX} className='servererror-inlineblock mr-5' />
          </div>
          <div className='text-lg'>Our server is feeling a little down</div>
          <p>Please try again in a few moments. We'll be back up in no time.</p>
          <span className='text-xs copy'>© ChampionX 2024</span>
        </div>
        <div className='servererror-logo'>
          <img alt='ServerErrorImg' src={ServerErrorImg} className='w-100' />
        </div>
      </div>
    </div>
  );
};

export default ServerError;
