export const OverLayFields = {
  Unknown: 0,
  LastGoodScan: 1,
  ApiDesignation: 2,
  CurrentCommunicationStatus: 3,
  ControllerInformation: 4,
  StructuralLoading: 5,
  RunStatus: 6,
  TimeInState: 7,
  MotorType: 8,
  MotorLoading: 9,
  StrokesPerMinute: 10,
  StrokeLength: 11,
  CyclesToday: 12,
  CyclesYesterday: 13,
  TubingPressure: 14,
  RodLoading: 15,
  PumpFillage: 16,
  CasingPressure: 17,
  GearboxLoading: 18,
  PumpDepth: 19,
  PumpType: 20,
  FlowingTemperature: 21,
  Protocol: 22,
  HostName: 23,
  OPCType: 24,
  Port: 25,
  RTUAddress: 26,
  Offset: 27,
  PumpingUnit: 28,
  FirmwareVersion: 29,
  FlowRate: 31,
  AccumulatedVolume: 34,
  DiffPress: 36,
  StaticPressure: 37,
  PulseCount: 41,
  BatteryVoltage: 42,
  EnergyRate: 44,
  InjectionPressure: 48,
  ControlValue: 51,
  FlowlinePressure: 52,
  ProductionFlowRate: 53,
  InjectionPress: 55,
  ProductionTodayVolume: 56,
  ProductionYesterdayVolume: 57,
  GasInjectionRate: 58,
  FacilityComms: 60,
  FacilityEnabled: 61,
  FacilityTagCount: 62,
  FacilityAlarmCount: 63,
  FacilityHostAlarm: 64,
  FacilityComment: 65,
  Seal: 66,
  Cable: 69,
  Motor: 75,
  Pump: 80,
  FluidLevel: 82,
  PumpEfficiency: 83,
  CurrentLoadFactor: 84,
  IntermittenRealTime: 85,
  FirstHead: 86,
  SecondHead: 87,
  TubeID: 88,
  TubeDescription: 89,
  LastSample: 90,
  Temperature: 91,
  Volume: 92,
  TankLevel: 93,
  InterfaceLevel: 94,
  StationID: 95,
  Location: 96,
  DeviceID: 97,
  Meter: 98,
  PIDType: 99,
  PIDMode: 100,
  ControllerType: 101,
  ControllerAndNode: 102,
  RodStrings: 10000,
  ChemicalInjectionInformation: 10001,
  ESPEquipment: 10002,
} as const;
