import api from '../../services/api';

export const fetchAssetGroupsInitialState = async () => {
  try {
    return await api
      .get('Assets?groupBy=AssetGroupName', {
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(function (response: any) {
        if (response) {
          return response.data;
        }
      });
  } catch (error) {
    console.error('Error fetching initial state:', error);
    return [];
  }
};
