import React from 'react';
import './NodalAnalysisLegend.scss';

interface LegendItem {
  id: number;
  name: string;
  color: string | undefined;
}

interface NodalAnalysisLegendProps {
  legendItems: LegendItem[];
}

const NodalAnalysisLegend: React.FC<NodalAnalysisLegendProps> = ({ legendItems }) => {
  return (
    <div className='nodal-analysis-legend-container'>
      <div className='flex flex-col items-center justify-center w-full h-full mb-10'>
        <div className='nodal-analysis-legend-items'>
          {legendItems?.map((label) => (
            <div key={label.id} className='flex flex-row items-center justify-center'>
              <div
                className='pressure-profile-legend-dash'
                style={{ backgroundColor: label.color ? label.color : '#006DFA' }}
              />
              <div className='pressure-profile-legend-label'>{label.name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NodalAnalysisLegend;
