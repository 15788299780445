export enum ChartIndex {
  FirstChart = 0,
  SecondChart = 1,
  ThirdChart = 2,
  FourthChart = 3,
}

export enum ChartType {
  Overlay = 'overlay',
  Group = 'group',
}

export interface TrendLibraryProps {
  buttonContent?: React.ReactNode;
  endDate?: Date | null;
  startDate?: Date | null;
}

export interface TrendLibraryGroupedProps {
  buttonContent?: React.ReactNode;
  endDate?: Date | null;
  startDate?: Date | null;
  chartIndex: number;
  trendId: any;
  trendIndex: any;
}

export interface UrlParams {
  assetId: string;
}

export interface TrendItemType {
  id: string;
  name: string;
  typeId: number;
}

interface GroupChartType {
  groupName: string;
  selectedTrendsArray: [];
}

export const groupedChartData: GroupChartType[] = [
  { groupName: 'Trend group 1', selectedTrendsArray: [] },
  { groupName: 'Trend group 2', selectedTrendsArray: [] },
  { groupName: 'Trend group 3', selectedTrendsArray: [] },
  { groupName: 'Trend group 4', selectedTrendsArray: [] },
];
