import React, { useEffect } from 'react';
import * as am5core from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { Card, CardCoordinate, RGBColor, SelectedCardTrend } from '../../models/Card';
import './ESPChart.scss';
import {
  AxisDefault,
  setGridDefault,
  createYAxisLabelDefault,
  createChartDefault,
  createTooltipDefault,
  setTooltipDefault,
} from '../../utilities/ChartDefaults';
import { CurveType } from '../gl-analysis/enums/CurveType';
import { useAppSelector } from '../../hooks/storeHooks';

interface ESPChartChartProps {
  selectedCardTrends?: SelectedCardTrend[];
  cards?: Card[];
}

export const defaultCurveColors = [
  {
    id: 12,
    name: 'Efficieny Curve',
    color: '#32d583',
  },
  {
    id: 11,
    name: 'Power Curve',
    color: '#f97066',
  },
  {
    id: 10,
    name: 'Well Performance Curve',
    color: '#800080',
  },
  {
    id: 9,
    name: 'Well Pump Curve',
    color: '#4294ff',
  },
  {
    id: -6,
    name: 'Operating Point',
    color: '#60bfda',
  },
  {
    id: 13,
    name: 'Recommended Range Curve - Left',
    color: '#fdb022',
  },
  {
    id: 14,
    name: 'Recommended Range Curve - Top',
    color: '#fdb022',
  },
  {
    id: 15,
    name: 'Recommended Range Curve - Right',
    color: '#fdb022',
  },
  {
    id: 16,
    name: 'Recommended Range Curve - Bottom',
    color: '#fdb022',
  },
  {
    id: 0,
    name: 'Tornado Curves',
    color: '#60bfda',
  },
];

export const ESPChart: React.FC<ESPChartChartProps> = (props: ESPChartChartProps) => {
  const themeMode = useAppSelector((state) => state.theme.mode);
  const isDarkMode = themeMode === 'dark';
  useEffect(() => {
    const root = am5core.Root.new('esp-chart');

    // create chart.
    const chart = root.container.children.push(createChartDefault(root));

    // create x axis.
    const xAxis = createXAxis();

    // create y axis.
    const yAxis = createYAxis();
    const yAxisEfficiency = createEfficiencyYAxis();
    const yAxisPower = createPowerYAxis();

    calculateMinMaxForAxes();

    // plot all series.
    props.selectedCardTrends?.forEach((selectedCardTrend) => {
      props.cards?.forEach((card) => {
        card.cardTrends?.forEach((cardTrend) => {
          if (
            selectedCardTrend.id === 13 &&
            (cardTrend.curveTypeId === 13 ||
              cardTrend.curveTypeId === 14 ||
              cardTrend.curveTypeId === 15 ||
              cardTrend.curveTypeId === 16)
          ) {
            createSeries(cardTrend.curveTypeId, cardTrend.name, cardTrend.coordinates, card.color ?? null);
          }

          if (cardTrend.curveTypeId !== selectedCardTrend.id) {
            return;
          }

          if (cardTrend.curveTypeId === CurveType.ESPOperatingPoint) {
            createBulletSeries(cardTrend.curveTypeId, cardTrend.name, cardTrend.coordinates, card.color ?? null);
          } else {
            createSeries(cardTrend.curveTypeId, cardTrend.name, cardTrend.coordinates, card.color ?? null);
          }
        });
      });
    });

    root.setThemes([am5themes_Animated.new(root)]);

    // create cursor.
    const customCursor = am5xy.XYCursor.new(root, {});
    chart.set('cursor', customCursor);

    return () => {
      root.dispose();
    };

    function createYAxis() {
      const rendererAxisY = am5xy.AxisRendererY.new(root, { minGridDistance: 37 });
      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          extraTooltipPrecision: 1,
          renderer: rendererAxisY,
          min: AxisDefault.Min,
          max: AxisDefault.Max,
          strictMinMax: true,
        }),
      );
      rendererAxisY.labels.template.set('fill', isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'));

      const label = createYAxisLabelDefault(root, 'Head (ft)');

      label.setAll({
        marginLeft: -30,
        fill: isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'),
      });

      yAxis.children.unshift(label);

      setGridDefault(yAxis);

      return yAxis;
    }

    function createPowerYAxis() {
      const axisRendererY = am5xy.AxisRendererY.new(root, { minGridDistance: 37, opposite: true });

      const yAxis1 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          extraTooltipPrecision: 1,
          renderer: axisRendererY,
          min: AxisDefault.Min,
          max: AxisDefault.Max,
          strictMinMax: true,
        }),
      );
      axisRendererY.labels.template.set('fill', isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'));

      const label = createYAxisLabelDefault(root, 'Power (HP)');

      label.setAll({
        paddingTop: 0,
        fill: isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'),
      });

      yAxis1.children.push(label);

      const axisRenderer = yAxis1.get('renderer');
      axisRenderer.grid.template.setAll({
        visible: false,
      });

      return yAxis1;
    }

    function createXAxis() {
      const xAxisRendererX = am5xy.AxisRendererX.new(root, { minGridDistance: 50 });
      const xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: xAxisRendererX,
          min: AxisDefault.Min,
          max: AxisDefault.Max,
          strictMinMax: true,
        }),
      );
      xAxisRendererX.labels.template.set('fill', isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'));

      xAxis.children.push(
        am5core.Label.new(root, {
          text: 'Flow Rate (bpd)',
          x: am5core.p50,
          centerX: am5core.p50,
          marginTop: -5,
          marginBottom: 5,
          fontSize: 12,
          fontWeight: '400',
          fill: isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'),
        }),
      );

      const axisRenderer = setGridDefault(xAxis);
      axisRenderer.grid.template.setAll({
        strokeDasharray: [2.5],
        strokeOpacity: 1,
        strokeWidth: 1,
        stroke: am5core.color('#D0D8DD'),
      });

      return xAxis;
    }

    function createEfficiencyYAxis() {
      const axisRendererY = am5xy.AxisRendererY.new(root, { opposite: true });
      const efficiencyYAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: axisRendererY,
          min: AxisDefault.Min,
          max: 100,
          strictMinMax: true,
        }),
      );
      axisRendererY.labels.template.set('fill', isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'));

      const label = createYAxisLabelDefault(root, 'Efficiency (%)');

      label.setAll({
        paddingTop: -5,
        fill: isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'),
      });

      efficiencyYAxis.children.push(label);

      const axisRenderer = efficiencyYAxis.get('renderer');

      axisRenderer.grid.template.setAll({
        visible: false,
      });

      return efficiencyYAxis;
    }

    function calculateMinMaxForAxes() {
      const allYValues: number[] = [];
      const allXValues: number[] = [];
      const allYValuesPower: number[] = [];

      props.selectedCardTrends?.forEach((selectedCardTrend) => {
        props.cards?.forEach((card) => {
          if (selectedCardTrend !== selectedCardTrend) {
            return;
          }

          card.cardTrends?.forEach((cardTrend) => {
            if (cardTrend.coordinates.length == 0) {
              return;
            }

            // accumulate all X values for X axis + accumulate all Y value for Y axis
            if (cardTrend.name?.toLowerCase() === 'pump curve' || cardTrend.name?.toLowerCase() === 'tornado curves') {
              allXValues.push(...cardTrend.coordinates.map((coord) => coord.x));
              allYValues.push(...cardTrend.coordinates.map((coord) => coord.y));
            }

            // accumulate all Y values for power axis
            if (cardTrend.name?.toLowerCase() === 'power curve') {
              allYValuesPower.push(...cardTrend.coordinates.map((coord) => coord.y));
            }
          });
        });
      });

      // update axis bounds
      xAxis.set('max', allXValues.length > 0 ? Math.max(...allXValues) : AxisDefault.Max);
      yAxis.set('min', allYValues.length > 0 ? Math.min(...allYValues) : AxisDefault.Min);
      yAxis.set('max', allYValues.length > 0 ? Math.max(...allYValues) : AxisDefault.Max);
      yAxisPower.set(
        'min',
        (allYValuesPower.length > 0 && Math.min(...allYValuesPower) >= 0) || allYValuesPower.length === 0
          ? AxisDefault.Min
          : Math.min(...allYValuesPower),
      );
      yAxisPower.set('max', allYValuesPower.length > 0 ? Math.max(...allYValuesPower) * 2 : AxisDefault.Max);
    }

    function createSeries(
      curveTypeId: number,
      seriesName: string,
      coordinates: CardCoordinate[],
      color: RGBColor | null,
    ) {
      const tooltip = createTooltipDefault(root);

      let seriesYAxis = yAxis;
      const curveColor = '#000000';
      const dotted = curveTypeId >= 10 && curveTypeId <= 16;

      if (seriesName?.toLowerCase() === 'power curve') {
        seriesYAxis = yAxisPower;
      } else if (seriesName?.toLowerCase() === 'efficiency curve') {
        seriesYAxis = yAxisEfficiency;
      }

      if (seriesName.toLowerCase().includes('tornado')) {
        color = { r: 96, g: 191, b: 218 };
      } else if (seriesName.toLowerCase().includes('recommended range')) {
        color = { r: 253, g: 176, b: 34 };
      }

      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: seriesName,
          xAxis: xAxis,
          yAxis: seriesYAxis,
          valueYField: 'y',
          valueXField: 'x',
          tooltip: tooltip,
          connect: false,
          stroke: color
            ? am5core.color(`rgb(${color.r}, ${color.g}, ${color.b})`)
            : am5core.color(defaultCurveColors.find((x) => x.id == curveTypeId)?.color || curveColor),
        }),
      );

      series.strokes.template.setAll({
        strokeWidth: curveTypeId === 0 ? 2 : 4,
      });

      if (dotted) {
        series.strokes.template.set('strokeDasharray', [8, 2]);
      }

      const seriesColor: am5core.Color = series.get('stroke') as am5core.Color;

      setTooltipDefault(tooltip, seriesColor);

      series.data.setAll(coordinates);
    }

    function createBulletSeries(
      curveTypeId: number,
      seriesName: string,
      coordinates: CardCoordinate[],
      color: RGBColor | null,
    ) {
      const tooltip = createTooltipDefault(root);

      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: seriesName,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'y',
          valueXField: 'x',
          tooltip: tooltip,
          connect: false,
          stroke: color
            ? am5core.color(`rgb(${color.r}, ${color.g}, ${color.b})`)
            : am5core.color(defaultCurveColors.find((color) => color.id === curveTypeId)?.color || '#000000'),
        }),
      );
      series.bullets.push(function (root) {
        return am5core.Bullet.new(root, {
          sprite: am5core.Rectangle.new(root, {
            width: 7,
            height: 7,
            fill: seriesColor,
            centerX: am5core.p50,
            centerY: am5core.p50,
          }),
        });
      });

      const seriesColor: am5core.Color = series.get('stroke') as am5core.Color;

      setTooltipDefault(tooltip, seriesColor);

      series.data.setAll(coordinates);
    }
  }, [props.cards?.length, props.selectedCardTrends?.length, isDarkMode]);

  return <div id='esp-chart' className='esp-chart'></div>;
};
