import api from '../../services/api';

function removeFullStop(str: string): string {
  // Check if the string ends with a full stop
  if (str.endsWith('.')) {
    // Remove the last character (full stop)
    return str.slice(0, -1);
  }
  // If the string doesn't end with a full stop, return the original string
  return str;
}
export const fetchInitialState = async (groupStatusData: any) => {
  try {
    return await api
      .get(`GroupStatus?viewId=${groupStatusData?.viewId}&groupName=${groupStatusData?.group}`, {
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(function (response: any) {
        if (response) {
          const columns = response.data.values.columns.map((column: any) => {
            const columnName = removeFullStop(column.name);
            const columnWidth = columnName.length * 10 < 180 ? 180 : columnName.length * 10;
            const columnDefinition = {
              Header: columnName,
              accessor: columnName,
              Id: column.Id,
              width: columnWidth,
            } as any;

            if (columnDefinition.Header.toLowerCase() === 'well') {
              columnDefinition.sticky = 'left';
              columnDefinition.width = 250;
            } else if (columnDefinition.Header.toLowerCase() === 'last good scan') {
              columnDefinition.width = 220;
            }
            return columnDefinition;
          });

          const unclipped_columns = ['Assetguid', 'Well'];
          // Extract the row values from the data
          const rowValues = response.data.values.rows.map((row: any) =>
            row.reduce((acc: any, cell: any) => {
              const columnName = response.data.values.columns.find((column: any) => column.id === cell.columnId)?.name;
              const sanitizedColumnName = removeFullStop(columnName);
              let clippedValue = cell.value;
              const columnLength = columns.find((column: any) => column.accessor == sanitizedColumnName).width;

              const maxLength = columnLength / 10;
              if (cell?.value?.length > maxLength && !unclipped_columns.includes(sanitizedColumnName)) {
                clippedValue = (cell.value.slice(0, maxLength) as string) + '...';
              }
              acc[sanitizedColumnName] = clippedValue;
              return acc;
            }, {}),
          );

          const rawRowValues = response.data.values.rows.map((row: any) =>
            row.reduce((acc: any, cell: any) => {
              const columnName = response.data.values.columns.find((column: any) => column.id === cell.columnId)?.name;
              const sanitizedColumnName = removeFullStop(columnName);
              const columnLength = columns.find((column: any) => column.accessor == sanitizedColumnName).width;
              const maxLength = columnLength / 10;

              let showToolTip = false;

              if (cell?.value?.length > maxLength && !unclipped_columns.includes(sanitizedColumnName)) {
                showToolTip = true;
              }
              acc[sanitizedColumnName] = {
                foreColor: cell.foreColor,
                backColor: cell.backColor,
                value: cell.value,
                showToolTip: showToolTip,
              };
              return acc;
            }, {}),
          );
          response.data.values.columns = columns;
          response.data.values.rowValues = rawRowValues;
          response.data.values.rows = rowValues;
          return response.data;
        }
      });
  } catch (error) {
    console.error('Error fetching initial state:', error);
    return [];
  }
};

export const fetchTableViewInitialState = async () => {
  try {
    return await api
      .get('Lookup/GroupStatusViews', {
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(function (response: any) {
        if (response) {
          return response.data;
        }
      });
  } catch (error) {
    console.error('Error fetching initial state:', error);
    return [];
  }
};

export const fetchGroupAlarmsWidgetDataInitialState = async (groupName: string) => {
  try {
    const response = await api.get('GroupStatus/GetAlarmsWidgetDataAsync?groupName=' + groupName, {
      headers: {
        'content-type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching initial state:', error);
    return [];
  }
};

export const fetchGroupClassificationWidgetDataInitialState = async (groupName: string) => {
  try {
    const response = await api.get('GroupStatus/GetGroupClassificationWidgetData?groupName=' + groupName, {
      headers: {
        'content-type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching initial state:', error);
    return [];
  }
};

export const fetchGroupDowntimeByWellsInitialState = async (groupName: string) => {
  try {
    const response = await api.get('GroupStatus/DowntimeByWells?groupName=' + groupName, {
      headers: {
        'content-type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching initial state:', error);
    return [];
  }
};

export const fetchGroupDowntimeByRunStatusInitialState = async (groupName: string) => {
  try {
    const response = await api.get('GroupStatus/DowntimeByRunStatus?groupName=' + groupName, {
      headers: {
        'content-type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching initial state:', error);
    return [];
  }
};
