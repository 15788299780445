import { PositioningType } from '../models/PositioningType';
import { OverLayFields } from '../models/OverlayFields';

export const AirBalancedPositioning: PositioningType[] = [
  {
    overlayField: OverLayFields.LastGoodScan,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ApiDesignation,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.CurrentCommunicationStatus,
    position: { x: 119, y: 232 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ControllerType,
    position: { x: 119, y: 270 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.RunStatus,
    position: { x: 119, y: 308 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.TimeInState,
    position: { x: 119, y: 346 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.StructuralLoading,
    position: { x: 0, y: 202 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.StrokesPerMinute,
    position: { x: 0, y: 241 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.StrokeLength,
    position: { x: 0, y: 279 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.CyclesToday,
    position: { x: 0, y: 317 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.CyclesYesterday,
    position: { x: 0, y: 355 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.MotorType,
    position: { x: 170, y: 467 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.MotorLoading,
    position: { x: 170, y: 505 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.TubingPressure,
    position: { x: 210, y: 470 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.CasingPressure,
    position: { x: 210, y: 508 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.RodStrings,
    position: { x: 439, y: 569 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.RodLoading,
    position: { x: 0, y: 625 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.PumpFillage,
    position: { x: 0, y: 663 },
    justification: 'right',
  },
];
