import './GLValveAnalysis.scss';
import React from 'react';
import { useAppSelector } from '../../../hooks/storeHooks';

interface GLValveAnalysisProps {
  cardId: string;
}

const GLValveAnalysis = ({ cardId }: GLValveAnalysisProps) => {
  const glValveAnalysisData = useAppSelector((state) => state.glAnalysisData.glAnalysis);
  return (
    <div className='analysis-result-item-container xd-analysis-container  flex-grow flex-shrink'>
      <div className='analysis-result-item-header-container'>
        <label className='analysis-result-item-header-text'>Gas Lift Valve Status</label>
      </div>
      <div className='xd-analysis-text-container valve-table-top-align gl-valve-analysis-valve-analysis-table-overflow'>
        <table className='gl-valve-analysis-valve-analysis-table'>
          <thead>
            <tr className='gl-valve-analysis-table-header-cell'>
              <th className='gl-valve-analysis-table-header-item'>Valve</th>
              <th className='gl-valve-analysis-table-header-item'>Description</th>
              <th className='gl-valve-analysis-table-header-item'>Depth</th>
              <th className='gl-valve-analysis-table-header-item'>Opening Pressure</th>
              <th className='gl-valve-analysis-table-header-item'>Closing Pressure</th>
              <th className='gl-valve-analysis-table-header-item'>Injection Pressure</th>
              <th className='gl-valve-analysis-table-header-item'>Critical Flowrate</th>
              <th className='gl-valve-analysis-table-header-item'>Operating Status</th>
            </tr>
          </thead>
          <tbody>
            {glValveAnalysisData[cardId]?.valves?.map(function (item) {
              return (
                <tr className='gl-valve-analysis-table-row-name'>
                  <td>{item.position}</td>
                  <td>{item.description}</td>
                  <td>{item.depth}</td>
                  <td>{item.openingPressureAtDepth}</td>
                  <td>{item.closingPressureAtDepth}</td>
                  <td>{item.injectionPressureAtDepth}</td>
                  <td>{item.tubingCriticalVelocityAtDepth}</td>
                  <td>{item.status}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GLValveAnalysis;
