import { OverLayFields } from '../models/OverlayFields';
import { PositioningType } from '../models/PositioningType';

export const PCPPositioning: PositioningType[] = [
  {
    overlayField: OverLayFields.LastGoodScan,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.CurrentCommunicationStatus,
    position: { x: 119, y: 203 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ControllerType,
    position: { x: 119, y: 248 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.TimeInState,
    position: { x: 119, y: 286 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.RodStrings,
    position: { x: 489, y: 558 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.MotorType,
    position: { x: 575, y: 144 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.TubingPressure,
    position: { x: 575, y: 338 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.CasingPressure,
    position: { x: 575, y: 375.13 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.PumpDepth,
    position: { x: 573, y: 479 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.PumpEfficiency,
    position: { x: 573, y: 516.13 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.PumpType,
    position: { x: 573, y: 553.27 },
    justification: 'left',
  },
];
