import React from 'react';
import './GroupStatusAlarms.scss';
import CardHeader from '../../common/CardHeader/CardHeader';
import FeaturedIcon from '../../common/No/FeaturedIcon';
import Loader from '../../common/Loader/Loader';

const handleWidgetClick = () => {
  alert('Header clicked!');
};

const Alarms = ({ loading, failedToLoad, value }: { loading: any; failedToLoad: any; value: any }) => {
  return (
    <div className='group-status-widget-card'>
      <CardHeader value='Alarms' icon='verticalDots' onClick={handleWidgetClick} />
      <div style={{ padding: '0px 13px' }}>
        <div className='card-header-divider' />
      </div>
      {loading ? (
        <Loader />
      ) : value ? (
        <div className='group-status-alarms-card-body'>
          <div className='group-status-alarms-percentage'>{value}</div>
          <p className='group-status-alarms-details'># of assets</p>
        </div>
      ) : (
        <div className='group-widgets-no-data'>
          <FeaturedIcon />
          {failedToLoad ? (
            <>
              <p className='group-widgets-no-data-text'>Failed to Load</p>
              <p className='group-widget-failed-to-load-text'>
                Some data requirements failed to load. Please refresh your browser and try again. If the problem
                persists, please contact the support team.
              </p>
            </>
          ) : (
            <p className='group-widgets-no-data-text'>No Data Found</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Alarms;
