import React from 'react';
import { ValveControlData } from '../../../models/ValveControlData';
import '../AssetStatus.scss';

interface ValveControlTablesProps {
  valveControlData: ValveControlData;
}

const ValveControlTables = ({ valveControlData }: ValveControlTablesProps) => {
  return (
    <div className='flex flex-col w-full h-full'>
      {/* Table 1 */}
      <div className='w-full facility-table-container'>
        <table className='w-full flex flex-column asset-status-table-border'>
          <thead className='w-full facility-table-header facility-table-bottom-border flex h-[44px] items-center'>
            <tr className='w-full flex'>
              <th className='facility-basis-sevenths'>
                <span>Description</span>
              </th>
              <th className='facility-basis-sevenths'>
                <span>Value</span>
              </th>
              <th className='facility-basis-sevenths'>
                <span>Setpoint</span>
              </th>
              <th className='facility-basis-sevenths'>
                <span>Limit hi</span>
              </th>
              <th className='facility-basis-sevenths'>
                <span>Limit lo</span>
              </th>
              <th className='facility-basis-sevenths'>
                <span>Deadband</span>
              </th>
              <th className='facility-basis-sevenths'>
                <span>Gain</span>
              </th>
            </tr>
          </thead>
          <tbody className='w-full flex flex-column'>
            <tr className='w-full flex h-[48px] items-center facility-table-bottom-border'>
              <td className='facility-basis-sevenths'>DP</td>
              <td className='facility-basis-sevenths'>{valveControlData?.valueDP ?? 'Missing'}</td>
              <td className='facility-basis-sevenths'>{valveControlData?.setpointDP ?? 'Missing'}</td>
              <td className='facility-basis-sevenths'>{valveControlData?.hiLimitDP ?? 'Missing'}</td>
              <td className='facility-basis-sevenths'>{valveControlData?.loLimitDP ?? 'Missing'}</td>
              <td className='facility-basis-sevenths'>{valveControlData?.deadBandDP ?? 'Missing'}</td>
              <td className='facility-basis-sevenths'>{valveControlData?.gainDP ?? 'Missing'}</td>
            </tr>
            <tr className='w-full flex h-[48px] items-center facility-table-bottom-border'>
              <td className='facility-basis-sevenths'>SP</td>
              <td className='facility-basis-sevenths'>{valveControlData?.valueSP ?? 'Missing'}</td>
              <td className='facility-basis-sevenths'>{valveControlData?.setpointSP ?? 'Missing'}</td>
              <td className='facility-basis-sevenths'>{valveControlData?.hiLimitSP ?? 'Missing'}</td>
              <td className='facility-basis-sevenths'>{valveControlData?.loLimitSP ?? 'Missing'}</td>
              <td className='facility-basis-sevenths'>{valveControlData?.deadBandSP ?? 'Missing'}</td>
              <td className='facility-basis-sevenths'>{valveControlData?.gainSP ?? 'Missing'}</td>
            </tr>
            <tr className='w-full flex h-[48px] items-center'>
              <td className='facility-basis-sevenths'>Flowrate</td>
              <td className='facility-basis-sevenths'>{valveControlData?.valueFlowRate ?? 'Missing'}</td>
              <td className='facility-basis-sevenths'>{valveControlData?.setpointFlowRate ?? 'Missing'}</td>
              <td className='facility-basis-sevenths'>{valveControlData?.hiLimitFlowRate ?? 'Missing'}</td>
              <td className='facility-basis-sevenths'>{valveControlData?.loLimitFlowRate ?? 'Missing'}</td>
              <td className='facility-basis-sevenths'>{valveControlData?.deadBandFlowRate ?? 'Missing'}</td>
              <td className='facility-basis-sevenths'>{valveControlData?.gainFlowRate ?? 'Missing'}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Table 2 */}
      <div className='w-full facility-table-container'>
        <table className='w-full flex flex-column asset-status-table-border'>
          <tbody className='w-full flex flex-column'>
            <tr className='w-full flex h-[48px] items-center facility-table-bottom-border'>
              <td className='asset-status-basis-seconds'>Shut-in left (min.)</td>
              <td className='asset-status-basis-seconds'>{valveControlData?.shutInLeftValue ?? 'Missing'}</td>
            </tr>
            <tr className='w-full flex h-[48px] items-center facility-table-bottom-border'>
              <td className='asset-status-basis-seconds'>SP override</td>
              <td className='asset-status-basis-seconds'>{valveControlData?.spOverrideValue ?? 'Missing'}</td>
            </tr>
            <tr className='w-full flex h-[48px] items-center'>
              <td className='asset-status-basis-seconds'>Controller mode</td>
              <td className='asset-status-basis-seconds'>{valveControlData?.controllerModeValue ?? 'Missing'}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ValveControlTables;
