import axios from 'axios';
import TokenService from './authentication/token.service';
import { getAPIBaseURL } from '../utilities/BaseURLUtility';

const baseUrl = getAPIBaseURL();
const wellControlUrl = getAPIBaseURL('wellcontrolapi');
const userURL = getAPIBaseURL('usersapi');

const userInstance = axios.create({
  baseURL: userURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const wellControlInstance = axios.create({
  baseURL: wellControlUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

userInstance.interceptors.request.use(
  (config: any) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    const correlationId = TokenService.getCorrelationId();
    if (correlationId) {
      config.headers['xspoc-ui-tracking-id'] = correlationId;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

wellControlInstance.interceptors.request.use(
  (config: any) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    const correlationId = TokenService.getCorrelationId();
    if (correlationId) {
      config.headers['xspoc-ui-tracking-id'] = correlationId;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.request.use(
  (config: any) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    const correlationId = TokenService.getCorrelationId();
    if (correlationId) {
      config.headers['xspoc-ui-tracking-id'] = correlationId;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

userInstance.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error) {
    handelUnauthorized(error);

    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error) {
    handelUnauthorized(error);

    return Promise.reject(error);
  },
);

wellControlInstance.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error) {
    handelUnauthorized(error);

    return Promise.reject(error);
  },
);

const handelUnauthorized = (error: any) => {
  if (error.response.status === 401) {
    TokenService.removeUser();

    if (window.location.pathname !== '/login') {
      window.location.reload();
    }
  }
};

export default instance;
export { wellControlInstance, userInstance };
