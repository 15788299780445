import { OverLayFields } from '../models/OverlayFields';
import { PositioningType } from '../models/PositioningType';

export const GasFlowMonitorPositioning: PositioningType[] = [
  {
    overlayField: OverLayFields.LastGoodScan,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.CurrentCommunicationStatus,
    position: { x: 73, y: 102 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ControllerType,
    position: { x: 73, y: 126 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.DeviceID,
    position: { x: 73, y: 163.94 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.RTUAddress,
    position: { x: 73, y: 202 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.Location,
    position: { x: 73, y: 240.13 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.EnergyRate,
    position: { x: 73, y: 337 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.Meter,
    position: { x: 577, y: 102 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.FlowRate,
    position: { x: 577, y: 139.94 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ProductionTodayVolume,
    position: { x: 577, y: 178 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ProductionYesterdayVolume,
    position: { x: 577, y: 216.13 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.DiffPress,
    position: { x: 577, y: 282 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.Temperature,
    position: { x: 577, y: 320 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.TubeID,
    position: { x: 577, y: 391 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.TubeDescription,
    position: { x: 577, y: 429 },
    justification: 'left',
  },
];
