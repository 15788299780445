import React, { useState } from 'react';
import { useTable, Column, useBlockLayout, useSortBy } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { FixedSizeList } from 'react-window';
import GroupStatusRow from './GroupStatusRow';

interface CustomTableData {
  // Add any specific properties
  Id: number;
}

// Define the types for the columns and data props
interface CustomTableProps {
  columns: Array<Column<CustomTableData>>;
  data: CustomTableData[];
  rawData: object;
  handleAssetDetailsRowClick: (row: any, index: number) => void;
  groupName: string | undefined;
  isFullScreen: boolean;
  clickedRowIndex: number | null;
}

const AssetDetailsWindowTable: React.FC<CustomTableProps> = ({
  columns,
  data,
  rawData,
  handleAssetDetailsRowClick,
  groupName,
  isFullScreen,
  clickedRowIndex,
}) => {
  const [sortState, setSortState] = useState<{ [key: string]: 'asc' | 'desc' }>({});

  const defaultColumn = React.useMemo(
    () => ({
      width: '180',
    }),
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, totalColumnsWidth, prepareRow } = useTable(
    {
      columns,
      data,
      sortTypes: {
        // Custom sort function
        alphanumeric: (rowA: any, rowB: any, columnId: any) => {
          const valueA = rowA.values[columnId];
          const valueB = rowB.values[columnId];

          const isNumericA = !isNaN(Number(valueA));
          const isNumericB = !isNaN(Number(valueB));

          if (isNumericA && isNumericB) {
            return Number(valueA) - Number(valueB);
          }

          // Check if the values are dates
          const isDateA = !isNaN(Date.parse(valueA));
          const isDateB = !isNaN(Date.parse(valueB));

          if (isDateA && isDateB) {
            return new Date(valueA).getTime() - new Date(valueB).getTime();
          }

          const lowercaseValueA = String(valueA).toLowerCase();
          const lowercaseValueB = String(valueB).toLowerCase();

          if (lowercaseValueA < lowercaseValueB) return -1;
          if (lowercaseValueA > lowercaseValueB) return 1;
          return 0;
        },
      },
      defaultColumn,
    } as any,
    useSortBy,
    useBlockLayout,
    useSticky,
  );

  const handleSort = (columnId: string) => {
    setSortState((prevState) => {
      const newState = { ...prevState };
      if (newState[columnId] === 'asc') {
        newState[columnId] = 'desc';
      } else {
        newState[columnId] = 'asc';
      }
      return newState;
    });
  };

  const RenderRow = React.useCallback(
    ({ index, style }: { index: any; style: React.CSSProperties }) => {
      const row = rows[index];
      prepareRow(row);
      const href = `/asset/status/${row.values.Assetguid}/${groupName}`;
      let rowClassName = 'tr table-row ';
      // Check if the current row index matches the clicked row index
      if (index === clickedRowIndex) {
        rowClassName += 'highlighted'; // Define your CSS class for highlighting
      }
      return (
        <GroupStatusRow
          rawData={rawData}
          index={index}
          row={row}
          rowClassName={rowClassName}
          style={style}
          href={href}
          handleClick={() => {
            handleAssetDetailsRowClick(row, index);
          }}
        />
      );
    },
    [prepareRow, rows, clickedRowIndex, rawData, groupName, handleAssetDetailsRowClick],
  );

  return (
    <div className='table-container ui' style={isFullScreen ? { maxHeight: '100vh' } : {}}>
      <div {...getTableProps()} className='sticky groupTable'>
        <div {...getTableBodyProps()} style={{ position: 'relative', flex: 1, zIndex: 0 }}>
          <FixedSizeList
            height={500}
            itemCount={rows.length}
            itemSize={44}
            width={'100%'}
            innerElementType={({ children, ...rest }) => (
              <>
                <div
                  className='tableHeader table-header-cell'
                  style={{ width: totalColumnsWidth - 180, height: '44px' }}
                >
                  {headerGroups.map((headerGroup: any) => (
                    <div
                      {...headerGroup.getHeaderGroupProps()}
                      className='tr table-header-cell'
                      style={{ width: totalColumnsWidth - 180, height: '100%' }}
                    >
                      {headerGroup.headers.map((column: Column<CustomTableData> | any, index: number) => {
                        let className = 'table-header-item th';
                        if (index == 0) {
                          className = 'table-header-item th well';
                        }
                        if (column.Header != 'Assetguid') {
                          const isSortedAsc = sortState[column.id] === 'asc';
                          const isSortedDesc = sortState[column.id] === 'desc';
                          return (
                            <div
                              {...column.getHeaderProps(
                                column.getSortByToggleProps({
                                  title: `Sort By ${column.id}`,
                                  onClick: () => {
                                    column.toggleSortBy(!column.isSortedDesc);
                                    handleSort(column.id);
                                  },
                                }),
                              )}
                              className={className}
                            >
                              {column.render('Header')}
                              <span className='sort-image'>{isSortedAsc ? '↑' : isSortedDesc ? '↓' : ''}</span>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  ))}
                </div>
                <div className='body' style={{ width: totalColumnsWidth - 180 }}>
                  <div {...getTableBodyProps()} {...rest} style={{ position: 'relative', flex: 1, zIndex: 0 }}>
                    {children}
                  </div>
                </div>
              </>
            )}
          >
            {RenderRow}
          </FixedSizeList>
        </div>
      </div>
    </div>
  );
};

export default AssetDetailsWindowTable;
