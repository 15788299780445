import { OverLayFields } from '../models/OverlayFields';
import { PositioningType } from '../models/PositioningType';

export const UnicoLRPPositioning: PositioningType[] = [
  {
    overlayField: OverLayFields.LastGoodScan,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ApiDesignation,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.CurrentCommunicationStatus,
    position: { x: 216, y: 193 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.RunStatus,
    position: { x: 216, y: 219 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.TimeInState,
    position: { x: 216, y: 250 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ControllerType,
    position: { x: 216, y: 288 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.Protocol,
    position: { x: 216, y: 326 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.HostName,
    position: { x: 216, y: 364 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.Port,
    position: { x: 216, y: 403 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.RTUAddress,
    position: { x: 216, y: 441 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.Offset,
    position: { x: 216, y: 479 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.OPCType,
    position: { x: 216, y: 517 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.PumpingUnit,
    position: { x: 577, y: 177.91 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.StructuralLoading,
    position: { x: 577, y: 214.92 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.StrokeLength,
    position: { x: 577, y: 252.92 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.StrokesPerMinute,
    position: { x: 577, y: 292 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.TubingPressure,
    position: { x: 577, y: 371 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.CasingPressure,
    position: { x: 577, y: 408.01 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.RodStrings,
    position: { x: 361, y: 565 },
    justification: 'right',
  },
  {
    overlayField: OverLayFields.PumpFillage,
    position: { x: 577, y: 565 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.RodLoading,
    position: { x: 577, y: 603 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.PumpDepth,
    position: { x: 577, y: 641 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.PumpType,
    position: { x: 577, y: 680.08 },
    justification: 'left',
  },
];
