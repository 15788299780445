import * as am5core from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';

export const CreateGasRateXAxis = (props: any) => {
  const gasRateXAxis = props[0].chart.xAxes.push(
    am5xy.ValueAxis.new(props[0].root, {
      renderer: am5xy.AxisRendererX.new(props[0].root, {
        minGridDistance: 50,
      }),
      marginBottom: -20,
      min: props[0].gasRateMinX,
      max: props[0].gasRateMaxX,
      strictMinMax: false,
    }),
  );

  gasRateXAxis.children.push(
    am5core.Label.new(props[0].root, {
      text: 'Injected gas rate (mscf/d)',
      x: am5core.p50,
      centerX: am5core.p50,
      marginTop: -5,
      marginBottom: 5,
      fontSize: 12,
      fontWeight: '400',
      fill: props[0].color,
    }),
  );

  const gasRateRenderer = gasRateXAxis.get('renderer');
  gasRateRenderer.grid.template.setAll({
    visible: false,
  });

  return gasRateXAxis;
};
