import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as WellControl } from '../../../images/wellControl.svg';
import './UIEyeAndFilterButtons.scss';

function UISettingsButton() {
  const [isActive, setIsActive] = useState(false);
  const settingsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (settingsRef.current && !settingsRef.current.contains(event.target as Node)) {
        setIsActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className='ui-settings-button'
      ref={settingsRef}
      onClick={() => {
        setIsActive(!isActive);
      }}
    >
      <WellControl />
      <span className={isActive ? 'fas fa-caret-up' : 'fas fa-caret-down'} />
    </div>
  );
}

export default UISettingsButton;
