import React from 'react';
import './UIButtonToolbar.css';

interface UIButtonToolbarButtonProps {
  id: number;
  name: string;
  active: boolean;
}

interface UIButtonToolbarProps {
  uiButtons: UIButtonToolbarButtonProps[];
  buttonClicked: (id: number) => void;
}

function UIButtonToolbar(props: UIButtonToolbarProps) {
  const { uiButtons, buttonClicked } = { ...props };

  return (
    <div id='ui-button-toolbar' className='ui-button-toolbar'>
      {uiButtons.map((uiButton: UIButtonToolbarButtonProps) => {
        const { id, name, active } = { ...uiButton };

        return (
          <button
            key={id}
            id={name}
            data-testid={`${name}-button`}
            className={
              active
                ? 'ui-button-toolbar-button ui-button-toolbar-button-active semi-bold'
                : 'ui-button-toolbar-button semi-bold'
            }
            onClick={() => buttonClicked(id)}
          >
            {name}
          </button>
        );
      })}
    </div>
  );
}

export default UIButtonToolbar;
