import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface State {
  industryApplicationId: number | undefined;
}

interface ActionPayload {
  industryApplicationId: number | undefined;
}

const initialState: State = {
  industryApplicationId: 0,
};

const AssetAnalysisSlice = createSlice({
  name: 'AssetAnalysis',
  initialState,
  reducers: {
    setIndustryApplicationId: (state, action: { payload: ActionPayload }) => {
      state.industryApplicationId = action.payload.industryApplicationId;
    },
  },
});

export const industryApplicationId = (state: RootState) => state.AssetAnalysis.industryApplicationId;
export const { setIndustryApplicationId } = AssetAnalysisSlice.actions;
export default AssetAnalysisSlice.reducer;
