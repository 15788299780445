import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import './Modal.scss';

interface ModalProps {
  message: string;
  buttonText: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  callbackOnConfirm: () => void;
  title?: string;
  hideCancelButton?: boolean;
  Icon?: string;
  buttonColor?: string;
}

const Modal: React.FC<ModalProps> = ({
  message,
  buttonText,
  open,
  setOpen,
  callbackOnConfirm,
  title = 'Start',
  Icon,
  buttonColor,
  hideCancelButton = false,
}) => {
  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity' />
        </Transition.Child>
        <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full modal modal-bg'>
                <div>
                  <div className='mt-2 text-center sm:mt-4'>
                    <div className='icon'>
                      <img src={Icon} />
                    </div>
                    <Dialog.Title as='h3' className='text-base font-semibold leading-6 text-white-900'>
                      {title}
                    </Dialog.Title>
                    <div className='mt-2'>
                      <p className='text-sm modal-text'>{message}</p>
                    </div>
                  </div>
                </div>

                <div className='modal-btns'>
                  <hr className='partition' />
                  <div
                    className={`mt-5 sm:grid sm:grid-flow-row-dense  sm:gap-3 px-6 ${
                      hideCancelButton ? 'sm:grid-cols-1' : 'sm:grid-cols-2'
                    }`}
                  >
                    <button
                      type='button'
                      className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                        hideCancelButton ? 'sm:col-start-1' : 'sm:col-start-2'
                      } ${buttonColor}`}
                      onClick={() => {
                        setOpen(false);
                        callbackOnConfirm();
                      }}
                    >
                      {buttonText}
                    </button>
                    {!hideCancelButton && (
                      <button
                        type='button'
                        className='mt-3 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0 btn-cancel'
                        onClick={() => {
                          setOpen(false);
                        }}
                        ref={cancelButtonRef}
                      >
                        <span style={{ color: 'white' }}>Cancel</span>
                      </button>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default Modal;
