import { OverLayFields } from '../models/OverlayFields';
import { PositioningType } from '../models/PositioningType';

export const GasLiftManagerPositioning: PositioningType[] = [
  {
    overlayField: OverLayFields.LastGoodScan,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.CurrentCommunicationStatus,
    position: { x: 73, y: 118 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.RTUAddress,
    position: { x: 73, y: 142 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ControlValue,
    position: { x: 73, y: 292 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.GasInjectionRate,
    position: { x: 72, y: 447 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.InjectionPress,
    position: { x: 72, y: 485 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.CasingPressure,
    position: { x: 241, y: 292 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.TubingPressure,
    position: { x: 525, y: 226 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ProductionFlowRate,
    position: { x: 649, y: 322.13 },
    justification: 'left',
  },
];
