import React, { useEffect, useState, useRef } from 'react';
import './Dropdown.scss';
import { useLocation } from 'react-router-dom';
import { ReactComponent as CheckIcon } from '../../..//images/check.svg';

interface DropdownProps {
  assets: Asset[] | undefined | null;
  showEyeIcon?: boolean | undefined | null;
  defaultOption?: string | undefined | null;
  height?: string | undefined | null;
  width?: string | undefined | null;
  onChange?: (asset: Asset) => void;
}
export interface Asset {
  name: string;
  id: string;
  isSelected?: boolean;
}

const Dropdown: React.FC<DropdownProps> = (props: DropdownProps) => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const [selected, setIsSelected] = useState(props.defaultOption || 'Choose one');
  const dropdownRef = useRef<HTMLDivElement>(null);
  const selectedRef = useRef<HTMLDivElement>(null);

  const eyeSpanClass = props.showEyeIcon ? 'fas fa-eye' : '';
  const textSpanClass = props.showEyeIcon ? 'fa-text' : '';

  useEffect(() => {
    setIsSelected(props.defaultOption || 'Choose one');
  }, [props.assets, location.pathname]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleAssetChange = (asset: Asset) => {
    setIsSelected(asset.name);
    setIsActive(!isActive);
    if (props.onChange) {
      props.onChange(asset);
    }
  };

  useEffect(() => {
    if (selectedRef.current && isActive) {
      selectedRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [selected, isActive]);

  return (
    <div
      ref={dropdownRef}
      className='dropdown'
      style={{
        height: props.height || '46px',
        width: props.width || '320px',
      }}
    >
      <div
        onClick={() => {
          setIsActive(!isActive);
        }}
        className='dropdown-btn'
        style={{
          height: props.height || '46px',
        }}
      >
        <span className={eyeSpanClass}>
          <span data-testid={`dropdown-${selected}`} className={textSpanClass}>
            {selected}
          </span>
        </span>
        <span className={isActive ? 'fas fa-caret-up' : 'fas fa-caret-down'} />
      </div>
      <div className='dropdown-content' style={{ display: isActive ? 'block' : 'none' }}>
        {props.assets?.map((asset, index) => {
          return (
            <div
              data-testid={asset.name}
              ref={asset.name === selected ? selectedRef : null}
              key={index}
              onClick={() => handleAssetChange(asset)}
              className={`${selected === asset.name ? 'item-selected' : 'item'}`}
            >
              {asset.name}
              {asset.isSelected && <CheckIcon className='check-icon' />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Dropdown;
