import React from 'react';
import Tooltip from '../tooltip/ToolTip';
import './UIIconButtonToolbar.css';

interface UIIconButtonToolbarButtonProps {
  key: number;
  name: string;
  active: boolean;
  tooltip: string;
  svgNode?: JSX.Element;
}

interface UIIconButtonToolbarProps {
  uiIconButtons: UIIconButtonToolbarButtonProps[];
  buttonClicked: (id: number) => void;
  isSvg?: boolean;
}

function UIIconButtonToolbar(props: UIIconButtonToolbarProps) {
  const { uiIconButtons, buttonClicked } = { ...props };
  return (
    <div id='ui-icon-button-toolbar' className='ui-icon-button-toolbar'>
      {uiIconButtons.map((uiIconButton: UIIconButtonToolbarButtonProps) => {
        const { key, name, active, tooltip, svgNode } = { ...uiIconButton };
        return (
          <Tooltip content={tooltip} direction='top' key={key}>
            <button
              key={key}
              id={name}
              className={
                active
                  ? 'ui-icon-button-toolbar-button ui-icon-button-toolbar-button-active semi-bold ui-icon-button-toolbar-background-color'
                  : 'ui-icon-button-toolbar-button semi-bold'
              }
              data-testid={name}
              onClick={() => buttonClicked(key)}
            >
              {svgNode}
            </button>
          </Tooltip>
        );
      })}
    </div>
  );
}

export default UIIconButtonToolbar;
