import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { fetchRodliftWellStatusInitialState } from './AssetStatusService';
import { IRodLiftStatusWellModel } from '../../models/RodPumpWellStatus';

interface State {
  loading: boolean;
  data?: IRodLiftStatusWellModel;
  error?: string;
}

const initialState: State = { loading: false, data: undefined, error: undefined };

export const rodLiftWellStatusSlice = createSlice({
  name: 'rodliftwellstatus',
  initialState: initialState,
  reducers: {
    setInitialState: (state) => {
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInitialStateAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInitialStateAsync.fulfilled, (state, action: { payload: { values: IRodLiftStatusWellModel } }) => {
        state.loading = false;
        state.data = action.payload.values;
      })
      .addCase(fetchInitialStateAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

interface FetchInitialStateArgs {
  assetId: string;
}

// Async thunk to fetch the initial state data
export const fetchInitialStateAsync = createAsyncThunk(
  'assetStatusSlice/fetchInitialState',
  async (args: FetchInitialStateArgs) => {
    const { assetId } = args;
    const response = await fetchRodliftWellStatusInitialState(assetId);
    return response;
  },
);

export const selectRodLiftWellStatus = (state: RootState) => state.rodliftwellstatus;
export const { setInitialState } = rodLiftWellStatusSlice.actions;

export default rodLiftWellStatusSlice.reducer;
