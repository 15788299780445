import React from 'react';

const GroupListItemCollapse = ({ isActive = false }) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='chevron-up'>
      <rect x='0.5' y='0.5' width='19' height='19' rx='3.5' stroke={isActive ? '#60BFDA' : '#4A5463'} />
      <path
        id='Icon'
        d='M15 12.5L10 7.5L5 12.5'
        stroke={isActive ? '#60BFDA' : '#B8C5CC'}
        strokeWidth='1.67'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
);

export default GroupListItemCollapse;
