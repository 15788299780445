import React from 'react';
import { ReactComponent as Downloadicon } from '../../../images/download-01.svg';
import './UIEyeAndFilterButtons.scss';

interface UIDownloadButtonProps {
  onClick?: () => void;
}

const UIDownloadButton: React.FC<UIDownloadButtonProps> = ({ onClick }) => {
  return (
    <button className='download-button' onClick={onClick}>
      <Downloadicon />
    </button>
  );
};

export default UIDownloadButton;
