import React from 'react';
import { ReactComponent as SelectGroupImage } from '../../images/select-a-group.svg';
import './SelectGroup.scss';

const SelectGroup = () => {
  return (
    <div className='select-group-container'>
      <SelectGroupImage />
      <div className='select-group-text-container'>
        <span className='select-group-title'>Select a well group</span>
        <span className='select-group-text'>
          Your groups and wells are in the left navigation bar. Group status loads when a well group is selected.
        </span>
      </div>
    </div>
  );
};

export default SelectGroup;
