import React from 'react';
import './PressureProfileWellbore.scss';

interface WellboreFillProps {
  topFluidColumn: number;
  bottomFluidColumn: number;
  casingFluidColumn: number;
}

const WellboreFill = ({ topFluidColumn, bottomFluidColumn, casingFluidColumn }: WellboreFillProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          position: 'absolute',
          bottom: 0,
        }}
      >
        <div style={{ display: 'flex', height: topFluidColumn }} />
        <div
          style={{
            display: 'flex',
            height: bottomFluidColumn,
            backgroundColor: '#384252',
            width: '100%',
          }}
        />
        <div style={{ display: 'flex', height: casingFluidColumn, backgroundColor: '#384252' }} />
        <div
          style={{
            display: 'flex',
            height: '20px',
            backgroundColor: '#384252',
          }}
        />
      </div>
    </div>
  );
};

export default WellboreFill;
