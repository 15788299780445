import React from 'react';
import { ReactComponent as DarkChemicalInjection } from '../../../images/dark-chemical-injection.svg';
import { ReactComponent as RodLift } from '../../../images/dark-rod-lift.svg';
import { ReactComponent as DarkDefault } from '../../../images/dark-default-icon.svg';
import { ReactComponent as DarkGasLift } from '../../../images/dark-gas-lift.svg';
import { ReactComponent as DarkInjection } from '../../../images/dark-injection.svg';
import { ReactComponent as DarkJetLift } from '../../../images/dark-jet-lift.svg';
import { ReactComponent as DarkPCP } from '../../../images/dark-pcp.svg';
import { ReactComponent as DarkPlungerLift } from '../../../images/dark-plunger-lift.svg';
import { ReactComponent as DarkFacilityLift } from '../../../images/dark-facility.svg';
import { ReactComponent as DarkESP } from '../../../images/dark-esp.svg';
import { ReactComponent as DarkValve } from '../../../images/dark-valve.svg';
import { ReactComponent as DarkPID } from '../../../images/dark-pid.svg';
import { ReactComponent as DarkTank } from '../../../images/dark-tank.svg';
import { ReactComponent as DarkLiquidMeter } from '../../../images/dark-liquid-meter.svg';
import { ReactComponent as DarkPump } from '../../../images/dark-pump.svg';
import { ReactComponent as OrifaceGasMeter } from '../../../images/dark-oriface-gas-meter.svg';

//light
import { ReactComponent as LightRodLift } from '../../../images/light-rod-lift.svg';
import { ReactComponent as LightDefaultIcon } from '../../../images/light-default-icon.svg';
import { ReactComponent as LightGasLift } from '../../../images/light-gas-lift.svg';
import { ReactComponent as LightPCP } from '../../../images/light-pcp.svg';
import { ReactComponent as LightInjection } from '../../../images/light-injection.svg';
import { ReactComponent as LightJetLift } from '../../../images/light-jet-lift.svg';
import { ReactComponent as LightPlungerLift } from '../../../images/light-plunger-lift.svg';
import { ReactComponent as LightFacilityLift } from '../../../images/light-facility.svg';
import { ReactComponent as LightESP } from '../../../images/light-esp.svg';
import { ReactComponent as LightValve } from '../../../images/light-valve.svg';
import { ReactComponent as LightPID } from '../../../images/light-pid.svg';
import { ReactComponent as LightTank } from '../../../images/light-tank.svg';
import { ReactComponent as LightLiquidMeter } from '../../../images/light-liquid-meter.svg';
import { ReactComponent as LightPump } from '../../../images/light-pump.svg';
import { ReactComponent as LightOrifaceGasMeter } from '../../../images/light-oriface-gas-meter.svg';
import { ReactComponent as LightChemicalInjection } from '../../../images/light-chemical-injection.svg';
import { useAppSelector } from '../../../hooks/storeHooks';
import './NavigationDrawerItem.scss';

interface NavigationIconsProps {
  asset: {
    industryApplicationId: number;
  };
  isActive?: boolean;
}

const NavigationIcons = (props: NavigationIconsProps) => {
  const themeMode = useAppSelector((state) => state.theme.mode);
  const isDarkMode = themeMode === 'dark';
  const isActive = props.isActive;

  const getDarkThemeImages = () => {
    switch (props.asset.industryApplicationId) {
      case 3:
        return <RodLift className={isActive ? 'navigation-icon-active' : 'navigation-icon'} />;
      case 4:
        return <DarkESP className={isActive ? 'navigation-icon-active' : 'navigation-icon'} />;
      case 5:
        return <DarkPCP className={isActive ? 'navigation-icon-active' : 'navigation-icon'} />;
      case 6:
        return <DarkPlungerLift className={isActive ? 'navigation-icon-active' : 'navigation-icon'} />;
      case 7:
        return <DarkGasLift className={isActive ? 'navigation-icon-active' : 'navigation-icon'} />;
      case 8:
        return <OrifaceGasMeter className={isActive ? 'navigation-icon-active' : 'navigation-icon'} />;
      case 9:
        return <DarkLiquidMeter className={isActive ? 'navigation-icon-active' : 'navigation-icon'} />;
      case 10:
        return <DarkTank className={isActive ? 'navigation-icon-active' : 'navigation-icon'} />;
      case 11:
        return <DarkPID className={isActive ? 'navigation-icon-active' : 'navigation-icon'} />;
      case 12:
        return <DarkValve className={isActive ? 'navigation-icon-active' : 'navigation-icon'} />;
      case 13:
        return <DarkInjection className={isActive ? 'navigation-icon-active' : 'navigation-icon'} />;
      case 14:
        return <DarkPump className={isActive ? 'navigation-icon-active' : 'navigation-icon'} />;
      case 15:
        return <DarkJetLift className={isActive ? 'navigation-icon-active' : 'navigation-icon'} />;
      case 16:
        return <DarkPlungerLift className={isActive ? 'navigation-icon-active' : 'navigation-icon'} />; // need to be changed
      case 17:
        return <DarkChemicalInjection className={isActive ? 'navigation-icon-active' : 'navigation-icon'} />;
      case 18:
        return <DarkFacilityLift className={isActive ? 'navigation-icon-active' : 'navigation-icon'} />;
      default:
        return <DarkDefault className={isActive ? 'navigation-icon-active' : 'navigation-icon'} />;
    }
  };

  const getLightThemeImages = () => {
    switch (props.asset.industryApplicationId) {
      case 3:
        return <LightRodLift />;
      case 4:
        return <LightESP />;
      case 5:
        return <LightPCP />;
      case 6:
        return <LightPlungerLift />;
      case 7:
        return <LightGasLift />;
      case 8:
        return <LightOrifaceGasMeter />;
      case 9:
        return <LightLiquidMeter />;
      case 10:
        return <LightTank />;
      case 11:
        return <LightPID />;
      case 12:
        return <LightValve />;
      case 13:
        return <LightInjection />; //light injection
      case 14:
        return <LightPump />;
      case 15:
        return <LightJetLift />;
      case 16:
        return <LightPlungerLift />;
      case 17:
        return <LightChemicalInjection />; // chemical injection
      case 18:
        return <LightFacilityLift />;
      default:
        return <LightDefaultIcon />;
    }
  };

  return (
    <>
      <div className='navigation-icons'>{isDarkMode ? getDarkThemeImages() : getLightThemeImages()}</div>
    </>
  );
};

export default NavigationIcons;
