import api from '../../services/api';

export const fetchGLAnalysisAsync = async (
  assetId: string,
  testDate: string,
  analysisTypeId: string,
  analysisResultId: string,
) => {
  const queryParams = {
    assetId,
    testDate,
    analysisTypeId,
    analysisResultId,
  };
  return await api
    .get('Analytics/GLAnalysis', {
      params: queryParams,
      headers: {
        'content-type': 'application/json',
      },
    })
    .then(function (response: any) {
      if (response) {
        return response.data;
      }
    });
};

export const fetchGLAnalysisCoordinatesAsync = async (
  assetId: string,
  testDate: string,
  analysisResultId: string,
  analysisTypeId: string,
) => {
  const queryParams = {
    assetId,
    testDate,
    analysisResultId,
    analysisTypeId,
  };
  return await api
    .get('Analytics/GLAnalysis/GLCoordinates', {
      params: queryParams,
      headers: {
        'content-type': 'application/json',
      },
    })
    .then(function (response: any) {
      if (response) {
        return response.data;
      }
    });
};

export const fetchGLAnalysisInitialState = async () => {
  try {
    return await api
      .get('GLAnalysis', {
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(function (response: any) {
        if (response) {
          return response.data;
        }
      });
  } catch (error) {
    console.error('Error fetching initial state:', error);
    return [];
  }
};

export const fetchGLAnalysisSurveyDateInitialState = async (assetId: string) => {
  try {
    return await api
      .get('Surveys?assetId=' + assetId, {
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(function (response: any) {
        if (response) {
          return response.data;
        }
      });
  } catch (error) {
    console.error('Error fetching initial state:', error);
    return [];
  }
};

export const fetchGLAnalysisWellTestInitialState = async (assetId: string, type: string) => {
  try {
    return await api
      .get('WellTests?assetId=' + assetId + '&type=' + type, {
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(function (response: any) {
        if (response) {
          return response.data;
        }
      });
  } catch (error) {
    console.error('Error fetching initial state:', error);
    return [];
  }
};

export const fetchGLAnalysisSurveyCurveCoordinatesInitialState = async (assetId: string, surveyDate: string) => {
  try {
    return await api
      .get('Surveys/SurveyCoordinates?assetId=' + assetId + '&surveyDate=' + surveyDate, {
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(function (response: any) {
        if (response) {
          return response.data;
        }
      });
  } catch (error) {
    console.error('Error fetching initial state:', error);
    return [];
  }
};

export const fetchGLAnalysisWellboreInitialState = async () => {
  try {
    return await api
      .get('GasLiftAnalysis-GetWellbore', {
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(function (response: any) {
        if (response) {
          return response.data;
        }
      });
  } catch (error) {
    console.error('Error fetching initial state:', error);
    return [];
  }
};
