import api from '../../../services/api';
import { AlarmLimitValues, CustomViews } from './TrendGroupLabelSlice';

interface DataHistoryAlarmLimitsResponse {
  data: AlarmLimitValues[];
}

export const fetchDataHistoryAlarmLimits = async (selectedAssetId: string | undefined, address: string) => {
  try {
    const apiEndpoint = `DataHistory/AlarmLimits?assetId=${selectedAssetId ?? ''}&addresses=${address}`;

    return await api
      .get(apiEndpoint, {
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(function (response: DataHistoryAlarmLimitsResponse) {
        if (response) {
          return response.data;
        } else {
          return null;
        }
      });
  } catch (error) {
    console.error('Error fetching initial state:', error);
    return {};
  }
};

export const fetchDataHistoryTrendViews = async () => {
  try {
    const apiEndpoint = `/DataHistory/GetDefaultTrendViews`;

    return await api
      .get(apiEndpoint, {
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(function (response: { data: CustomViews }) {
        if (response) {
          return response.data;
        } else {
          return null;
        }
      });
  } catch (error) {
    console.error('Error fetching initial state:', error);
    return {};
  }
};
