import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AnalysisInputOutputResponseValues, fetchAnalysisAsync } from './RodLiftAnalysisService';

interface FetchAnalysisParameters {
  assetId: string;
  cardDate: string;
}

const fetchRodLiftAnalysisAsync = createAsyncThunk(
  'RodLiftAnalysis/fetchAnalysis',
  async (data: FetchAnalysisParameters) => {
    const response = await fetchAnalysisAsync(data.assetId, data.cardDate);
    return response;
  },
);

interface RodLiftAnalysisResult {
  input: AnalysisInputOutputResponseValues[];
  output: AnalysisInputOutputResponseValues[];
  xdiagAnalysis: AnalysisInputOutputResponseValues;
}

interface RodLiftAnalysisResults {
  [cardDate: string]: RodLiftAnalysisResult;
}

interface RodLiftAnalysisResultsState {
  values: RodLiftAnalysisResults;
  assetId: string;
  isLoading: boolean;
}

const initialState: RodLiftAnalysisResultsState = {
  values: {},
  assetId: '',
  isLoading: false,
};

const analysisSlice = createSlice({
  name: 'RodLiftAnalysis/Analysis',
  initialState,
  reducers: {
    setInitialState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRodLiftAnalysisAsync.pending, (state, action) => {
      // If fetching data for new asset id then clear slice state.
      const assetId = action.meta.arg.assetId;
      const existingAssetId = state.assetId;

      if (assetId != existingAssetId) {
        return initialState;
      }

      state.isLoading = true;
      return state;
    });
    builder.addCase(fetchRodLiftAnalysisAsync.fulfilled, (state, action) => {
      const cardDate = action.meta.arg.cardDate;
      const assetId = action.meta.arg.assetId;

      const rodLiftAnalysisResult: RodLiftAnalysisResult = {
        input: [],
        output: [],
        xdiagAnalysis: {} as AnalysisInputOutputResponseValues,
      };

      if (action?.payload?.values?.input !== undefined) {
        rodLiftAnalysisResult.input = action.payload.values.input;
      }

      if (action?.payload?.values?.output !== undefined) {
        rodLiftAnalysisResult.output = action.payload.values.output.filter((x) => x.id.toLowerCase() !== 'xdanalysis');
      }

      if (action?.payload?.values?.output !== undefined) {
        const analysis = action.payload.values.output.find((x) => x.id.toLowerCase() === 'xdanalysis');

        if (analysis) {
          rodLiftAnalysisResult.xdiagAnalysis = analysis;
        }
      }

      state.values[cardDate] = rodLiftAnalysisResult;
      state.assetId = assetId;
      state.isLoading = true;
    });
  },
});

export { fetchRodLiftAnalysisAsync };
export const { setInitialState } = analysisSlice.actions;
export default analysisSlice.reducer;
