export function currentChart(chartIndex: number) {
  return chartIndex === 0
    ? 'chart1'
    : chartIndex === 1
    ? 'chart2'
    : chartIndex === 2
    ? 'chart3'
    : chartIndex === 3
    ? 'chart4'
    : 'chart1';
}

export function getParams(
  assetId: string,
  formattedStartDate: any,
  formattedEndDate: any,
  chartIndex: number,
  trendItem: any,
) {
  const charts: any = {
    assetId,
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    chartIndex,
  };

  for (let i = 0; i < 4; i++) {
    const chartNumber = i + 1;
    const itemIdKey = `chart${chartNumber}ItemId`;
    const typeKey = `chart${chartNumber}Type`;

    charts[itemIdKey as any] = chartIndex === i ? trendItem.typeId.toString() : '';
    charts[typeKey as any] = chartIndex === i ? trendItem.name : '';
  }

  return charts;
}

export function mapObject(original: any, dateCreated: any) {
  const mapped: any = {};
  for (const key in original) {
    mapped[key] = original[key].map((item: any) => {
      return {
        name: item.axisLabel,
        typeId: item.itemId,
        coordinates: item.axisValues,
        dateCreated,
      };
    });
  }
  return mapped;
}
//remove trends if does not exist in trend library
export function isExistInTrendLibrary(trendLibrary: any, trend: any) {
  return trendLibrary.trends.some((item: any) => {
    return item.items.some((item: any) => item['name'].replace(' ', '') === trend.name.replace(' ', ''));
  });
}
