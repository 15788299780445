const PressureProfileChartColors = [
  {
    id: 7,
    name: 'Temperature',
    color: '#F04438',
  },
  {
    id: 8,
    name: 'Production fluid pressure',
    color: '#FF8F61',
  },
  {
    id: 5,
    name: 'Reservoir Fluid Hydrostatic Curve',
    color: '#884DD5',
  },
  {
    id: 6,
    name: 'Kill Fluid Hrydrostatic Curve',
    color: '#60BFDA',
  },
  {
    id: 4,
    name: 'Gas Injection Pressure Curve',
    color: '#6BBBAE',
  },
  {
    id: 29,
    name: 'Injection Rate for Critical Velocity Curve',
    color: '#4294FF',
  },
  {
    id: 25,
    name: 'Temperature Survey Curve',
    color: '#F04438',
  },
  {
    id: 26,
    name: 'Pressure Survey Curve',
    color: '#FF8F61',
  },
];

export default PressureProfileChartColors;
