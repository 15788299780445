import React from 'react';

const GroupListItemExpand = ({ isActive = false }) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='chevron-down'>
      <rect x='0.5' y='0.500977' width='19' height='19' rx='3.5' stroke={isActive ? '#60BFDA' : '#4A5463'} />
      <path
        id='Icon'
        d='M5 7.50098L10 12.501L15 7.50098'
        stroke={isActive ? '#60BFDA' : '#B8C5CC'}
        strokeWidth='1.67'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
);

export default GroupListItemExpand;
