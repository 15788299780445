import { OverLayFields } from '../models/OverlayFields';
import { PositioningType } from '../models/PositioningType';

export const LiquidFlowMeterPositioning: PositioningType[] = [
  {
    overlayField: OverLayFields.LastGoodScan,
    position: { x: 0, y: 0 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.ControllerType,
    position: { x: 143, y: 239 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.RTUAddress,
    position: { x: 143, y: 277 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.HostName,
    position: { x: 143, y: 315 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.TubeID,
    position: { x: 577, y: 233 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.TubeDescription,
    position: { x: 577, y: 270.94 },
    justification: 'left',
  },
  {
    overlayField: OverLayFields.FlowRate,
    position: { x: 577, y: 309 },
    justification: 'left',
  },
];
