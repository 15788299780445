import React, { useState } from 'react';
import BreakLink from './icons/BreakLink';
import TimelineLink from './icons/TimelineLink';
import Unlinked from './icons/Unlinked';
import './Timeline.scss';
import Tooltip from '../tooltip/ToolTip';

interface TimelineIconProps {
  isDateLinked: boolean;
}

const TimelineIcon = ({ isDateLinked }: TimelineIconProps) => {
  const [isHoveringTimelineIcon, setIsHoveringTimelineIcon] = useState(false);

  return (
    <Tooltip content={isDateLinked ? 'Unlink date selection from all charts' : 'Link date selection to all charts'}>
      <div
        className={`timeline-icon-container ${
          isDateLinked && !isHoveringTimelineIcon ? 'linked' : !isDateLinked && isHoveringTimelineIcon ? 'linked' : ''
        }`}
        onMouseEnter={() => setIsHoveringTimelineIcon(true)}
        onMouseLeave={() => setIsHoveringTimelineIcon(false)}
      >
        {isHoveringTimelineIcon && isDateLinked ? (
          <BreakLink color='#B8C5CC' />
        ) : isHoveringTimelineIcon && !isDateLinked ? (
          <TimelineLink color='#B8C5CC' />
        ) : !isHoveringTimelineIcon && isDateLinked ? (
          <TimelineLink color='#B8C5CC' />
        ) : (
          <Unlinked color='#B8C5CC' />
        )}
      </div>
    </Tooltip>
  );
};

export default TimelineIcon;
