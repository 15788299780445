import React, { useEffect, useState } from 'react';
import ChevronUp from '../icons/ChevronUp';
import ChevronDown from '../icons/ChevronDown';
import { FacilityTagData } from '../../../models/FacilityTagData';
import { DisplayStatusStateCssClassMap } from '../DisplayStatusStateCssClassMap';

interface FacilityTagGroupTableProps {
  facilityTagData: FacilityTagData[];
}

const FacilityTagGroupTable = ({ facilityTagData }: FacilityTagGroupTableProps) => {
  const [openSet, setOpenSet] = useState(new Set());
  const [tableHeight, setTableHeight] = useState<number>(0);

  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  if (tableHeight === 0 && tableContainerRef.current) {
    setTableHeight(tableContainerRef.current.clientHeight);
  }

  useEffect(() => {
    const resizeHandler = () => {
      if (tableContainerRef.current) {
        setTableHeight(tableContainerRef.current.clientHeight);
      }
    };

    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const toggleAccordion = (index: number) => {
    const newOpenSet = new Set(openSet);
    if (newOpenSet.has(index)) {
      newOpenSet.delete(index);
    } else {
      newOpenSet.add(index);
    }
    setOpenSet(newOpenSet);
  };

  return (
    <div className='w-full h-full facility-table-container' ref={tableContainerRef}>
      <table className='w-full flex flex-column asset-status-table-border'>
        <thead className='w-full facility-table-header facility-table-bottom-border flex pl-[48px] h-[44px] items-center'>
          <tr className='w-full flex'>
            <th className='facility-text-fourths'>
              <span>Tag group name</span>
            </th>
            <th className='facility-text-fourths'>
              <span>Tag count</span>
            </th>
            <th className='facility-text-fourths'>
              <span>Alarm count</span>
            </th>
            <th className='facility-text-fourths'>
              <span>Host alarms</span>
            </th>
          </tr>
        </thead>
        <tbody
          style={{
            maxHeight: tableHeight - 44,
            overflow: 'auto',
          }}
          className='w-full flex flex-column'
        >
          {facilityTagData && facilityTagData.length > 0 ? (
            facilityTagData?.map((facilityTagDatum, index) => (
              <div className='facility-table-accordion-container'>
                <div className='flex flex-row facility-table-bottom-border'>
                  <div key={index} className='carrot' onClick={() => toggleAccordion(index)}>
                    {openSet.has(index) ? <ChevronUp color='#f7f9f9' /> : <ChevronDown color='#f7f9f9' />}
                  </div>
                  <div className='flex w-full'>
                    <tr key={index} className='w-full flex h-[48px] items-center'>
                      <td className='facility-text-fourths'>{facilityTagDatum.tagGroupName}</td>
                      <td className='facility-text-fourths'>{facilityTagDatum.tagCount}</td>
                      <td className='facility-text-fourths'>{facilityTagDatum.alarmCount}</td>
                      <td className='facility-text-fourths'>{facilityTagDatum.groupHostAlarm}</td>
                    </tr>
                  </div>
                </div>
                {openSet.has(index) && (
                  <div className='facility-inner-table-outer-container flex w-full p-[20px]'>
                    <div className='facility-inner-table-container w-full'>
                      <table className='w-full flex flex-column'>
                        <thead className='h-[44px] w-full flex'>
                          <tr className='w-full flex h-[44px] items-center facility-table-bottom-border'>
                            <th className='facility-basis-sevenths'>
                              <span>Description</span>
                            </th>
                            <th className='facility-basis-sevenths'>
                              <span>Value</span>
                            </th>
                            <th className='facility-basis-sevenths'>
                              <span>Unit</span>
                            </th>
                            <th className='facility-basis-sevenths'>
                              <span>Alarms</span>
                            </th>
                            <th className='facility-basis-sevenths'>
                              <span>Host alarms</span>
                            </th>
                            <th className='facility-basis-sevenths'>
                              <span>Last updated</span>
                            </th>
                            <th className='facility-basis-sevenths'>
                              <span>Address</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className='w-full flex flex-column'>
                          {facilityTagDatum.facilityTags.map((facilityTag, idx) => (
                            <tr
                              key={idx}
                              className={`w-full flex min-h-[44px] items-center ${
                                idx === facilityTagDatum.facilityTags.length - 1 ? '' : 'facility-table-bottom-border'
                              }`}
                            >
                              <td className='facility-basis-sevenths'>{facilityTag.description}</td>
                              <td className='facility-basis-sevenths'>{facilityTag.value}</td>
                              <td className='facility-basis-sevenths'>{facilityTag.units}</td>
                              <td className='facility-basis-sevenths'>
                                <div
                                  className={`facility-display-state-${
                                    DisplayStatusStateCssClassMap.get(facilityTag.alarm.displayState) ?? ''
                                  }`}
                                >
                                  {facilityTag.alarm.value ?? ''}
                                </div>
                              </td>
                              <td className='facility-basis-sevenths'>{facilityTag.hostAlarm}</td>
                              <td className='facility-basis-sevenths'>
                                {facilityTag.lastUpdated ? new Date(facilityTag.lastUpdated).toLocaleString() : ''}
                              </td>
                              <td className='facility-basis-sevenths'>{facilityTag.address}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            ))
          ) : (
            <tr className='w-full flex h-[44px] items-center justify-center'>
              <td className='asset-status-no-rows'>No data available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default FacilityTagGroupTable;
